import axios from "axios";
import IQuote from "@interfaces/IQuote";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/quote";

export const getQuoteService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getQuotesService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const createQuoteService = async (quoteData: IQuote) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    quoteData,
    headers
  );
  return response.data;
};

export const updateQuoteService = async (quoteData: IQuote) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${quoteData.id}`,
    quoteData,
    headers
  );
  return response.data;
};

export const deleteQuoteService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
