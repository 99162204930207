import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrencyFormat } from "@utils/util";
import { fetchRoomsAsync } from "@store/slices/room";
import { getErrorMessage } from "@utils/errorMessage";
import { validatePhoneNumber } from "@utils/validation";
import { paymentFormLayout } from "@constants/formLayout";
import { fetchProductsAsync } from "@store/slices/product";
import { fetchServicesAsync } from "@store/slices/service";
import { PRIMARY_COLOR } from "@constants/globalConstants";
import { createQuoteService } from "@services/quoteService";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { clearUsers, fetchUsersAsync } from "@store/slices/user";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getQuoteTotalsCalculation, getUniqueByType } from "./helpers";
import { clearCompanies, fetchCompaniesAsync } from "@store/slices/company";

import {
  filterData,
  IFormProps,
  IItemOption,
  onFinishFailed,
} from "@components/Form";

import {
  getSpan,
  optionsRender,
  productOptionsRender,
} from "@components/Common/Render";

import {
  getItems,
  onProductChange,
  onServiceChange,
  onRoomTypeChange,
  CalculationOutputs,
  shouldDisableQuoteSubmitButton,
} from "@pages/Invoice/helpers";

import {
  Col,
  Row,
  Form,
  Card,
  Spin,
  Space,
  Input,
  Button,
  Select,
  Divider,
  message,
  InputNumber,
} from "antd";

import IQuote from "@interfaces/IQuote";
import DatePicker from "@components/Common/DatePicker";
import PhonePrefix from "@components/Common/PhonePrefix";
import IReservationRoom from "@interfaces/IReservationRoom";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";

const { TextArea } = Input;

const QuoteForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [roomTypeOptions, setRoomTypeOptions] = useState<any>([]);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<IItemOption | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<IItemOption | null>(
    null
  );

  const [selectedRooms, setSelectedRooms] = useState<IReservationRoom[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<
    IReservationProduct[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<
    IReservationService[]
  >([]);

  const [totalCalculations, setTotalCalculations] =
    useState<CalculationOutputs>({
      total: 0,
      isvTax: 0,
      isrtTax: 0,
      subtotal: 0,
      totalDiscount: 0,
      subtotalWithDiscount: 0,
    });

  const {
    users,
    userOptions,
    loading: userLoading,
  } = useAppSelector((state) => state.user);
  const {
    companies,
    companyOptions,
    loading: companyLoading,
  } = useAppSelector((state) => state.company);
  const {
    rooms,
    roomOptions,
    loading: roomLoading,
  } = useAppSelector((state) => state.room);
  const {
    products,
    productOptions,
    loading: productLoading,
  } = useAppSelector((state) => state.product);
  const {
    services,
    serviceOptions,
    loading: serviceLoading,
  } = useAppSelector((state) => state.service);

  useEffect(() => {
    setLoading(
      userLoading ||
        companyLoading ||
        productLoading ||
        serviceLoading ||
        roomLoading
    );
  }, [
    userLoading,
    companyLoading,
    productLoading,
    serviceLoading,
    roomLoading,
  ]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsersAsync());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(fetchCompaniesAsync());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    if (rooms.length === 0) {
      dispatch(fetchRoomsAsync());
    } else {
      const roomTypeOptions = getUniqueByType(roomOptions);
      setRoomTypeOptions(roomTypeOptions);
    }
  }, [dispatch, rooms, roomOptions]);

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProductsAsync());
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (services.length === 0) {
      dispatch(fetchServicesAsync());
    }
  }, [dispatch, services]);

  useEffect(() => {
    const totalCalculations = getQuoteTotalsCalculation({
      selectedRooms,
      selectedProducts,
      selectedServices,
      discountPercentage,
    });
    setTotalCalculations(totalCalculations);
  }, [selectedRooms, selectedProducts, selectedServices, discountPercentage]);

  const onFinish = async (quoteData: IQuote) => {
    setLoading(true);
    try {
      await createQuoteService(quoteData);
      dispatch(clearUsers());
      dispatch(clearCompanies());
      message.success("Cotización creada!");
      goBack();
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    props.onBack ? props.onBack() : navigate("..");
  };

  const onFieldsChangeForm = (changedFields: any, allFields: any) => {
    if (changedFields.length !== 1) return;
    const [field] = changedFields;
    const [name] = field.name;
    const index = field.name[1];
    if (name === "discountPercentage") {
      setDiscountPercentage(field.value);
    }
    if (name === "userId") {
      const user = userOptions?.find((x: any) => x.id === field.value) || null;
      form.setFieldValue("userDni", user?.dni || null);
      form.setFieldValue("userRtn", user?.rtn || null);
      form.setFieldValue("userPhone", user?.phoneRaw || null);
      form.setFieldValue("userPhonePrefix", user?.phonePrefix || null);
      setSelectedUser(user);
    }
    if (name === "companyId") {
      const company =
        companyOptions?.find((x: any) => x.id === field.value) || null;
      form.setFieldValue("companyRtn", company?.rtn || null);
      form.setFieldValue("companyPhone", company?.phoneRaw || null);
      form.setFieldValue("companyPhonePrefix", company?.phonePrefix || null);
      setSelectedCompany(company);
    }
    if (name === "rooms") {
      const room = roomTypeOptions?.find((x: any) => x.id === field.value);
      if (room) onRoomTypeChange(index, form, room);
    }
    if (name === "products") {
      const product = productOptions?.find((x: any) => x.id === field.value);
      if (product) onProductChange(index, form, product);
    }
    if (name === "services") {
      const service = serviceOptions?.find((x: any) => x.id === field.value);
      if (service) onServiceChange(index, form, service);
    }

    if (name === "rooms" || name === "products" || name === "services") {
      setSelectedRooms(getItems(allFields, "rooms"));
      setSelectedServices(getItems(allFields, "services"));
      setSelectedProducts(getItems(allFields, "products"));
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card
        bordered={false}
        style={{ width: 1600 }}
        className={props.onBack ? "custom-form-card" : ""}
      >
        <Row>
          <Col span={8}>
            <h2>Detalle de cotización</h2>
            <Divider style={{ marginTop: 0, marginBottom: 10 }} />
            <Form
              form={form}
              onFinish={onFinish}
              {...paymentFormLayout}
              onFinishFailed={onFinishFailed}
              onFieldsChange={onFieldsChangeForm}
            >
              <Card className="custom-card">
                <Form.Item
                  name="userId"
                  label={getSpan("Cliente")}
                  rules={[{ required: true }]}
                  style={{ width: "95%", marginBottom: 10 }}
                >
                  <Select
                    showSearch
                    size="large"
                    options={userOptions}
                    filterOption={filterData}
                    optionRender={optionsRender}
                    notFoundContent="Cliente no encontrada"
                  ></Select>
                </Form.Item>

                {selectedUser && (
                  <>
                    <Form.Item
                      name={"userPhone"}
                      label={getSpan("Teléfono")}
                      dependencies={["userPhonePrefix"]}
                      style={{ width: "95%", marginBottom: 10 }}
                      rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                          async validator(_, value) {
                            const phonePrefix =
                              getFieldValue("userPhonePrefix");
                            return validatePhoneNumber(_, phonePrefix, value);
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="large"
                        maxLength={20}
                        addonBefore={<PhonePrefix name={"userPhonePrefix"} />}
                      />
                    </Form.Item>

                    <Form.Item
                      name={"userDni"}
                      label={getSpan("DNI")}
                      style={{ width: "95%", marginBottom: 10 }}
                    >
                      <Input size="large" maxLength={50} />
                    </Form.Item>
                    <Form.Item
                      name={"userRtn"}
                      label={getSpan("RTN")}
                      style={{ width: "95%", marginBottom: 10 }}
                    >
                      <Input size="large" maxLength={50} />
                    </Form.Item>
                  </>
                )}
              </Card>

              <Card
                className="custom-card"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Form.Item
                  name="companyId"
                  label={getSpan("Empresa")}
                  style={{ width: "95%", marginBottom: 10 }}
                >
                  <Select
                    showSearch
                    size="large"
                    options={companyOptions}
                    filterOption={filterData}
                    optionRender={optionsRender}
                    notFoundContent="Empresa no encontrada"
                  ></Select>
                </Form.Item>
                {selectedCompany && (
                  <>
                    <Form.Item
                      name={"companyPhone"}
                      label={getSpan("Teléfono")}
                      dependencies={["companyPhonePrefix"]}
                      style={{ width: "95%", marginBottom: 10 }}
                      rules={[
                        ({ getFieldValue }) => ({
                          async validator(_, value) {
                            const phonePrefix =
                              getFieldValue("companyPhonePrefix");
                            return validatePhoneNumber(_, phonePrefix, value);
                          },
                        }),
                      ]}
                    >
                      <Input
                        size="large"
                        maxLength={20}
                        addonBefore={
                          <PhonePrefix name={"companyPhonePrefix"} />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={"companyRtn"}
                      label={getSpan("RTN")}
                      style={{ width: "95%", marginBottom: 10 }}
                    >
                      <Input size="large" maxLength={50} />
                    </Form.Item>
                  </>
                )}
              </Card>
              <Card
                className="custom-card"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Form.Item
                  name="date"
                  rules={[{ required: true }]}
                  label={getSpan("Fecha de evento")}
                  style={{ width: "95%", marginBottom: 10 }}
                >
                  <DatePicker
                    size="large"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name="expirationDate"
                  rules={[{ required: true }]}
                  label={getSpan("Fecha de expiración")}
                  style={{ width: "95%", marginBottom: 10 }}
                >
                  <DatePicker
                    size="large"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  initialValue={0}
                  name="discountPercentage"
                  label={getSpan("Descuento")}
                  style={{ width: "95%", marginBottom: 10 }}
                >
                  <InputNumber
                    min={0}
                    max={50}
                    size="large"
                    controls={true}
                    addonAfter="%"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Card>

              <Form.Item
                label={getSpan("Subtotal")}
                style={{ textAlign: "right", marginBottom: 0 }}
              >
                {getSpan(`L. ${getCurrencyFormat(totalCalculations.subtotal)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("Descuento")}
                style={{ textAlign: "right", marginBottom: 0 }}
              >
                {getSpan(
                  `L. ${getCurrencyFormat(totalCalculations.totalDiscount)}`
                )}
              </Form.Item>
              <Form.Item
                label={getSpan("Subtotal con Descuento")}
                style={{ textAlign: "right", marginBottom: 0 }}
              >
                {getSpan(
                  `L. ${getCurrencyFormat(
                    totalCalculations.subtotalWithDiscount
                  )}`
                )}
              </Form.Item>
              <Form.Item
                label={getSpan("I.S.V.")}
                style={{ textAlign: "right", marginBottom: 0 }}
              >
                {getSpan(`L. ${getCurrencyFormat(totalCalculations.isvTax)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("I.S.R.T.")}
                style={{
                  textAlign: "right",
                  marginBottom: 0,
                }}
              >
                {getSpan(`L. ${getCurrencyFormat(totalCalculations.isrtTax)}`)}
              </Form.Item>
              <Form.Item
                label={getSpan("Total")}
                style={{
                  textAlign: "right",
                  marginBottom: 10,
                }}
              >
                <b>
                  {getSpan(`L. ${getCurrencyFormat(totalCalculations.total)}`)}
                </b>
              </Form.Item>
              {getSpan("Notas")}
              <Form.Item noStyle name="notes">
                <TextArea
                  rows={5}
                  maxLength={200}
                  style={{ maxWidth: "100%" }}
                />
              </Form.Item>
              <Form.Item noStyle>
                <Space size="large" style={{ marginTop: 20 }}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    disabled={
                      loading ||
                      shouldDisableQuoteSubmitButton(
                        selectedRooms,
                        selectedProducts,
                        selectedServices
                      )
                    }
                  >
                    Crear Cotización
                  </Button>
                  <Button size="large" onClick={() => goBack()}>
                    Cancelar
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8} style={{ paddingLeft: 30, paddingRight: 30 }}>
            <h2>Habitaciones</h2>
            <Form
              form={form}
              onFinish={onFinish}
              {...paymentFormLayout}
              onFinishFailed={onFinishFailed}
              onFieldsChange={onFieldsChangeForm}
            >
              <Form.List name="rooms">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                    {fields.map(({ key, name, ...restField }) => (
                      <Form.Item key={key} noStyle>
                        <Row>
                          <Col span={11}>
                            <div>{getSpan("Tipo de habitación")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              name={[name, "id"]}
                              rules={[{ required: true }]}
                              validateTrigger={["onChange", "onBlur"]}
                            >
                              <Select
                                showSearch
                                size="large"
                                style={{ width: "90%" }}
                                options={roomTypeOptions}
                                filterOption={filterData}
                                notFoundContent="Habitacion no encontrado"
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <div>{getSpan("Precio")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={0}
                              name={[name, "price"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={0}
                                size="large"
                                controls={true}
                                addonBefore="L."
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                          <Col span={11}>
                            <div>{getSpan("Cantidad")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={1}
                              name={[name, "quantity"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={1}
                                size="large"
                                controls={true}
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={11}>
                            <div>{getSpan("Noches")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={1}
                              name={[name, "nights"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={1}
                                size="large"
                                controls={true}
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ marginTop: 30 }}>
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                              className="dynamic-delete-button"
                            />
                          </Col>
                        </Row>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      </Form.Item>
                    ))}
                    <Row style={{ marginTop: 10 }}>
                      <Col span={24}>
                        <Form.Item noStyle>
                          <Button
                            block
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            style={{
                              color: PRIMARY_COLOR,
                              borderColor: PRIMARY_COLOR,
                            }}
                          >
                            Agregar habitación
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          </Col>
          <Col span={8} style={{ paddingLeft: 0 }}>
            <Form
              form={form}
              onFinish={onFinish}
              {...paymentFormLayout}
              onFinishFailed={onFinishFailed}
              onFieldsChange={onFieldsChangeForm}
            >
              <Form.List name="products">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <h2>Productos</h2>
                    <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                    {fields.map(({ key, name, ...restField }) => (
                      <Form.Item key={key} noStyle>
                        <Row>
                          <Col span={11}>
                            <div>{getSpan("Nombre")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              name={[name, "key"]}
                              rules={[{ required: true }]}
                              validateTrigger={["onChange", "onBlur"]}
                            >
                              <Select
                                showSearch
                                size="large"
                                style={{ width: "90%" }}
                                options={productOptions}
                                filterOption={filterData}
                                optionRender={productOptionsRender}
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <div>{getSpan("Precio")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={0}
                              name={[name, "price"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={0}
                                controls={true}
                                addonBefore="L."
                                size="large"
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <div>{getSpan("Cantidad")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={1}
                              name={[name, "quantity"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={1}
                                controls={true}
                                size="large"
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ marginTop: 30 }}>
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                              className="dynamic-delete-button"
                            />
                          </Col>
                        </Row>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      </Form.Item>
                    ))}
                    <Row style={{ marginTop: 10 }}>
                      <Col span={24}>
                        <Form.Item noStyle>
                          <Button
                            block
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            style={{
                              color: PRIMARY_COLOR,
                              borderColor: PRIMARY_COLOR,
                            }}
                          >
                            Agregar producto
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
              <br />
              <Form.List name="services">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <h2>Servicios</h2>
                    <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                    {fields.map(({ key, name, ...restField }) => (
                      <Form.Item key={key} noStyle>
                        <Row>
                          <Col span={11}>
                            <div>{getSpan("Nombre")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              name={[name, "key"]}
                              rules={[{ required: true }]}
                              validateTrigger={["onChange", "onBlur"]}
                            >
                              <Select
                                showSearch
                                size="large"
                                style={{ width: "90%" }}
                                options={serviceOptions}
                                filterOption={filterData}
                                optionRender={productOptionsRender}
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <div>{getSpan("Precio")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={0}
                              name={[name, "price"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={0}
                                size="large"
                                controls={true}
                                addonBefore="L."
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <div>{getSpan("Cantidad")}</div>
                            <Form.Item
                              noStyle
                              {...restField}
                              initialValue={1}
                              name={[name, "quantity"]}
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                min={1}
                                size="large"
                                controls={true}
                                style={{ width: "90%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ marginTop: 30 }}>
                            <MinusCircleOutlined
                              onClick={() => remove(name)}
                              className="dynamic-delete-button"
                            />
                          </Col>
                        </Row>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      </Form.Item>
                    ))}
                    <Row style={{ marginTop: 10 }}>
                      <Col span={24}>
                        <Form.Item noStyle>
                          <Button
                            block
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            style={{
                              color: PRIMARY_COLOR,
                              borderColor: PRIMARY_COLOR,
                            }}
                          >
                            Agregar servicio
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default QuoteForm;
