import React, { useState } from "react";
import { Card, Modal, Spin } from "antd";
import { getCurrencyFormat } from "@utils/util";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { reservationStatus } from "@constants/reservationStatus";
import { clearSelectedReservation } from "@store/slices/reservation";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";

import {
  getTimeString,
  getNumberOfDays,
  getLongDateString,
  getShortDateTimeString,
} from "@utils/date";

import ReservationForm from "@pages/Reservations/ReservationForm";
import ReservationInvoice from "@pages/Reservations/ReservationInvoice";
import ReservationActionComponent from "@components/Table/Reservation/ActionComponent";

const TimelineModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [timelineModalOpen, setTimelineModalOpen] = useState(true);
  const [reservationModalOpen, setReservationModalOpen] = useState(false);

  const { selectedReservation, loading } = useAppSelector(
    (state) => state.reservation
  );

  const onModalClose = () => {
    setTimelineModalOpen(false);
    dispatch(clearSelectedReservation());
  };

  const ModalHeader = () => (
    <>
      {selectedReservation && (
        <div className="info-modal">
          <div className="info-modal-title">
            <h2 style={{ marginTop: 0 }}>{selectedReservation?.user?.name}</h2>
          </div>
          {selectedReservation?.status !== reservationStatus.COMPLETED && (
            <div className="info-modal-actions">
              <ReservationActionComponent
                reservation={selectedReservation}
                onEdit={() => setReservationModalOpen(true)}
                onPayment={() => setPaymentModalOpen(true)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <Modal
        centered
        footer={[]}
        width={800}
        open={timelineModalOpen}
        onCancel={() => onModalClose()}
        title={<ModalHeader />}
      >
        <Spin className="spinner" spinning={loading}>
          <Card className="mb" title="Detalles">
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Fechas </b>
              <br />
              <span>
                {getLongDateString(selectedReservation?.initialDate)}
                <br />
                {getLongDateString(selectedReservation?.finalDate)}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Hora de llegada </b>
              <br />
              <span>{getTimeString(selectedReservation?.arrivalTime)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Noches </b>
              <br />
              <span>
                {getNumberOfDays(
                  selectedReservation?.initialDate,
                  selectedReservation?.finalDate
                )}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Checkin </b>
              <br />
              <span>
                {getShortDateTimeString(selectedReservation?.checkinAt)}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Checkout </b>
              <br />
              <span>
                {getShortDateTimeString(selectedReservation?.checkoutAt)}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Huespedes </b>
              <br />
              <span>{selectedReservation?.guests}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>D.N.I. Cliente </b>
              <br />
              <span>{selectedReservation?.user?.dni}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>R.T.N. Cliente </b>
              <br />
              <span>{selectedReservation?.user?.rtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Teléfono Cliente</b>
              <br />
              <span>
                {selectedReservation?.user?.phonePrefix}{" "}
                {selectedReservation?.user?.phone}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Nombre Empresa </b>
              <br />
              <span>{selectedReservation?.company?.name}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>R.T.N. Empresa </b>
              <br />
              <span>{selectedReservation?.company?.rtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-trio">
              <b>Teléfono Empresa</b>
              <br />
              <span>
                {selectedReservation?.company?.phonePrefix}{" "}
                {selectedReservation?.company?.phone}
              </span>
            </Card.Grid>
          </Card>
          {selectedReservation &&
            [
              ...selectedReservation.products,
              ...selectedReservation.services,
              ...selectedReservation.roomsPayments,
            ].length > 0 && (
              <Card className="mb" title="Pagos">
                {[
                  ...selectedReservation.products,
                  ...selectedReservation.services,
                  ...selectedReservation.roomsPayments,
                ]?.map((payment: any) => (
                  <Card.Grid
                    key={payment.id}
                    hoverable={false}
                    className="table-row-detail"
                  >
                    <b>{payment.name}</b>
                    <br />
                    <b>Precio:</b>{" "}
                    <span>L.{getCurrencyFormat(payment.price)}</span>
                    <br />
                    {payment.date ? (
                      <>
                        <span>{payment.date}</span>
                        <br />
                      </>
                    ) : (
                      <>
                        <b>Cantidad:</b> <span>{payment.quantity}</span>
                        <br />
                      </>
                    )}
                    <br />
                    {payment.invoiceIds ? (
                      <CheckCircleFilled
                        style={{ color: "green", fontSize: "32px" }}
                      />
                    ) : (
                      <MinusCircleFilled
                        style={{ color: "red", fontSize: "32px" }}
                      />
                    )}
                  </Card.Grid>
                ))}
              </Card>
            )}
          {selectedReservation?.notes && (
            <Card className="mb" title="Notas">
              <Card.Grid hoverable={false} className="table-row-detail-single ">
                <span>{selectedReservation?.notes}</span>
              </Card.Grid>
            </Card>
          )}
          <Card className="mb" title="Historial de Cambios">
            <Card.Grid hoverable={false} className="table-row-detail-duo">
              <b>Creado Por </b>
              <br />
              <span>{selectedReservation?.createdBy}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-duo">
              <b>Modificado Por </b>
              <br />
              <span>{selectedReservation?.modifiedBy}</span>
            </Card.Grid>
          </Card>
        </Spin>
      </Modal>

      {reservationModalOpen && (
        <Modal
          centered
          footer={[]}
          width={950}
          open={reservationModalOpen}
          onCancel={() => setReservationModalOpen(false)}
        >
          <ReservationForm onBack={() => setReservationModalOpen(false)} />
        </Modal>
      )}

      {paymentModalOpen && (
        <Modal
          centered
          footer={[]}
          width={1650}
          open={paymentModalOpen}
          onCancel={() => setPaymentModalOpen(false)}
        >
          <ReservationInvoice onBack={() => setPaymentModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default TimelineModal;
