import { getDateString } from "@utils/date";
import { ColumnsType } from "antd/es/table";
import { Key, useRef, useState } from "react";
import { Button, Tooltip, Card, Tag } from "antd";
import { getStatusColor } from "@components/Table/helpers";
import { getInvoiceService } from "@services/invoiceService";
import { StopOutlined, PrinterOutlined } from "@ant-design/icons";
import { getReservationService } from "@services/reservationService";
import { CheckCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import {
  getTimeString,
  getNumberOfDays,
  getDateDifference,
  getShortDateTimeString,
} from "@utils/date";

import IUser from "@interfaces/IUser";
import IInvoice from "@interfaces/IInvoice";
import IReservation from "@interfaces/IReservation";
import IReservationProduct from "@interfaces/IReservationProduct";
import IReservationService from "@interfaces/IReservationService";
import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";
import ReservationActionComponent from "@components/Table/Reservation/ActionComponent";

export const getRoomPaymentColumns: ColumnsType<IReservationRoomsPayment> = [
  {
    key: "name",
    width: "50%",
    title: "Nombre",
    dataIndex: "name",
  },
  {
    key: "price",
    width: "30%",
    title: "Precio",
    align: "right",
    dataIndex: "price",
    render: (price: number) => `L. ${getCurrencyFormat(price)}`,
  },
  {
    key: "date",
    width: "20%",
    align: "center",
    title: "Fecha",
    dataIndex: "date",
  },
];

export const getProductPaymentColumns: ColumnsType<IReservationProduct> = [
  {
    key: "name",
    width: "50%",
    title: "Nombre",
    dataIndex: "name",
  },
  {
    width: "30%",
    key: "price",
    title: "Precio",
    align: "right",
    dataIndex: "price",
    render: (price: number) => `L. ${getCurrencyFormat(price)}`,
  },
  {
    width: "20%",
    key: "quantity",
    title: "Cantidad",
    align: "right",
    dataIndex: "quantity",
  },
];

export const getServicePaymentColumns: ColumnsType<IReservationService> = [
  {
    key: "name",
    width: "50%",
    title: "Nombre",
    dataIndex: "name",
  },
  {
    key: "price",
    width: "30%",
    title: "Precio",
    align: "right",
    dataIndex: "price",
    render: (price: number) => `L. ${getCurrencyFormat(price)}`,
  },
  {
    width: "20%",
    key: "quantity",
    title: "Cantidad",
    align: "right",
    dataIndex: "quantity",
  },
];

export const ActiveReservationColumns: any = (
  filteredString: Key,
  sortedInfo: any
) => [
  {
    width: "15%",
    align: "center",
    key: "initialDate",
    title: "Fecha de llegada",
    dataIndex: "initialDate",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IReservation) =>
      isFilterMatched(value, record),
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.initialDate, b.initialDate),
    sortOrder: sortedInfo.columnKey === "initialDate" ? sortedInfo.order : null,
    render: (initialDate: string) => getDateString(initialDate),
  },
  {
    width: "15%",
    align: "center",
    key: "finalDate",
    dataIndex: "finalDate",
    title: "Fecha de salida",
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.finalDate, b.finalDate),
    sortOrder: sortedInfo.columnKey === "finalDate" ? sortedInfo.order : null,
    render: (finalDate: string) => getDateString(finalDate),
  },
  {
    width: "15%",
    align: "center",
    key: "arrivalTime",
    title: "Hora de llegada",
    dataIndex: "arrivalTime",
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.arrivalTime, b.arrivalTime),
    sortOrder: sortedInfo.columnKey === "arrivalTime" ? sortedInfo.order : null,
    render: (arrivalTime: string) => getTimeString(arrivalTime),
  },
  {
    width: "25%",
    align: "center",
    key: "userName",
    title: "Cliente",
    dataIndex: "user",
    sorter: (a: IReservation, b: IReservation) =>
      sortAlphaNum(a.user?.name, b.user?.name),
    sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    render: (user: IUser) => user?.name,
  },
  {
    width: "15%",
    key: "status",
    align: "center",
    title: "Estado",
    dataIndex: "status",
    sorter: (a: IReservation, b: IReservation) =>
      sortAlphaNum(a.status, b.status),
    sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
    render: (status: string) => (
      <Tag color={getStatusColor(status)}>{status}</Tag>
    ),
  },
  {
    key: "x",
    width: "15%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (reservation: IReservation) => (
      <ReservationActionComponent reservation={reservation} />
    ),
  },
];

export const InactiveReservationColumns: any = (
  filteredString: Key,
  sortedInfo: any
) => [
  {
    width: "15%",
    align: "center",
    key: "initialDate",
    title: "Fecha de llegada",
    dataIndex: "initialDate",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IReservation) =>
      isFilterMatched(value, record),
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.initialDate, b.initialDate),
    sortOrder: sortedInfo.columnKey === "initialDate" ? sortedInfo.order : null,
    render: (initialDate: string) => getDateString(initialDate),
  },
  {
    width: "15%",
    align: "center",
    key: "finalDate",
    title: "Fecha de salida",
    dataIndex: "finalDate",
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.finalDate, b.finalDate),
    sortOrder: sortedInfo.columnKey === "finalDate" ? sortedInfo.order : null,
    render: (finalDate: string) => getDateString(finalDate),
  },
  {
    width: "15%",
    align: "center",
    key: "arrivalTime",
    title: "Hora de llegada",
    dataIndex: "arrivalTime",
    sorter: (a: IReservation, b: IReservation) =>
      getDateDifference(a.arrivalTime, b.arrivalTime),
    sortOrder: sortedInfo.columnKey === "arrivalTime" ? sortedInfo.order : null,
    render: (arrivalTime: string) => getTimeString(arrivalTime),
  },
  {
    width: "40%",
    key: "userName",
    align: "center",
    title: "Cliente",
    dataIndex: "user",
    sorter: (a: IReservation, b: IReservation) =>
      sortAlphaNum(a.user?.name, b.user?.name),
    sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
    render: (user: IUser) => user?.name,
  },
  {
    width: "15%",
    key: "status",
    align: "center",
    title: "Estado",
    dataIndex: "status",
    sorter: (a: IReservation, b: IReservation) =>
      sortAlphaNum(a.status, b.status),
    sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
    render: (status: string) => (
      <Tag color={getStatusColor(status)}>{status}</Tag>
    ),
  },
];

export const ReservationExpandableComponent = () => {
  const expandedData = useRef<Record<string, any>>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      if (!expandedData.current[record.id]) {
        const reservation = await getReservationService(record.id);
        expandedData.current[record.id] = [
          ...reservation.products,
          ...reservation.services,
          ...reservation.roomsPayments,
        ];
      }
      setExpandedRowKeys((prevKeys: any) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys: any) =>
        prevKeys.filter((key: Key) => key !== record.id)
      );
    }
  };
  const expandedRowRender = (record: any) => {
    const data = expandedData.current[record.id];
    return (
      data && (
        <>
          <Card className="mb" title="Detalles">
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Huespedes </b>
              <br />
              <span>{record.guests}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Noches </b>
              <br />
              <span>
                {getNumberOfDays(record.initialDate, record.finalDate)}
              </span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Checkin </b>
              <br />
              <span>{getShortDateTimeString(record.checkinAt)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Checkout </b>
              <br />
              <span>{getShortDateTimeString(record.checkoutAt)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>D.N.I. Cliente </b>
              <br />
              <span>{record.user?.dni}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Cliente </b>
              <br />
              <span>{record.user?.rtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Teléfono Cliente</b>
              <br />
              <span>
                {record.user?.phonePrefix} {record?.user?.phone}
              </span>
            </Card.Grid>
            <Card.Grid
              hoverable={false}
              className="table-row-detail"
            ></Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Nombre Empresa </b>
              <br />
              <span>{record?.company?.name}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Empresa </b>
              <br />
              <span>{record?.company?.rtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Teléfono Empresa</b>
              <br />
              <span>
                {record?.company?.phonePrefix} {record?.company?.phone}
              </span>
            </Card.Grid>
          </Card>
          {data.length > 0 && (
            <Card className="mb" title="Pagos">
              {data?.map((payment: any) => (
                <Card.Grid
                  key={payment.id}
                  hoverable={false}
                  className="table-row-detail"
                >
                  <b>{payment.name}</b>
                  <br />
                  <b>Precio:</b>{" "}
                  <span>L.{getCurrencyFormat(payment.price)}</span>
                  <br />
                  {payment.date ? (
                    <>
                      <span>{payment.date}</span>
                      <br />
                    </>
                  ) : (
                    <>
                      <b>Cantidad:</b> <span>{payment.quantity}</span>
                      <br />
                    </>
                  )}
                  <br />
                  {payment.invoiceIds ? (
                    <CheckCircleFilled
                      style={{ color: "green", fontSize: "32px" }}
                    />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: "red", fontSize: "32px" }}
                    />
                  )}
                </Card.Grid>
              ))}
            </Card>
          )}
          {record.notes && (
            <Card className="mb" title="Notas">
              <Card.Grid hoverable={false} className="table-row-detail-single ">
                <span>{record.notes}</span>
              </Card.Grid>
            </Card>
          )}
          <Card className="mb" title="Historial de Cambios">
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Creado Por </b>
              <br />
              <span>{record.createdBy}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Modificado Por </b>
              <br />
              <span>{record.modifiedBy}</span>
            </Card.Grid>
          </Card>
        </>
      )
    );
  };
  return {
    expandedRowKeys,
    onExpand: handleExpand,
    expandedRowRender,
  };
};

export const InvoiceDetailColumns: any = (
  onPrint: Function,
  onDelete: Function
) => [
  {
    width: "10%",
    title: "Fecha",
    align: "center",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (createdAt: string) => getDateString(createdAt),
  },
  {
    width: "20%",
    align: "center",
    key: "paymentMethod",
    title: "Método de pago",
    dataIndex: "paymentMethod",
  },
  {
    key: "total",
    width: "20%",
    title: "Total",
    align: "center",
    dataIndex: "total",
    render: (total: number) => `L. ${getCurrencyFormat(total)}`,
  },
  {
    width: "20%",
    align: "center",
    title: "Cajero",
    key: "createdBy",
    dataIndex: "createdBy",
  },
  {
    key: "x",
    width: "20%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (invoice: IInvoice) => (
      <>
        <Tooltip title="Imprimir">
          <Button
            ghost
            className="ghost-button"
            icon={
              <PrinterOutlined onClick={async () => await onPrint(invoice)} />
            }
          />
        </Tooltip>
        {invoice.invoiceName !== "Caja 1" && (
          <Tooltip title="Anular">
            <Button
              ghost
              className="ghost-button"
              icon={
                <StopOutlined onClick={async () => await onDelete(invoice)} />
              }
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const InvoiceDetailsExpandableComponent = () => {
  const expandedData = useRef<Record<string, any>>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      if (!expandedData.current[record.id]) {
        const invoice = await getInvoiceService(record.id);
        expandedData.current[record.id] = [
          ...invoice.products,
          ...invoice.services,
          ...invoice.roomsPayments,
        ];
      }
      setExpandedRowKeys((prevKeys: any) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys: any) =>
        prevKeys.filter((key: Key) => key !== record.id)
      );
    }
  };
  const expandedRowRender = (record: any) => {
    const data = expandedData.current[record.id];
    return (
      data && (
        <>
          <Card className="mb" title="Detalles">
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Tipo </b>
              <br />
              <span>{record.invoiceName}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Factura Autorizada </b>
              <br />
              <span>{record.invoiceAuthorizedNumber}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-duo">
              <b>C.A.I. </b>
              <br />
              <span>{record.invoiceCai}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Nombre Cliente </b>
              <br />
              <span>{record.invoiceClientName}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>D.N.I. Cliente </b>
              <br />
              <span>{record.invoiceClientDni}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Cliente </b>
              <br />
              <span>{record.invoiceClientRtn}</span>
            </Card.Grid>
            <Card.Grid
              hoverable={false}
              className="table-row-detail"
            ></Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Nombre Empresa </b>
              <br />
              <span>{record.invoiceCompanyName}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Empresa </b>
              <br />
              <span>{record.invoiceCompanyRtn}</span>
            </Card.Grid>
            <Card.Grid
              hoverable={false}
              className="table-row-detail"
            ></Card.Grid>
            <Card.Grid
              hoverable={false}
              className="table-row-detail"
            ></Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Subtotal </b>
              <br />
              <span>L. {getCurrencyFormat(record.subtotal)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Descuento </b>
              <br />
              <span>L. {getCurrencyFormat(record.discount)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>I.S.V. </b>
              <br />
              <span>L. {getCurrencyFormat(record.isvTax)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>I.S.R.T. </b>
              <br />
              <span>L. {getCurrencyFormat(record.isrtTax)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail-duo">
              <b>Notas </b>
              <br />
              <span>{record.invoiceNotes}</span>
            </Card.Grid>
          </Card>
          {data.length > 0 && (
            <Card className="mb" title="Pagos">
              {data?.map((payment: any) => (
                <Card.Grid
                  key={payment.id}
                  hoverable={false}
                  className="table-row-detail"
                >
                  <b>{payment.name}</b>
                  <br />
                  <b>Precio:</b>{" "}
                  <span>L.{getCurrencyFormat(payment.price)}</span>
                  <br />
                  {payment.date ? (
                    <>
                      <span>{getDateString(payment.date)}</span>
                      <br />
                    </>
                  ) : (
                    <>
                      <b>Cantidad:</b> <span>{payment.quantity}</span>
                      <br />
                    </>
                  )}
                  <br />
                  {payment.invoiceIds ? (
                    <CheckCircleFilled
                      style={{ color: "green", fontSize: "32px" }}
                    />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: "red", fontSize: "32px" }}
                    />
                  )}
                </Card.Grid>
              ))}
            </Card>
          )}
        </>
      )
    );
  };
  return {
    expandedRowKeys,
    onExpand: handleExpand,
    expandedRowRender,
  };
};
