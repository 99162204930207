import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getItemOptions, IItemOption } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getUserService,
  getUsersService,
  createUserService,
  deleteUserService,
  updateUserService,
} from "@services/userService";

import IUser from "@interfaces/IUser";

export interface UserState {
  users: IUser[];
  userOptions: IItemOption[];
  selectedUser: IUser | null;
  loading: boolean;
}

const initialState: UserState = {
  users: [],
  userOptions: [],
  selectedUser: null,
  loading: false,
};

export const fetchUserAsync = createAsyncThunk(
  "user/fetchUser",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getUserService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchUsersAsync = createAsyncThunk(
  "user/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      return await getUsersService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "user/createUser",
  async (newUser: IUser, { rejectWithValue }) => {
    try {
      return await createUserService(newUser);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (updatedUser: IUser, { rejectWithValue }) => {
    try {
      return await updateUserService(updatedUser);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteUserService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = [];
      state.userOptions = [];
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.userOptions = getItemOptions(action.payload);
        state.loading = false;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.userOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Usuario creado!");
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.userOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Usuario actualizado!");
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.userOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Usuario borrado exitosamente!");
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearUsers, clearSelectedUser } = userSlice.actions;
export default userSlice.reducer;
