import { Tag } from "antd";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";
import { getDateCountInRange } from "@utils/date";
import { reservationStatus } from "@constants/reservationStatus";

import {
  getStatusColor,
  getStatusBackgroundColor,
} from "@components/Table/helpers";

export const Draggable = (props: any) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: props.data,
  });

  const { selectedDate, reservation } = props?.data;
  const { dates, status, companyName } = reservation;

  const startDate = dates[0];
  const endDate = dates[dates.length - 1];
  const daysCount = getDateCountInRange(startDate, endDate, selectedDate) - 2;

  let width = 60;
  if (daysCount > 0) {
    width = daysCount * 120 + 110;
  } else if (daysCount === 0) {
    width = 110;
  }

  const colorName =
    companyName === "Booking" && status !== reservationStatus.COMPLETED
      ? "white"
      : "black";

  const statusColor = getStatusColor(status);
  const statusBackgroundColor = getStatusBackgroundColor(companyName, status);

  const style: any = {
    width,
    color: colorName,
    borderLeftColor: statusColor,
    backgroundColor: statusBackgroundColor,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Tag
      ref={setNodeRef}
      className="draggable-class"
      style={style}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </Tag>
  );
};
