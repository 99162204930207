import axios from "axios";
import IUser from "@interfaces/IUser";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/user";

export const getUserService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getUsersService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const getStaffUsersService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/staff`, headers);
  return response.data;
};

export const createUserService = async (userData: IUser) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    userData,
    headers
  );
  return response.data;
};

export const updateUserService = async (userData: IUser) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${userData.id}`,
    userData,
    headers
  );
  return response.data;
};

export const deleteUserService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
