import { getDate, isDateToday } from "@utils/date";
import { getCalendarColumnDate, TimelineFilterComponent } from "./Components";

export const roomsColumn = (dateFilter: any) => ({
  fixed: "left",
  dataIndex: "rooms",
  title: dateFilter,
});

export const getColumns = (data: any, selectedDate: any, onDateChange: any) => {
  if (!data) return [];
  const dateFilter = TimelineFilterComponent(selectedDate, onDateChange);
  const fixedColumn = roomsColumn(dateFilter);

  const columns = data?.columns?.map((key: any) => {
    const isToday = isDateToday(key);
    const title = getCalendarColumnDate(key);
    return {
      title,
      width: 120,
      dataIndex: key,
      className: isToday ? "today-class" : "cell-class",
      onCell: () => ({ colSpan: 1 }),
    };
  });
  return [fixedColumn, ...columns];
};

export const getUpdatedTimelineReservation = (active: any, over: any) => {
  const reservation = active?.reservation;
  const totalDates = reservation.dates.length;
  const initialDate = getDate(over.date)?.toISOString();
  const finalDate = getDate(over.date)
    ?.add(totalDates - 1, "day")
    .toISOString();
  return {
    finalDate,
    initialDate,
    roomId: reservation.roomId,
    reservationId: reservation.reservationId,
    newRoomId: over.room ? over.room.id : over.roomId,
  };
};

export const sortDraggableComponents = (draggableComponents: any) =>
  draggableComponents.sort((a: any, b: any) => {
    const datesA = a.props.reservation.dates || [];
    const datesB = b.props.reservation.dates || [];
    return datesB.length - datesA.length;
  });
