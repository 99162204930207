import { Key } from "react";
import { isFilterMatched, sortAlphaNum } from "@utils/util";
import { getDateDifference, getDateString } from "@utils/date";

import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

export const InvoiceConfigurationColumns: any = (
  filteredString: Key,
  sortedInfo: any
) => [
  {
    key: "name",
    width: "10%",
    title: "Nombre",
    align: "center",
    dataIndex: "name",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IInvoiceConfiguration) =>
      isFilterMatched(value, record),
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      sortAlphaNum(a.name, b.name),
    sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
  },
  {
    key: "cai",
    title: "CAI",
    width: "30%",
    align: "center",
    dataIndex: "cai",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      sortAlphaNum(a.cai, b.cai),
    sortOrder: sortedInfo.columnKey === "cai" ? sortedInfo.order : null,
  },
  {
    width: "10%",
    align: "center",
    key: "deadlineForIssuance",
    title: "Fecha limite emisión",
    dataIndex: "deadlineForIssuance",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      getDateDifference(a.deadlineForIssuance, b.deadlineForIssuance),
    sortOrder:
      sortedInfo.columnKey === "deadlineForIssuance" ? sortedInfo.order : null,
    render: (deadlineForIssuance: string) => getDateString(deadlineForIssuance),
  },
  {
    width: "15%",
    align: "center",
    key: "initialAuthorizedRange",
    title: "Rango inicial autorizado",
    dataIndex: "initialAuthorizedRange",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      sortAlphaNum(a.initialAuthorizedRange, b.initialAuthorizedRange),
    sortOrder:
      sortedInfo.columnKey === "initialAuthorizedRange"
        ? sortedInfo.order
        : null,
  },
  {
    width: "15%",
    align: "center",
    key: "finalAuthorizedRange",
    title: "Rango final autorizado",
    dataIndex: "finalAuthorizedRange",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      sortAlphaNum(a.finalAuthorizedRange, b.finalAuthorizedRange),
    sortOrder:
      sortedInfo.columnKey === "finalAuthorizedRange" ? sortedInfo.order : null,
  },
  {
    width: "10%",
    align: "center",
    key: "currentNumeration",
    title: "Numeración actual",
    dataIndex: "currentNumeration",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      a.currentNumeration - b.currentNumeration,
    sortOrder:
      sortedInfo.columnKey === "currentNumeration" ? sortedInfo.order : null,
  },
  {
    width: "10%",
    align: "center",
    key: "totalNumeration",
    title: "Numeración total",
    dataIndex: "totalNumeration",
    sorter: (a: IInvoiceConfiguration, b: IInvoiceConfiguration) =>
      a.totalNumeration - b.totalNumeration,
    sortOrder:
      sortedInfo.columnKey === "totalNumeration" ? sortedInfo.order : null,
  },
];
