import { Key } from "react";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import IProduct from "@interfaces/IProduct";
import ActionComponent from "@components/Table/ActionComponent";

export const ProductColumns: any = (
  filteredString: Key,
  sortedInfo: any
) => [
  {
    key: "name",
    width: "35%",
    title: "Nombre",
    align: "center",
    dataIndex: "name",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IProduct) =>
      isFilterMatched(value, record),
    sorter: (a: IProduct, b: IProduct) => sortAlphaNum(a.name, b.name),
    sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
  },
  {
    width: "35%",
    align: "center",
    key: "description",
    title: "Descripción",
    dataIndex: "description",
    sorter: (a: IProduct, b: IProduct) =>
      sortAlphaNum(a.description, b.description),
    sortOrder: sortedInfo.columnKey === "description" ? sortedInfo.order : null,
  },
  {
    key: "price",
    width: "15%",
    title: "Precio",
    align: "center",
    dataIndex: "price",
    sorter: (a: IProduct, b: IProduct) => a.price - b.price,
    sortOrder: sortedInfo.columnKey === "price" ? sortedInfo.order : null,
    render: (price: number) => `L. ${getCurrencyFormat(price)}`,
  },
  {
    key: "x",
    width: "15%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (product: IProduct) => (
      <ActionComponent
        label="producto"
        dataObject={product}
      />
    ),
  },
];
