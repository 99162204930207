import React from "react";
import { ITableToolbarProps } from "./helpers";
import { Input, Col, Row, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

const TableToolbar: React.FC<ITableToolbarProps> = (props) => {
  const navigate = useNavigate();

  const onFilterChange = (e: any) => {
    props.onFilterChange(e.target.value);
  };

  return (
    <Row>
      <Col style={{ display: "flex" }} flex={3}>
        <Input
          allowClear
          size="large"
          placeholder="Buscar..."
          onChange={onFilterChange}
          prefix={<SearchOutlined />}
        />
      </Col>
      <Col
        flex={3}
        style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        {props.canCreate ? (
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginRight: "50px" }}
            onClick={() => navigate("form")}
          >
            Nuevo
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};

export default TableToolbar;
