import { Empty } from "antd";
import { useAppDispatch } from "@store/hooks";
import { connectAndPrint } from "@utils/printer";
import { deleteInvoiceAsync } from "@store/slices/invoice";
import { fetchReservationAsync } from "@store/slices/reservation";

import {
  InvoiceDetailColumns,
  InvoiceDetailsExpandableComponent,
} from "./Components";

import Table from "antd/es/table";
import IInvoice from "@interfaces/IInvoice";

const InvoicesDetails: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();

  const onPrint = async (invoice: IInvoice) =>
    await connectAndPrint([invoice], props.hotel);

  const onDelete = async (invoice: IInvoice) => {
    await dispatch(deleteInvoiceAsync(invoice.id));
    await dispatch(fetchReservationAsync(props.reservation.id));
  };

  return (
    <>
      {props.reservation && props.reservation.invoices.length > 0 ? (
        <Table
          pagination={false}
          rowKey={(x) => x.id}
          dataSource={props.reservation.invoices}
          expandable={InvoiceDetailsExpandableComponent()}
          columns={InvoiceDetailColumns(onPrint, onDelete)}
        />
      ) : (
        <Empty description="No hay facturas" />
      )}
    </>
  );
};

export default InvoicesDetails;
