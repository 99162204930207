import jsPDF from "jspdf";
import IHotel from "@interfaces/IHotel";
import IQuote from "@interfaces/IQuote";

import { getDateString, getLongDateString } from "@utils/date";
import { HOTEL_LOGO_URL } from "@constants/globalConstants";
import autoTable from "jspdf-autotable";
import { getCurrencyFormat } from "@utils/util";

export interface CalculationInputs {
  selectedRooms: any[];
  selectedProducts: any[];
  selectedServices: any[];
  discountPercentage: number;
}

export interface CalculationOutputs {
  total: number;
  isvTax: number;
  isrtTax: number;
  subtotal: number;
  totalDiscount: number;
  subtotalWithDiscount: number;
}

export const getUniqueByType = (items: any) =>
  Object.values(
    items.reduce((acc: any, { type, price }: any) => {
      if (!acc[type]) {
        acc[type] = { id: type, label: type, value: type, price };
      }
      return acc;
    }, {})
  );

export const calculateSubtotal = (items: any[], priceMultiplier: number = 1) =>
  items?.reduce((accumulator, item) => {
    const nights = Number(item.nights || 1);
    return (
      accumulator +
      Number(item.price) * Number(item.quantity || 1) * nights * priceMultiplier
    );
  }, 0);

export const getQuoteTotalsCalculation = ({
  selectedRooms,
  selectedProducts,
  selectedServices,
  discountPercentage,
}: CalculationInputs): CalculationOutputs => {
  const calculateDiscount = (subtotal: number) =>
    (subtotal * discountPercentage) / 100 || 0;

  const calculateSubtotals = (items: any[]) => {
    const subtotal = calculateSubtotal(items);
    const discount = calculateDiscount(subtotal);
    const subtotalWithDiscount = subtotal - discount;

    return { subtotal, discount, subtotalWithDiscount };
  };

  const rooms = calculateSubtotals(selectedRooms);
  const products = calculateSubtotals(selectedProducts);
  const services = calculateSubtotals(selectedServices);

  const totalDiscount = rooms.discount + products.discount + services.discount;
  const subtotal = rooms.subtotal + products.subtotal + services.subtotal;
  const subtotalWithDiscount =
    rooms.subtotalWithDiscount +
    products.subtotalWithDiscount +
    services.subtotalWithDiscount;

  const isvTax = subtotalWithDiscount * 0.15;
  const isrtTax = rooms.subtotalWithDiscount * 0.04;
  const total = subtotalWithDiscount + isvTax + isrtTax;

  return {
    total,
    isvTax,
    isrtTax,
    subtotal,
    totalDiscount,
    subtotalWithDiscount,
  };
};

export const setHeaderSection = (doc: jsPDF, hotel: IHotel, quote: IQuote) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const logoWidth = 30;
  const logoHeight = 30;
  const headerY = 10;
  const lineHeight = 6;

  const columnWidth = pageWidth / 3;

  const logoX = columnWidth / 2 - logoWidth / 2; // Center logo in the first column
  doc.addImage(
    HOTEL_LOGO_URL,
    "JPEG",
    logoX - 5,
    headerY,
    logoWidth,
    logoHeight
  );
  // --- Middle text ---
  const HOTEL_NAME = hotel.name || "";
  const middleColumnX = columnWidth + columnWidth / 2; // Center of the second column
  let middleY = headerY + 5; // Start below the header

  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(HOTEL_NAME, middleColumnX, middleY, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(9);

  const hotelDetails = [
    hotel.legalName,
    hotel.rtn,
    hotel.address,
    hotel.phone,
    hotel.email,
  ];

  hotelDetails.forEach((detail) => {
    middleY += lineHeight - 1;
    doc.text(detail || "", middleColumnX, middleY, { align: "center" });
  });

  // --- Info block on the right ---
  const rightColumnX = columnWidth * 2 + columnWidth / 2; // Center of the third column
  let rightY = headerY + 5;

  const infoBlock = [
    `ID: ${quote.id?.replace(/-/g, "").slice(0, 8)}`,
    `Fecha: ${getDateString(quote.createdAt)}`,
  ];

  infoBlock.forEach((info, index) => {
    doc.text(info, rightColumnX + 20, rightY + index * lineHeight, {
      align: "right",
    });
  });

  // --- Divider ---
  doc.setDrawColor(200, 200, 200);
  doc.line(
    10,
    headerY + logoHeight + 5,
    pageWidth - 10,
    headerY + logoHeight + 5
  );
};

export const setContactSection = (doc: jsPDF, quote: IQuote) => {
  const {
    date,
    userName,
    companyId,
    createdBy,
    companyName,
    contactDetail,
    expirationDate,
  } = quote;

  const lineHeight = 6; // Space between rows
  const marginX = 10; // Left margin
  let currentY = 55; // Start below the header section

  const sectionWidth = doc.internal.pageSize.getWidth() - 2 * marginX; // Width of the content area
  const leftColumnWidth = sectionWidth * 0.5; // 60% width for the left column

  const leftValueOffset = 30;
  const rightValueOffset = 10;

  const addLeftLabelValue = (
    label: string,
    value: string,
    x: number,
    y: number
  ) => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(label, x + leftValueOffset - 20, y);

    doc.setFont("helvetica", "normal");
    doc.text(value, x + leftValueOffset, y);
  };

  const addRightLabelValue = (
    label: string,
    value: string,
    x: number,
    y: number
  ) => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(label, x + rightValueOffset - 20, y);

    doc.setFont("helvetica", "normal");
    doc.text(value, x + rightValueOffset + 20, y);
  };

  // --- Left Column ---
  const leftX = marginX;
  let leftY = currentY;

  addLeftLabelValue("Cliente: ", userName || "", leftX, leftY);
  leftY += lineHeight;

  if (!companyId) {
    addLeftLabelValue("DNI: ", contactDetail.user.dni || "", leftX, leftY);
    leftY += lineHeight;
    addLeftLabelValue("RTN: ", contactDetail.user.rtn || "", leftX, leftY);
    leftY += lineHeight;
    addLeftLabelValue("Email: ", contactDetail.user.email || "", leftX, leftY);
    leftY += lineHeight;
    addLeftLabelValue(
      "Teléfono: ",
      `(${contactDetail.user.phonePrefix || ""}) ${
        contactDetail.user.phone || ""
      }`,
      leftX,
      leftY
    );
    leftY += lineHeight;
  } else {
    addLeftLabelValue("Empresa: ", companyName || "", leftX, leftY);
    leftY += lineHeight;
    addLeftLabelValue("RTN: ", contactDetail.company.rtn || "", leftX, leftY);
    leftY += lineHeight;
    addLeftLabelValue(
      "Email: ",
      contactDetail.company.email || "",
      leftX,
      leftY
    );
    leftY += lineHeight;
    addLeftLabelValue(
      "Teléfono: ",
      `(${contactDetail.company.phonePrefix || ""}) ${
        contactDetail.company.phone || ""
      }`,
      leftX,
      leftY
    );
    leftY += lineHeight;
  }

  // --- Right Column ---
  const rightX = marginX + leftColumnWidth + 10; // Space between columns
  let rightY = currentY;

  addRightLabelValue(
    "Fecha de evento: ",
    getLongDateString(date),
    rightX,
    rightY
  );
  rightY += lineHeight;
  addRightLabelValue(
    "Fecha de expiración: ",
    getLongDateString(expirationDate),
    rightX,
    rightY
  );
  rightY += lineHeight;
  addRightLabelValue("Reservado por: ", createdBy || "", rightX, rightY);
  rightY += lineHeight;
};

export const setObservationsSection = (
  doc: jsPDF,
  text: string,
  startY: number
) => {
  const marginX = 20; 
  const maxWidth = doc.internal.pageSize.width - 2 * marginX; 
  const lineHeight = 6; 

  startY += 10;

  doc.setFontSize(10);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(0, 0, 0);
  doc.text("Observaciones", marginX, startY);

  doc.setFont("helvetica", "normal");
  const wrappedText = doc.splitTextToSize(text, maxWidth);
  doc.text(wrappedText, marginX, startY + lineHeight);

  const textHeight = wrappedText.length + lineHeight;
  const dividerY = startY + textHeight + lineHeight;

  doc.setDrawColor(200, 200, 200);
  doc.line(marginX, dividerY, doc.internal.pageSize.width - marginX, dividerY); 

  return dividerY; 
};

export const setRoomsSection = (doc: jsPDF, rooms: any, currentY: number) => {
  currentY += 5;

  // --- Rooms Table Section ---
  const roomData = rooms?.map((room: any) => [
    `Habitación ${room.name}`,
    `L. ${getCurrencyFormat(room.price)}`,
    room.nights,
    room.quantity,
    `L. ${getCurrencyFormat(room.price * room.nights * room.quantity)}`,
  ]);

  const roomHeaders = [
    "Descripción",
    "Precio (por noche)",
    "Noches",
    "Cantidad",
    "Total",
  ];

  autoTable(doc, {
    startY: currentY,
    head: [roomHeaders],
    body: roomData,
    theme: "grid",
    styles: {
      fontSize: 10,
      cellPadding: 3,
    },
    headStyles: {
      halign: "center",
      fontStyle: "bold",
      textColor: [0, 0, 0],
      fillColor: [200, 200, 200],
    },
    columnStyles: {
      0: { halign: "center" },
      1: { halign: "center" },
      2: { halign: "center" },
      3: { halign: "center" },
      4: { halign: "center" },
    },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    didDrawPage: (data: any) => {
      currentY = data.cursor.y;
    },
  });
  return currentY;
};

export const setProductsSection = (
  doc: jsPDF,
  products: any,
  currentY: number
) => {
  currentY += 10;

  // --- Products Table Section ---
  const productData = products?.map((product: any) => [
    `${product.name}`,
    `L. ${getCurrencyFormat(product.price)}`,
    product.quantity,
    `L. ${getCurrencyFormat(product.price * product.quantity)}`,
  ]);

  const productHeaders = ["Descripción", "Precio", "Cantidad", "Total"];
  autoTable(doc, {
    startY: currentY,
    head: [productHeaders],
    body: productData,
    theme: "grid",
    styles: {
      fontSize: 10,
      cellPadding: 3,
    },
    headStyles: {
      halign: "center",
      fontStyle: "bold",
      textColor: [0, 0, 0],
      fillColor: [200, 200, 200],
    },
    columnStyles: {
      0: { halign: "center" },
      1: { halign: "center" },
      2: { halign: "center" },
      3: { halign: "center" },
    },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    didDrawPage: (data: any) => {
      currentY = data.cursor.y;
    },
  });
  return currentY;
};

export const setServicesSection = (
  doc: jsPDF,
  services: any,
  currentY: number
) => {
  currentY += 10;

  // --- Services Table Section ---
  const serviceData = services?.map((service: any) => [
    `${service.name}`,
    `L. ${getCurrencyFormat(service.price)}`,
    service.quantity,
    `L. ${getCurrencyFormat(service.price * service.quantity)}`,
  ]);

  const serviceHeaders = ["Descripción", "Precio", "Cantidad", "Total"];
  autoTable(doc, {
    startY: currentY,
    head: [serviceHeaders],
    body: serviceData,
    theme: "grid",
    styles: {
      fontSize: 10,
      cellPadding: 3,
    },
    headStyles: {
      halign: "center",
      fontStyle: "bold",
      textColor: [0, 0, 0],
      fillColor: [200, 200, 200],
    },
    columnStyles: {
      0: { halign: "center" },
      1: { halign: "center" },
      2: { halign: "center" },
      3: { halign: "center" },
    },
    alternateRowStyles: { fillColor: [245, 245, 245] },
    didDrawPage: (data: any) => {
      currentY = data.cursor.y;
    },
  });
  return currentY;
};

export const setDetailsSection = (
  doc: jsPDF,
  quoteDetail: any,
  currentY: number
) => {
  const { total, isvTax, isrtTax, subtotal, totalDiscount } = quoteDetail;
  currentY += 10;
  doc.setDrawColor(200);
  doc.setLineWidth(0.5);
  doc.line(10, currentY, 200, currentY);
  currentY += 10;

  const details = [
    { label: "Subtotal:", value: `L. ${getCurrencyFormat(subtotal)}` },
    { label: "Descuento:", value: `L. ${getCurrencyFormat(totalDiscount)}` },
    { label: "I.S.V.:", value: `L. ${getCurrencyFormat(isvTax)}` },
    { label: "I.S.R.T.:", value: `L. ${getCurrencyFormat(isrtTax)}` },
    { label: "Total:", value: `L. ${getCurrencyFormat(total)}` },
  ];

  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);

  const pageWidth = doc.internal.pageSize.width;
  const marginLeft = 30;
  const marginRight = 10;
  const maxContentWidth = pageWidth - marginRight;
  const lineSpacing = 6;

  details.forEach((detail) => {
    doc.setFont("helvetica", "bold");
    const labelWidth = doc.getTextWidth(detail.label);
    doc.text(detail.label, maxContentWidth - labelWidth - marginLeft, currentY);

    doc.setFont("helvetica", "normal");
    const valueWidth = doc.getTextWidth(detail.value);
    doc.text(detail.value, maxContentWidth - valueWidth, currentY);

    currentY += lineSpacing;
  });

  return currentY;
};

export const setFooterWithPageNumbers = (doc: jsPDF) => {
  const totalPagesExp = "{total_pages_count_string}";
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const footerFontSize = 10;

  doc.setFont("helvetica", "normal");
  doc.setFontSize(footerFontSize);

  const footerHeight = 10;
  const totalPages = doc.getNumberOfPages();

  for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
    doc.setPage(pageNum);
    const text = `Página ${pageNum} of ${totalPagesExp}`;
    const textWidth = doc.getTextWidth(text) / 2;
    const x = (pageWidth - textWidth) / 2;
    const y = pageHeight - footerHeight;
    doc.text(text, x, y);
  }

  doc.putTotalPages(totalPagesExp);
};
