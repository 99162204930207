import React, { Key, useEffect, useState } from "react";
import { Table, Layout, Card } from "antd";
import { ProductColumns } from "./helpers/Components";
import { fetchProductsAsync } from "@store/slices/product";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const ProductsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  
  const { products, loading } = useAppSelector((state) => state.product);

  useEffect(() => {
    if (products.length === 0) dispatch(fetchProductsAsync());
  }, [dispatch, products.length]);

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            dataSource={products}
            pagination={{ pageSize: 20 }}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={ProductColumns(filteredString, sortedInfo)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ProductsTable;
