import axios from "axios";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/hotel";

export const getHotelService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
