import TimePicker from "@components/Common/TimePicker";
import DatePicker from "@components/Common/DatePicker";

import { filterData } from "@components/Form";
import { Form, Select, InputNumber } from "antd";
import { getSpan, optionsRender } from "@components/Common/Render";

const { RangePicker } = DatePicker;

const ReservationDetails: React.FC<any> = (props) => (
  <>
    <Form.Item name="id" hidden />
    <Form.Item name="status" hidden />

    <Form.Item
      name="dates"
      label={getSpan("Fechas")}
      rules={[{ required: true }]}
    >
      <RangePicker style={{ width: "80%" }} format={"ddd DD/MM/YY"} />
    </Form.Item>
    <Form.Item
      name="arrivalTime"
      rules={[{ required: true }]}
      label={getSpan("Hora de llegada")}
    >
      <TimePicker style={{ width: "80%" }} use12Hours format="h:mm a" />
    </Form.Item>
    <Form.Item
      name="guests"
      initialValue={2}
      label={getSpan("Huespedes")}
      rules={[{ required: true }]}
    >
      <InputNumber style={{ width: "80%" }} controls={true} min={1} />
    </Form.Item>
    <Form.Item
      name="userId"
      label={getSpan("Cliente")}
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        style={{ width: "80%" }}
        filterOption={filterData}
        options={props.userOptions}
        optionRender={optionsRender}
        notFoundContent="Usuario no encontrado"
      ></Select>
    </Form.Item>
    <Form.Item name="companyId" label={getSpan("Empresa")}>
      <Select
        showSearch
        style={{ width: "80%" }}
        filterOption={filterData}
        optionRender={optionsRender}
        options={props.companyOptions}
        notFoundContent="Empresa no encontrada"
      ></Select>
    </Form.Item>
  </>
);

export default ReservationDetails;
