import { Col, Form, Input, Row } from "antd";

const { TextArea } = Input;

const NotesDetails: React.FC = () => (
  <Row style={{ marginBottom: 20 }}>
    <Col span={24}>
      <Form.Item name="notes" noStyle>
        <TextArea style={{ width: "90%" }} rows={8} maxLength={400} />
      </Form.Item>
    </Col>
  </Row>
);

export default NotesDetails;
