import React, { useEffect, useState } from "react";
import { fetchUsersAsync } from "@store/slices/user";
import { fetchRoomsAsync } from "@store/slices/room";
import { getDateDatabase, getTime } from "@utils/date";
import { useNavigate, useParams } from "react-router-dom";
import { reservationLayout } from "@constants/formLayout";
import { fetchProductsAsync } from "@store/slices/product";
import { fetchServicesAsync } from "@store/slices/service";
import { fetchCompaniesAsync } from "@store/slices/company";
import { Tabs, Card, Form, Spin, Space, Button } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { IFormProps, getFieldData, onFinishFailed } from "@components/Form";

import {
  onRoomChange,
  onProductChange,
  onServiceChange,
} from "@pages/Invoice/helpers";

import {
  fetchReservationAsync,
  createReservationAsync,
  updateReservationAsync,
  clearSelectedReservation,
} from "@store/slices/reservation";

import IFieldData from "@interfaces/IFieldData";
import IReservation from "@interfaces/IReservation";
import FloatButtons from "@components/FloatButtons";
import RoomsDetails from "@components/Reservations/RoomsDetails";
import NotesDetails from "@components/Reservations/NotesDetails";
import ProductsDetails from "@components/Reservations/ProductsDetails";
import InvoicesDetails from "@components/Reservations/InvoicesDetails";
import ServicesDetails from "@components/Reservations/ServicesDetails";
import ReservationDetails from "@components/Reservations/ReservationDetails";

const ReservationForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");

  const { hotel } = useAppSelector((state) => state.hotel);
  const { selectedReservation, loading: reservationLoading } = useAppSelector(
    (state) => state.reservation
  );
  const {
    rooms,
    roomOptions,
    loading: roomLoading,
  } = useAppSelector((state) => state.room);
  const {
    products,
    productOptions,
    loading: productLoading,
  } = useAppSelector((state) => state.product);
  const {
    services,
    serviceOptions,
    loading: serviceLoading,
  } = useAppSelector((state) => state.service);
  const {
    users,
    userOptions,
    loading: userLoading,
  } = useAppSelector((state) => state.user);
  const {
    companies,
    companyOptions,
    loading: companyLoading,
  } = useAppSelector((state) => state.company);

  useEffect(() => {
    setLoading(
      roomLoading ||
        userLoading ||
        productLoading ||
        serviceLoading ||
        companyLoading ||
        reservationLoading
    );
  }, [
    roomLoading,
    userLoading,
    productLoading,
    serviceLoading,
    companyLoading,
    reservationLoading,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedReservation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedReservation) {
      const arrivalTime = getTime(selectedReservation.arrivalTime);
      const dates = [
        getDateDatabase(selectedReservation.initialDate),
        getDateDatabase(selectedReservation.finalDate),
      ];
      const reservation = Object.assign({}, selectedReservation, {
        dates,
        arrivalTime,
      });
      setFields(getFieldData(reservation));
    } else if (!selectedReservation && guid) {
      dispatch(fetchReservationAsync(guid));
    }
  }, [dispatch, guid, selectedReservation]);

  useEffect(() => {
    if (rooms.length === 0) {
      dispatch(fetchRoomsAsync());
    }
  }, [dispatch, rooms]);

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProductsAsync());
    }
  }, [dispatch, products]);

  useEffect(() => {
    if (services.length === 0) {
      dispatch(fetchServicesAsync());
    }
  }, [dispatch, services]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(fetchCompaniesAsync());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsersAsync());
    }
  }, [dispatch, users]);

  const onFieldsChangeForm = (changedFields: any) => {
    const [field] = changedFields;
    const [name] = field.name;
    const index = field.name[1];
    if (name === "rooms") {
      const room = roomOptions?.find((x: any) => x.key === field.value);
      if (!room) return;
      onRoomChange(index, form, room);
    }
    if (name === "products") {
      const product = productOptions?.find((x: any) => x.key === field.value);
      if (!product) return;
      onProductChange(index, form, product);
    }
    if (name === "services") {
      const service = serviceOptions?.find((x: any) => x.key === field.value);
      if (!service) return;
      onServiceChange(index, form, service);
    }
  };

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (data: IReservation) => {
    const reservationData = Object.assign({}, selectedReservation, data);
    if (selectedReservation) {
      await dispatch(updateReservationAsync(reservationData));
    } else {
      await dispatch(createReservationAsync(reservationData));
    }
    goBack();
  };

  const reservationDetails = () => {
    return {
      label: "Reservación",
      card: (
        <ReservationDetails
          userOptions={userOptions}
          companyOptions={companyOptions}
        />
      ),
    };
  };

  const roomDetails = () => ({
    label: "Habitaciones",
    card: <RoomsDetails form={form} roomOptions={roomOptions} />,
  });

  const productsDetails = () => ({
    label: "Productos",
    card: <ProductsDetails form={form} productOptions={productOptions} />,
  });

  const servicesDetails = () => ({
    label: "Servicios",
    card: <ServicesDetails form={form} serviceOptions={serviceOptions} />,
  });

  const notesDetails = () => ({
    label: "Notas",
    card: <NotesDetails />,
  });

  const invoicesDetails = () => ({
    label: "Facturas",
    card: <InvoicesDetails hotel={hotel} reservation={selectedReservation} />,
  });

  return (
    <Spin className="spinner" spinning={loading}>
      <Card
        bordered={false}
        style={{ width: 900 }}
        className={props.onBack ? "custom-form-card" : ""}
      >
        <Form
          {...reservationLayout}
          form={form}
          fields={fields}
          onFinish={onFinish}
          initialValues={{ rooms: [] }}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChangeForm}
        >
          <Tabs
            type="card"
            size="large"
            defaultActiveKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            items={[
              reservationDetails(),
              roomDetails(),
              productsDetails(),
              servicesDetails(),
              notesDetails(),
              invoicesDetails(),
            ].map((detail, i) => {
              const id = String(i + 1);
              return {
                key: id,
                forceRender: true,
                label: detail.label,
                children: detail.card,
              };
            })}
          />
          {activeTabKey !== "6" && (
            <Form.Item>
              <Space size="large" style={{ marginTop: 10 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={reservationLoading}
                >
                  {selectedReservation
                    ? "Editar Reservación"
                    : "Crear Reservación"}
                </Button>
                <Button onClick={() => goBack()}>Cancelar</Button>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Card>
      <FloatButtons />
    </Spin>
  );
};

export default ReservationForm;
