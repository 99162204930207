import React, { Key, useEffect, useState } from "react";
import { getToday } from "@utils/date";
import { Row, Col, Card, Table, Layout } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { fetchActiveReservationsAsync } from "@store/slices/reservation";

import {
  ActiveReservationColumns,
  ReservationExpandableComponent,
} from "@components/Reservations/Components";

import DateToolbar from "@components/Table/DateToolbar";
import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const ReservationsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  const [filteredDate, setFilteredDate] = useState<any>(getToday());
  const { activeReservations, loading } = useAppSelector(
    (state) => state.reservation
  );

  useEffect(() => {
    if (activeReservations.length === 0)
      dispatch(fetchActiveReservationsAsync(filteredDate));
  }, [dispatch, filteredDate, activeReservations.length]);

  useEffect(() => {
    dispatch(fetchActiveReservationsAsync(filteredDate));
  }, [dispatch, filteredDate]);

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <Row>
            <Col style={{ display: "flex" }} flex={0.5}>
              <DateToolbar
                filteredDate={filteredDate}
                setFilteredDate={setFilteredDate}
              />
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} flex={12}>
              <TableToolbar
                onFilterChange={setFilteredString}
                canCreate={true}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            pagination={{ pageSize: 20 }}
            dataSource={activeReservations}
            expandable={ReservationExpandableComponent()}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={ActiveReservationColumns(filteredString, sortedInfo)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ReservationsTable;
