import React from "react";
import { useNavigate } from "react-router-dom";
import { doLoginAsync } from "@store/slices/auth";
import { Card, Form, Spin, Input, Image, Button } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { HOTEL_LOGO_URL, PRIMARY_COLOR } from "@constants/globalConstants";

const primaryColor = PRIMARY_COLOR;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.auth);

  const onFinish = async (userData: any) => {
    const { username, password } = userData;
    await dispatch(doLoginAsync({ username, password }));
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <div className="login">
        <Card className="login-card" title={null}>
          <div
            style={{ textAlign: "center", paddingTop: 20, paddingBottom: 40 }}
          >
            <Image
              width="60%"
              height="60%"
              preview={false}
              src={HOTEL_LOGO_URL}
            />
          </div>
          <Form
            name="basic"
            size={"large"}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Correo no es valido.",
                },
              ]}
            >
              <Input className="input-class" placeholder="Correo electrónico" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "Contraseña no es valida." }]}
            >
              <Input.Password
                className="input-class"
                placeholder="Contraseña"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="link"
                size={"small"}
                style={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: primaryColor,
                }}
                onClick={() => navigate("reset-password")}
              >
                ¿Olvidó su contraseña?
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Iniciar sesión
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

export default Login;
