import React from "react";
import Rooms from "@pages/Rooms";
import Users from "@pages/Users";
import Login from "@pages/Login";
import Quotes from "@pages/Quotes";
import Timeline from "@pages/Timeline";
import Products from "@pages/Products";
import Services from "@pages/Services";
import Companies from "@pages/Companies";
import RoomForm from "@pages/Rooms/RoomForm";
import UserForm from "@pages/Users/UserForm";
import Reservations from "@pages/Reservations";
import QuoteForm from "@pages/Quotes/QuoteForm";
import ProductForm from "@pages/Products/ProductForm";
import ServiceForm from "@pages/Services/ServiceForm";
import CompanyForm from "@pages/Companies/CompanyForm";
import ResetPassword from "@pages/Login/ResetPassword";
import InvoiceHistory from "@pages/Invoice/InvoiceHistory";
import InvoiceConfiguration from "@pages/InvoiceConfiguration";
import ReservationForm from "@pages/Reservations/ReservationForm";
import SalesReportByRooms from "@pages/Reports/SalesReportByRooms";
import ReservationHistory from "@pages/Reservations/ReservationHistory";
import ReservationInvoice from "@pages/Reservations/ReservationInvoice";
import SalesReportByRoomTypes from "@pages/Reports/SalesReportByRoomTypes";
import ReportByRoomTypeQuantity from "@pages/Reports/ReportByRoomTypeQuantity";
import CashRegisterClosingReport from "@pages/Reports/CashRegisterClosingReport";
import SalesReportByPaymentMethod from "@pages/Reports/SalesReportByPaymentMethod";
import InvoiceConfigurationForm from "@pages/InvoiceConfiguration/InvoiceConfigurationForm";

import { Routes, Route } from "react-router-dom";

export const PublicContent: React.FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export const PrivateContent: React.FC<any> = ({ isAdmin }) => {
  return (
    <Routes>
      <Route path="/" element={<Timeline />} />
      <Route path="timeline" element={<Timeline />} />
      <Route path="reservations">
        <Route index element={<Reservations />} />
        <Route path="form" element={<ReservationForm />} />
        <Route path="form/:guid" element={<ReservationForm />} />
        <Route
          path="reservationInvoice/:guid"
          element={<ReservationInvoice />}
        />
      </Route>
      <Route path="reservationsHistory">
        <Route index element={<ReservationHistory />} />
        <Route path="form" element={<ReservationForm />} />
        <Route path="form/:guid" element={<ReservationForm />} />
        <Route
          path="reservationInvoice/:guid"
          element={<ReservationInvoice />}
        />
      </Route>
      <Route path="invoicesHistory">
        <Route index element={<InvoiceHistory />} />
      </Route>
      <Route path="products">
        <Route index element={<Products />} />
        <Route path="form" element={<ProductForm />} />
        <Route path="form/:guid" element={<ProductForm />} />
      </Route>
      <Route path="services">
        <Route index element={<Services />} />
        <Route path="form" element={<ServiceForm />} />
        <Route path="form/:guid" element={<ServiceForm />} />
      </Route>
      <Route path="rooms">
        <Route index element={<Rooms />} />
        <Route path="form" element={<RoomForm />} />
        <Route path="form/:guid" element={<RoomForm />} />
      </Route>
      <Route path="users">
        <Route index element={<Users />} />
        <Route path="form" element={<UserForm />} />
        <Route path="form/:guid" element={<UserForm />} />
      </Route>
      <Route path="companies">
        <Route index element={<Companies />} />
        <Route path="form/" element={<CompanyForm />} />
        <Route path="form/:guid" element={<CompanyForm />} />
      </Route>
      <Route path="quotes">
        <Route index element={<Quotes />} />
        <Route path="form/" element={<QuoteForm />} />
        <Route path="form/:guid" element={<QuoteForm />} />
      </Route>
      <Route path="cashRegisterClosingReport">
        <Route index element={<CashRegisterClosingReport />} />
      </Route>
      {isAdmin && (
        <Route path="salesReportByPaymentMethod">
          <Route index element={<SalesReportByPaymentMethod />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="salesReportByRooms">
          <Route index element={<SalesReportByRooms />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="salesReportByRoomTypes">
          <Route index element={<SalesReportByRoomTypes />} />
        </Route>
      )}
      {isAdmin && (
        <Route path="reportByRoomTypeQuantity">
          <Route index element={<ReportByRoomTypeQuantity />} />
        </Route>
      )}
      <Route path="invoiceConfiguration">
        <Route index element={<InvoiceConfiguration />} />
        <Route path="form" element={<InvoiceConfigurationForm />} />
        <Route path="form/:guid" element={<InvoiceConfigurationForm />} />
      </Route>
    </Routes>
  );
};
