import React, { useEffect, useState } from "react";
import { ROLE } from "@constants/role";
import { DATE_FORMAT } from "@constants/date";
import { formLayout } from "@constants/formLayout";
import { getSpan } from "@components/Common/Render";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Form, Spin, Card, Input, Space, Select, Button } from "antd";
import { fetchCitiesAsync, fetchCountriesAsync } from "@store/slices/location";

import {
  clearSelectedUser,
  createUserAsync,
  fetchUserAsync,
  updateUserAsync,
} from "@store/slices/user";

import {
  IFormProps,
  filterData,
  getFieldData,
  onFinishFailed,
} from "@components/Form";

import {
  validateRtn,
  validateDni,
  validateTwoWords,
  validatePhoneNumber,
  validateNoSpecialChars,
} from "@utils/validation";

import IUser from "@interfaces/IUser";
import IFieldData from "@interfaces/IFieldData";
import DatePicker from "@components/Common/DatePicker";
import PhonePrefix from "@components/Common/PhonePrefix";

const UserForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [form] = Form.useForm();
  const [fields, setFields] = useState<IFieldData[]>([]);
  const [showPassword, setShowPassword] = useState(false);

  const { isAdmin } = useAppSelector((state) => state.auth);
  const { selectedUser, loading } = useAppSelector((state) => state.user);
  const { cityOptions, countryOptions } = useAppSelector(
    (state) => state.location
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedUser());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedUser) {
      setFields(getFieldData(selectedUser));
    } else if (!selectedUser && guid) {
      dispatch(fetchUserAsync(guid));
    }
  }, [dispatch, guid, selectedUser]);

  useEffect(() => {
    if (countryOptions.length === 0) {
      dispatch(fetchCountriesAsync());
      dispatch(fetchCitiesAsync("HN"));
    }
  }, [dispatch, countryOptions.length]);

  const handleCountryChange = (_: any, value: any) => {
    form.setFieldsValue({ city: "" });
    dispatch(fetchCitiesAsync(value.key));
  };

  const shouldShowPasswordField = (_: any, option: any) =>
    setShowPassword(option.value !== ROLE.GUEST);

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (userData: IUser) => {
    selectedUser
      ? await dispatch(updateUserAsync(userData)).unwrap()
      : await dispatch(createUserAsync(userData)).unwrap();
    goBack();
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card
        bordered={false}
        style={{ width: 600 }}
        className={props.onBack ? "custom-form-card" : ""}
      >
        <Form
          form={form}
          {...formLayout}
          fields={fields}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />
          <Form.Item name="uid" hidden />
          <Form.Item name="role" hidden />
          <Form.Item
            name="name"
            label={getSpan("Nombre y Apellido")}
            rules={[
              { required: true },
              { validator: validateTwoWords },
              { validator: validateNoSpecialChars },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="phone"
            label={getSpan("Teléfono")}
            dependencies={["phonePrefix"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const phonePrefix = getFieldValue("phonePrefix");
                  return validatePhoneNumber(_, phonePrefix, value);
                },
              }),
            ]}
          >
            <Input addonBefore={<PhonePrefix />} maxLength={20} />
          </Form.Item>

          <Form.Item
            name="dni"
            label={getSpan("DNI")}
            rules={[
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const country = getFieldValue("country");
                  return validateDni(_, country, value);
                },
              }),
            ]}
          >
            <Input maxLength={20} />
          </Form.Item>

          <Form.Item
            name="rtn"
            label={getSpan("RTN")}
            rules={[
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const country = getFieldValue("country");
                  return validateRtn(_, country, value);
                },
              }),
            ]}
          >
            <Input maxLength={20} />
          </Form.Item>

          <Form.Item name="passport" label={getSpan("Pasaporte")}>
            <Input maxLength={20} />
          </Form.Item>

          <Form.Item
            name="role"
            label={getSpan("Role")}
            initialValue={ROLE.GUEST}
          >
            <Select onChange={shouldShowPasswordField}>
              <Select.Option value={ROLE.GUEST}>{ROLE.GUEST}</Select.Option>
              {isAdmin && (
                <>
                  <Select.Option value={ROLE.RECEPCIONIST}>
                    {ROLE.RECEPCIONIST}
                  </Select.Option>
                  <Select.Option value={ROLE.ADMIN}>{ROLE.ADMIN}</Select.Option>
                </>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label={getSpan("Correo")}
            rules={[
              {
                type: "email",
                message: "Correo no es valido.",
                required: showPassword,
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>

          {!selectedUser && showPassword && (
            <Form.Item
              name="password"
              label={getSpan("Contraseña")}
              rules={[{ required: true }]}
            >
              <Input maxLength={30} />
            </Form.Item>
          )}

          <Form.Item name="gender" label={getSpan("Género")}>
            <Select>
              <Select.Option value="Femenino">Femenino</Select.Option>
              <Select.Option value="Masculino">Masculino</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="birthday" label={getSpan("Fecha de Nacimiento")}>
            <DatePicker
              format={DATE_FORMAT}
              inputReadOnly
              style={{ width: "100%" }}
              placeholder="Elegir fecha"
            />
          </Form.Item>
          <Form.Item name="city" label={getSpan("Ciudad")}>
            <Select
              showSearch
              options={cityOptions}
              filterOption={filterData}
              notFoundContent="Ciudad no encontrado"
            ></Select>
          </Form.Item>

          <Form.Item
            name="country"
            initialValue="Honduras"
            label={getSpan("País")}
          >
            <Select
              showSearch
              options={countryOptions}
              filterOption={filterData}
              onChange={handleCountryChange}
              notFoundContent="País no encontrado"
            ></Select>
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {selectedUser ? "Editar Usuario" : "Crear Usuario"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default UserForm;
