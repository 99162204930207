import { getSpan } from "@components/Common/Render";
import { PRIMARY_COLOR } from "@constants/globalConstants";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  Divider,
  InputNumber,
} from "antd";

import IReservationRoomsPayment from "@interfaces/IReservationRoomsPayment";

const RoomsDetails: React.FC<any> = (props) => {
  const shouldDisable = (name: any) => {
    const room = props.form.getFieldValue("rooms")[name];
    const roomsPayments = props.form.getFieldValue("roomsPayments");
    if (!room) return false;
    return roomsPayments?.some(
      (x: IReservationRoomsPayment) =>
        x.roomId === room.roomId && x.invoiceIds && x.invoiceIds.length
    );
  };
  return (
    <Form.List name="rooms">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Form.Item key={key} noStyle>
              <Row>
                <Col span={8}>
                  <div>{getSpan("Nombre")}</div>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "key"]}
                    rules={[{ required: true }]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      showSearch
                      style={{ width: "90%" }}
                      options={props.roomOptions}
                      disabled={shouldDisable(name)}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div>{getSpan("Precio")}</div>
                  <Form.Item
                    noStyle
                    {...restField}
                    initialValue={0}
                    name={[name, "price"]}
                  >
                    <InputNumber
                      min={0}
                      controls={true}
                      addonBefore="L."
                      style={{ width: "90%" }}
                      disabled={shouldDisable(name)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div>{getSpan("Nombre de huesped")}</div>
                  <Form.Item noStyle {...restField} name={[name, "guest"]}>
                    <Input
                      maxLength={50}
                      style={{ width: "90%" }}
                      disabled={shouldDisable(name)}
                    />
                  </Form.Item>
                </Col>
                <Col span={1} style={{ marginTop: 30 }}>
                  {!shouldDisable(name) && (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  )}
                </Col>
              </Row>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              block
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
              style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
            >
              Agregar habitación
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default RoomsDetails;
