import IProduct from "@interfaces/IProduct";

import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getItemOptions, IItemOption } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getProductService,
  getProductsService,
  createProductService,
  updateProductService,
} from "@services/productService";

export interface ProductState {
  products: IProduct[];
  productOptions: IItemOption[];
  selectedProduct: IProduct | null;
  loading: boolean;
}

const initialState: ProductState = {
  products: [],
  productOptions: [],
  selectedProduct: null,
  loading: false,
};

export const fetchProductAsync = createAsyncThunk(
  "product/fetchProduct",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getProductService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchProductsAsync = createAsyncThunk(
  "product/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      return await getProductsService();
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createProductAsync = createAsyncThunk(
  "product/createProduct",
  async (newProduct: IProduct, { rejectWithValue }) => {
    try {
      return await createProductService(newProduct);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  "product/updateProduct",
  async (updatedProduct: IProduct, { rejectWithValue }) => {
    try {
      return await updateProductService(updatedProduct);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductAsync.fulfilled, (state, action) => {
        state.selectedProduct = action.payload;
        state.loading = false;
      })
      .addCase(fetchProductAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchProductsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload;
        state.productOptions = getItemOptions(action.payload);
        state.loading = false;
      })
      .addCase(fetchProductsAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProductAsync.fulfilled, (state, action) => {
        state.products = action.payload;
        state.productOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Producto creado!");
      })
      .addCase(createProductAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateProductAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductAsync.fulfilled, (state, action) => {
        state.products = action.payload;
        state.productOptions = getItemOptions(action.payload);
        state.loading = false;
        toastHandler.success("Producto actualizado!");
      })
      .addCase(updateProductAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearSelectedProduct } = productSlice.actions;
export default productSlice.reducer;
