import IQuote from "@interfaces/IQuote";
import IHotel from "@interfaces/IHotel";
import QuoteActionComponent from "./ActionComponent";

import { Key } from "react";
import { Card } from "antd";
import { getDateString, getDateDifference } from "@utils/date";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

export const QuoteColumns: any = (
  filteredString: Key,
  sortedInfo: any,
  hotel: IHotel,
  onDelete: Function
) => [
  {
    key: "date",
    width: "10%",
    align: "center",
    dataIndex: "date",
    title: "Fecha de Evento",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IQuote) => isFilterMatched(value, record),
    sorter: (a: IQuote, b: IQuote) => getDateDifference(a.date, b.date),
    sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
    render: (date: string) => getDateString(date),
  },
  {
    width: "20%",
    key: "userName",
    align: "center",
    title: "Cliente",
    dataIndex: "userName",
    sorter: (a: IQuote, b: IQuote) => sortAlphaNum(a.userName, b.userName),
    sortOrder: sortedInfo.columnKey === "userName" ? sortedInfo.order : null,
  },
  {
    width: "20%",
    align: "center",
    title: "Empresa",
    key: "companyName",
    dataIndex: "companyName",
    sorter: (a: IQuote, b: IQuote) =>
      sortAlphaNum(a.companyName, b.companyName),
    sortOrder: sortedInfo.columnKey === "companyName" ? sortedInfo.order : null,
  },
  {
    width: "10%",
    align: "center",
    key: "expirationDate",
    dataIndex: "expirationDate",
    title: "Fecha de Expiración",
    sorter: (a: IQuote, b: IQuote) =>
      getDateDifference(a.expirationDate, b.expirationDate),
    sortOrder:
      sortedInfo.columnKey === "expirationDate" ? sortedInfo.order : null,
    render: (date: string) => getDateString(date),
  },
  {
    key: "total",
    width: "10%",
    title: "Total",
    align: "center",
    dataIndex: "total",
    sorter: (a: IQuote, b: IQuote) => a.total - b.total,
    sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
    render: (total: number) => `L. ${getCurrencyFormat(total)}`,
  },
  {
    width: "20%",
    key: "createdBy",
    align: "center",
    title: "Reservado Por",
    dataIndex: "createdBy",
    sorter: (a: IQuote, b: IQuote) => sortAlphaNum(a.createdBy, b.createdBy),
    sortOrder: sortedInfo.columnKey === "createdBy" ? sortedInfo.order : null,
  },
  {
    key: "x",
    width: "10%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (quote: IQuote) => (
      <QuoteActionComponent
        hotel={hotel}
        quote={quote}
        onDelete={async () => await onDelete(quote.id)}
      />
    ),
  },
];

export const QuoteExpandableComponent = {
  expandedRowRender: (record: any) => (
    <>
      <Card className="mb" title="Detalles">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Subtotal</b>
          <br />
          <span>L. {getCurrencyFormat(record?.quoteDetail?.subtotal)}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Descuento Porcentual</b>
          <br />
          <span>{record?.quoteDetail?.discountPercentage}%</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Descuento</b>
          <br />
          <span>
            L. {getCurrencyFormat(record?.quoteDetail?.totalDiscount)}
          </span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Subtotal con Descuento</b>
          <br />
          <span>
            L. {getCurrencyFormat(record?.quoteDetail?.subtotalWithDiscount)}
          </span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>I.S.V.</b>
          <br />
          <span>L. {getCurrencyFormat(record?.quoteDetail?.isvTax)}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>I.S.R.T.</b>
          <br />
          <span>L. {getCurrencyFormat(record?.quoteDetail?.isrtTax)}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Total</b>
          <br />
          <span>L. {getCurrencyFormat(record?.quoteDetail?.total)}</span>
        </Card.Grid>
      </Card>
      <Card className="mb" title="Cliente">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Nombre de Cliente</b>
          <br />
          <span>{record?.userName}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Teléfono</b>
          <br />
          {record?.contactDetail?.user?.phone && (
            <>
              <span>({record?.contactDetail?.user?.phonePrefix}) </span>
              <span>{record?.contactDetail?.user?.phone}</span>
            </>
          )}
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>R.T.N.</b>
          <br />
          <span>{record?.contactDetail?.user?.rtn}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>D.N.I.</b>
          <br />
          <span>{record?.contactDetail?.user?.dni}</span>
        </Card.Grid>
      </Card>
      <Card className="mb" title="Empresa">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Nombre de Empresa</b>
          <br />
          <span>{record?.companyName}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Teléfono</b>
          <br />
          {record?.contactDetail?.company?.phone && (
            <>
              <span>({record?.contactDetail?.company?.phonePrefix}) </span>
              <span>{record?.contactDetail?.company?.phone}</span>
            </>
          )}
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>R.T.N.</b>
          <br />
          <span>{record?.contactDetail?.company?.rtn}</span>
        </Card.Grid>
      </Card>
      <Card className="mb" title="Notas">
        <Card.Grid hoverable={false} className="table-row-detail-single">
          <span>{record?.quoteDetail?.notes}</span>
        </Card.Grid>
      </Card>
    </>
  ),
};
