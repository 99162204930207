import React, { useState } from "react";
import { useAppSelector } from "@store/hooks";
import { useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Spin } from "antd";
import { getBreadcrumbItems } from "@components/Common/Render";
import { PublicContent, PrivateContent } from "@components/Content";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import MenuComponent from "@components/Menu";
import packageInfo from "../../../package.json";
import FloatButtons from "@components/FloatButtons";

const { Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState<any>(false);
  const { user, isAdmin, loading } = useAppSelector((state) => state.auth);

  return (
    <Spin className="spinner" spinning={loading}>
      <Layout>
        {user !== null && !loading && (
          <Layout>
            <Sider
              width={250}
              collapsible
              collapsedWidth={50}
              onCollapse={(collapsed) => setIsCollapsed(collapsed)}
              trigger={
                isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
              }
            >
              <MenuComponent user={user} />
            </Sider>
            <Layout>
              {location.pathname !== "/" &&
                location.pathname !== "/timeline" && (
                  <Breadcrumb
                    className="breadcrumb-class"
                    items={getBreadcrumbItems(location)}
                  ></Breadcrumb>
                )}
              <Content className="content-class">
                <PrivateContent isAdmin={isAdmin} />
              </Content>
            </Layout>

            <FloatButtons showReservation showInvoice/>
          </Layout>
        )}

        {user === null && !loading && <PublicContent />}
        {!loading && (
          <Footer style={{ textAlign: "center" }}>
            Micro Hotel Rio de Piedras ©2023 created by Wasabi <br />
            version {packageInfo.version}
          </Footer>
        )}
      </Layout>
    </Spin>
  );
};

export default App;
