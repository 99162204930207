import React, { useEffect, useState } from "react";
import { roomTypes } from "@constants/roomTypes";
import { formLayout } from "@constants/formLayout";
import { getSpan } from "@components/Common/Render";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { IFormProps, getFieldData, onFinishFailed } from "@components/Form";

import {
  fetchRoomAsync,
  createRoomAsync,
  updateRoomAsync,
  clearSelectedRoom,
} from "@store/slices/room";

import {
  Card,
  Form,
  Spin,
  Input,
  Space,
  Select,
  Button,
  InputNumber,
} from "antd";

import IRoom from "@interfaces/IRoom";
import IFieldData from "@interfaces/IFieldData";

const RoomForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [fields, setFields] = useState<IFieldData[]>([]);
  const { selectedRoom, loading } = useAppSelector((state) => state.room);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedRoom());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedRoom) {
      setFields(getFieldData(selectedRoom));
    } else if (!selectedRoom && guid) {
      dispatch(fetchRoomAsync(guid));
    }
  }, [dispatch, guid, selectedRoom]);

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (roomData: IRoom) => {
    if (selectedRoom) {
      await dispatch(updateRoomAsync(roomData));
    } else {
      await dispatch(createRoomAsync(roomData));
    }
    goBack();
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            label={getSpan("Nombre")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="type"
            label={getSpan("Tipo")}
            rules={[{ required: true }]}
            initialValue={roomTypes.SINGLE}
          >
            <Select>
              <Select.Option value={roomTypes.SINGLE}>
                {roomTypes.SINGLE}
              </Select.Option>
              <Select.Option value={roomTypes.DOUBLE}>
                {roomTypes.DOUBLE}
              </Select.Option>
              <Select.Option value={roomTypes.TRIPLE}>
                {roomTypes.TRIPLE}
              </Select.Option>
              <Select.Option value={roomTypes.JRSUITE}>
                {roomTypes.JRSUITE}
              </Select.Option>
              <Select.Option value={roomTypes.SUITE}>
                {roomTypes.SUITE}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="price"
            initialValue={0}
            label={getSpan("Precio")}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={0}
              controls={true}
              addonBefore="L."
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {selectedRoom ? "Editar Habitación" : "Crear Habitación"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default RoomForm;
