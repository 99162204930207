// @ts-nocheck
import React from "react";
import DatePicker from "@components/Common/DatePicker";

import { Col, Row, Button } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProjectOutlined, FilePdfOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export interface IToolbarProps {
  chart: any;
  pdfComponent: any;
  onGenerateClick: any;
  onFilteredDateChange: any;
}

const SalesReportToolbar: React.FC<IToolbarProps> = (props) => {
  const onFilterChange = (dates: any) => {
    props.onFilteredDateChange(dates);
  };

  const onGenerateClick = () => {
    props.onGenerateClick();
  };

  return (
    <Row style={{ marginBottom: 40 }}>
      <Col style={{ display: "flex", marginLeft: 20 }}>
        <RangePicker
          size="large"
          format={"DD/MM/YY"}
          onChange={onFilterChange}
        />
      </Col>
      <Col style={{ marginLeft: 50, marginRight: 50 }}>
        <Button
          size="large"
          type="primary"
          icon={<ProjectOutlined />}
          onClick={() => onGenerateClick()}
        >
          Generar
        </Button>
      </Col>
      <Col
        flex={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <PDFDownloadLink
          fileName="sales_report.pdf"
          document={props.pdfComponent}
        >
          {({ loading }) => (
            <Button
              size="large"
              type="primary"
              loading={loading}
              disabled={!props.chart}
              icon={<FilePdfOutlined />}
            >
              Descargar PDF
            </Button>
          )}
        </PDFDownloadLink>
      </Col>
    </Row>
  );
};

export default SalesReportToolbar;
