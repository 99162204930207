import React, { useState } from "react";
import { Modal, Button, Tooltip } from "antd";
import { fetchUserAsync } from "@store/slices/user";
import { getReservationStatusColor } from "./helpers";
import { ClockCircleTwoTone } from "@ant-design/icons";
import { getHeaderTitle } from "@components/Common/Render";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { reservationStatus } from "@constants/reservationStatus";
import { updateReservationStatusAsync } from "@store/slices/reservation";

import UserForm from "@pages/Users/UserForm";
import IReservation from "@interfaces/IReservation";
import CompanyForm from "@pages/Companies/CompanyForm";
import { fetchCompanyAsync } from "@store/slices/company";

export interface ICheckinProps {
  reservation: IReservation;
}

const CheckinComponent: React.FC<ICheckinProps> = (props) => {
  const dispatch = useAppDispatch();

  const { isAdmin } = useAppSelector((state) => state.auth);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const onReservationStatusModalOpen = async (status: string | null = null) => {
    setSelectedStatus(status);
    if (props.reservation.status === reservationStatus.RESERVED) {
      await dispatch(fetchUserAsync(props.reservation?.user?.id));
      setUserModalOpen(true);
    }
    if (
      props.reservation?.company?.id &&
      props.reservation.status === reservationStatus.RESERVED
    ) {
      await dispatch(fetchCompanyAsync(props.reservation?.company?.id));
      setCompanyModalOpen(true);
    }
  };

  const onUpdateReservationStatus = async (
    reservation: IReservation,
    status: string | null
  ) => {
    if (!status || !reservation) return;
    await dispatch(
      updateReservationStatusAsync({ id: reservation.id, status })
    );
    setSelectedStatus(null);
  };

  const ReservationStatusButtonComponent = ({ reservation }: any) => {
    let status: string | null = null;
    if (!reservation.checkinAt) status = reservationStatus.CHECKIN;
    if (reservation.checkinAt && !reservation.checkoutAt)
      status = reservationStatus.CHECKOUT;
    if (reservation.checkinAt && reservation.checkoutAt)
      status = reservationStatus.COMPLETED;
    if (!status) return null;
    return (
      <Tooltip title={status}>
        <Button
          ghost
          className="ghost-button"
          disabled={status === reservationStatus.COMPLETED && !isAdmin}
          icon={
            <ClockCircleTwoTone
              twoToneColor={getReservationStatusColor(status)}
              onClick={() => onReservationStatusModalOpen(status)}
            />
          }
        />
      </Tooltip>
    );
  };

  return (
    <>
      <ReservationStatusButtonComponent reservation={props.reservation} />
      <Modal
        centered
        width={600}
        open={!!selectedStatus}
        title={getHeaderTitle(selectedStatus)}
        onCancel={() => setSelectedStatus(null)}
        onOk={() =>
          onUpdateReservationStatus(props.reservation, selectedStatus)
        }
      ></Modal>
      {userModalOpen && (
        <Modal
          centered
          width={700}
          footer={[]}
          open={userModalOpen}
          onCancel={() => setUserModalOpen(false)}
        >
          <UserForm onBack={() => setUserModalOpen(false)} />
        </Modal>
      )}
      {companyModalOpen && (
        <Modal
          centered
          width={700}
          footer={[]}
          open={companyModalOpen}
          onCancel={() => setCompanyModalOpen(false)}
        >
          <CompanyForm onBack={() => setCompanyModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default CheckinComponent;
