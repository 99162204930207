import { Col, Form, Row, Select } from "antd";
import { codes } from "@constants/countryCodes";

interface IPhonePrefixProps {
  name?: string;
}

export const renderItem = (value: any) => ({
  key: value.key,
  label: value.name,
  value: value.id,
});

export const optionsRender = (option: any) => (
  <Row>
    <Col span={4}>
      <b>({option.value})</b>
    </Col>
    <Col span={4}>{option.label}</Col>
  </Row>
);

const filterData = (inputValue: any, option: any) => {
  const labelMatch = option?.label
    ?.toUpperCase()
    ?.includes(inputValue?.toUpperCase());
  const areaMatch = option?.value?.includes(inputValue.toUpperCase());
  return labelMatch || areaMatch;
};

const areaCodes: any = codes.map((code: any) => renderItem(code));

const PhonePrefix: React.FC<IPhonePrefixProps> = ({
  name = "phonePrefix",
}: any) => (
  <Form.Item noStyle name={name} initialValue="+504">
    <Select
      showSearch
      options={areaCodes}
      style={{ width: 90 }}
      optionLabelProp="value"
      filterOption={filterData}
      optionRender={optionsRender}
      popupMatchSelectWidth={400}
      notFoundContent="Código de area no encontrado"
    ></Select>
  </Form.Item>
);
export default PhonePrefix;
