import React, { Key, useEffect, useState } from "react";
import { getToday } from "@utils/date";
import { connectAndPrint } from "@utils/printer";
import { Row, Col, Card, Table, Layout } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { deleteInvoiceAsync, fetchInvoicesAsync } from "@store/slices/invoice";

import {
  InvoiceColumns,
  InvoiceExpandableComponent,
} from "./helpers/Components";

import IInvoice from "@interfaces/IInvoice";
import DateToolbar from "@components/Table/DateToolbar";
import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const InvoiceHistory: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  const [filteredDate, setFilteredDate] = useState<any>(getToday());

  const { hotel } = useAppSelector((state) => state.hotel);
  const { invoices, loading } = useAppSelector((state) => state.invoice);

  useEffect(() => {
    if (invoices.length === 0) dispatch(fetchInvoicesAsync(filteredDate));
  }, [dispatch, filteredDate, invoices.length]);

  useEffect(() => {
    dispatch(fetchInvoicesAsync(filteredDate));
  }, [dispatch, filteredDate]);

  const onPrint = async (invoice: IInvoice) =>
    await connectAndPrint([invoice], hotel);

  const onDelete = async (id: string) => await dispatch(deleteInvoiceAsync(id));

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <Row>
            <Col style={{ display: "flex" }} flex={0.5}>
              <DateToolbar
                filteredDate={filteredDate}
                setFilteredDate={setFilteredDate}
              />
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} flex={12}>
              <TableToolbar
                onFilterChange={setFilteredString}
                canCreate={false}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            dataSource={invoices}
            pagination={{ pageSize: 20 }}
            expandable={InvoiceExpandableComponent()}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={InvoiceColumns(
              filteredString,
              sortedInfo,
              onDelete,
              onPrint
            )}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default InvoiceHistory;
