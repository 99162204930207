import { Card, Spin } from "antd";
import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fetchRoomsAsync } from "@store/slices/room";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { generateSalesReportByRooms } from "@services/reportService";

import {
  parseCurrency,
  ReportByRooms,
  getDetailsComponent,
  getSalesChartByRoomsData,
} from "./Common";

import {
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";

import SalesReportToolbar from "./SalesReportToolbar";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const SalesReportByRooms = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any>(null);
  const [reportData, setReportData] = useState([]);
  const [filteredDate, setFilteredDate] = useState<any>([]);

  const chartRef = useRef<any>(null);
  const [chart, setChart] = useState();

  const { isAdmin } = useAppSelector((state) => state.auth);
  const { rooms, loading } = useAppSelector((state) => state.room);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
  }, [navigate, isAdmin]);

  useEffect(() => {
    if (rooms.length === 0) dispatch(fetchRoomsAsync());
  }, [dispatch, rooms.length]);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      generateChart(reportData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const saveChart = () => {
    setChart(chartRef.current);
  };

  const options: any = {
    responsive: true,
    animation: {
      onComplete: ({ initial }: any) => {
        if (initial) saveChart();
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" },
        formatter: parseCurrency,
      },
    },
  };

  const handleFilterDateChange = (value: any) => {
    setFilteredDate(value);
  };

  const handleOnGenerate = async () => {
    if (filteredDate.length < 2) return;

    const payload = {
      initialDate: filteredDate[0].toISOString(),
      finalDate: filteredDate[1].toISOString(),
    };
    const result = await generateSalesReportByRooms(payload);
    setReportData(result);
  };

  const generateChart = async (data: any) => {
    const chartData = getSalesChartByRoomsData(rooms, data);
    setData(chartData);
  };

  const pdfComponent = () => (
    <ReportByRooms
      chartRef={chart}
      reportData={reportData}
      filteredDate={filteredDate}
    />
  );

  return (
    <Spin className="spinner" spinning={loading}>
      <Card>
        <SalesReportToolbar
          chart={chart}
          pdfComponent={pdfComponent()}
          onGenerateClick={handleOnGenerate}
          onFilteredDateChange={handleFilterDateChange}
        />

        {data && <>{getDetailsComponent(filteredDate, reportData)}</>}

        {data && (
          <Bar
            ref={chartRef}
            data={data}
            plugins={[ChartDataLabels]}
            options={options}
          />
        )}
      </Card>
    </Spin>
  );
};

export default SalesReportByRooms;
