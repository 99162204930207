import React, { useEffect } from "react";
import locale from "antd/locale/es_ES";
import Dashboard from "@pages/Dashboard";

import { ConfigProvider } from "antd";
import { useAppDispatch } from "@store/hooks";
import { BrowserRouter } from "react-router-dom";
import { PRIMARY_COLOR } from "@constants/globalConstants";
import { doInitializeAuthAsync } from "@store/slices/auth";

import "./App.css";
import "./styles/Timeline.css";

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(doInitializeAuthAsync());
  }, [dispatch]);
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
        },
      }}
    >
      <BrowserRouter>
        <Dashboard />
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
