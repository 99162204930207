import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import roomReducer from "./slices/room";
import hotelReducer from "./slices/hotel";
import invoiceReducer from "./slices/invoice";
import productReducer from "./slices/product";
import serviceReducer from "./slices/service";
import companieReducer from "./slices/company";
import locationReducer from "./slices/location";
import timelineReducer from "./slices/timeline";
import reservationReducer from "./slices/reservation";

const appReducer = combineReducers({
  auth: authReducer,
  room: roomReducer,
  user: userReducer,
  hotel: hotelReducer,
  invoice: invoiceReducer,
  product: productReducer,
  service: serviceReducer,
  company: companieReducer,
  location: locationReducer,
  timeline: timelineReducer,
  reservation: reservationReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
