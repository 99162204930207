import IHotel from "@interfaces/IHotel";
import IInvoice from "@interfaces/IInvoice";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

import { toastHandler } from "./toastHandler";
import { NumerosALetras } from "numero-a-letras";
import { convertName, getCurrencyFormat } from "./util";
import { getInvoiceService } from "@services/invoiceService";
import { getDateString, getTimeString, getTodayTimeString } from "./date";
import { getInvoiceConfigurationService } from "@services/invoiceConfigurationService";

export const connectAndPrint = async (
  invoices: IInvoice[],
  hotel: IHotel | null
) => {
  try {
    if (!hotel) return;
    if (invoices && invoices.length === 0) return;
    const device = await navigator.usb.requestDevice({
      filters: [{ vendorId: 0x04b8 }],
    });
    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);

    const invoiceConfiguration = await getInvoiceConfigurationService(
      invoices[0].invoiceConfigurationId
    );

    await Promise.all(
      invoices?.map(async (invoice) => {
        const data = await getInvoiceData(
          hotel,
          invoice.id,
          invoiceConfiguration
        );

        await device.transferOut(1, data);
      })
    );
    console.log("Print command sent");
  } catch (error) {
    console.error("Error:", error);
    toastHandler.error("Ocurrió un error al intentar imprimir");
  }
};

const getInvoiceData = async (
  hotel: IHotel,
  invoiceId: string,
  invoiceConfiguration: IInvoiceConfiguration
) => {
  const esc = "\x1b"; // ESC character
  const leftAlign = "\x1b\x61\x00";
  const centerAlign = `${esc}a\x01`; // ESC a 1 -> Center Align
  const rightAlign = "\x1b\x61\x02";
  const boldOn = `${esc}E\x01`; // ESC E 1 -> Bold On
  const boldOff = `${esc}E\x00`; // ESC E 0 -> Bold Off

  const invoice = await getInvoiceService(invoiceId);

  const headerText =
    `${centerAlign}${boldOn}${hotel.name || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${hotel.legalName || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${hotel.rtn || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${hotel.address || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${hotel.phone || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${hotel.email || ""}\n\n${boldOff}`;

  const invoiceText =
    `${centerAlign}${boldOn}FACTURA DE VENTA\n${boldOff}` +
    `${centerAlign}${boldOn}${invoice.invoiceName}\n${boldOff}` +
    `${centerAlign}${boldOn}${invoice.invoiceAuthorizedNumber}\n${boldOff}` +
    `${centerAlign}${boldOn}CAI: ${invoice.invoiceCai}\n\n${boldOff}`;

  const companyText = invoice.invoiceCompanyName
    ? `${leftAlign}${boldOn}Empresa: ${invoice.invoiceCompanyName}\n${boldOff}` +
      `${leftAlign}${boldOn}R.T.N.: ${invoice.invoiceCompanyRtn}\n\n${boldOff}`
    : "";

  const clientName = `${leftAlign}${boldOn}Cliente: ${invoice.invoiceClientName}\n${boldOff}`;
  const clientDni = invoice.invoiceClientDni
    ? `${leftAlign}${boldOn}D.N.I.: ${invoice.invoiceClientDni}\n${boldOff}`
    : "";
  const clientRtn = invoice.invoiceClientRtn
    ? `${leftAlign}${boldOn}R.T.N.: ${invoice.invoiceClientRtn}\n\n${boldOff}`
    : "";

  const clientText =
    invoice.invoiceClientName !== "Consumidor Final"
      ? clientName + clientDni + clientRtn
      : "";

  const vendorText =
    `${leftAlign}${boldOn}Cajero: ${convertName(
      invoice.createdBy
    )}\n${boldOff}` +
    `${leftAlign}${boldOn}Fecha: ${getDateString(
      invoice.createdAt
    )}   Hora: ${getTimeString(invoice.createdAt)}\n\n${boldOff}`;

  let tableContent = `${leftAlign}${boldOn}Cant.           Descripcion         Valor\n${boldOff}`;
  tableContent += `${boldOn}------------------------------------------\n${boldOff}`;

  const uniqueRooms = getGroupedRooms(invoice.roomsPayments);
  Object.values(uniqueRooms)?.forEach((item: any) => {
    const qty = `${item.quantity.toString()} X L.${getCurrencyFormat(item.price)
      .toString()
      .padEnd(10)}`;
    const name = `${item?.name} - ${item?.type?.toUpperCase()?.padEnd(12)}`;
    const value = `L.${getCurrencyFormat(item.subtotal).toString()}`;

    tableContent += `${boldOn}${leftAlign}${qty}${name}\n${boldOff}`;
    tableContent += `${boldOn}${rightAlign}${value}\n${boldOff}`;
  });

  invoice.products?.forEach((item: any) => {
    const qty = `${item.quantity} X L.${getCurrencyFormat(item.price)
      .toString()
      .padEnd(10)}`;
    const name = item.name?.toUpperCase().padEnd(12);
    const subtotal = item.price * item.quantity;
    const value = `L.${getCurrencyFormat(subtotal)?.toString()}`;

    tableContent += `${boldOn}${leftAlign}${qty}${name}\n${boldOff}`;
    tableContent += `${boldOn}${rightAlign}${value}\n${boldOff}`;
  });

  invoice.services?.forEach((item: any) => {
    const qty = `${item.quantity} X L.${getCurrencyFormat(item.price)
      .toString()
      .padEnd(10)}`;
    const name = item.name?.toUpperCase().padEnd(12);
    const subtotal = item.price * item.quantity;
    const value = `L.${getCurrencyFormat(subtotal)?.toString()}`;

    tableContent += `${boldOn}${leftAlign}${qty}${name}\n${boldOff}`;
    tableContent += `${boldOn}${rightAlign}${value}\n${boldOff}`;
  });

  tableContent += `${boldOn}${rightAlign}-----------------------\n${boldOff}`;

  const paymentDetailText =
    `${rightAlign}${boldOn}Subtotal: L. ${getCurrencyFormat(
      invoice.subtotal
    )}\n${boldOff}` +
    `${rightAlign}${boldOn}Descuento: L. ${getCurrencyFormat(
      invoice.discount
    )}\n${boldOff}` +
    `${rightAlign}${boldOn}Subtotal con Descuento: L. ${getCurrencyFormat(
      invoice.subtotalWithDiscount
    )}\n${boldOff}` +
    `${rightAlign}${boldOn}I.S.V. 15%: L. ${getCurrencyFormat(
      invoice.isvTax
    )}\n${boldOff}` +
    `${rightAlign}${boldOn}I.S.R.T. 4%: L. ${getCurrencyFormat(
      invoice.isrtTax
    )}\n${boldOff}` +
    `${rightAlign}${boldOn}Total: L. ${getCurrencyFormat(
      invoice.total
    )}\n\n${boldOff}`;

  const additionalNotes = invoice.invoiceNotes
    ? `${leftAlign}${boldOn}Notas Adicionales:\n${boldOff}` +
      `${centerAlign}${boldOn}${invoice.invoiceNotes} \n\n${boldOff}`
    : "";

  const disclaimerText =
    `${centerAlign}${boldOn}NO SE ACEPTAN DEVOLUCIONES \n${boldOff}` +
    `${centerAlign}${boldOn}LA FACTURA ES BENEFICIO DE TODOS \n${boldOff}` +
    `${centerAlign}${boldOn}EXIJALA \n\n${boldOff}`;

  const currencyWords = NumerosALetras(invoice.total);
  const updatedCurrencyWords = currencyWords
    ?.replace("Pesos", "Lempiras")
    ?.replace("M.N.", "")
    ?.toUpperCase();

  const totalNumberText =
    `${centerAlign}${boldOn}Cantidad en Letras: \n${boldOff}` +
    `${centerAlign}${boldOn}${updatedCurrencyWords} \n${boldOff}` +
    `${centerAlign}${boldOn}Folio: 3665 \n${boldOff}` +
    `${centerAlign}${boldOn}Autorizados: ${invoiceConfiguration.initialAuthorizedRange} - ${invoiceConfiguration.finalAuthorizedRange}\n${boldOff}` +
    `${centerAlign}${boldOn}Fecha Limite Emision: ${getDateString(
      invoiceConfiguration.deadlineForIssuance
    )}\n${boldOff}` +
    `${centerAlign}${boldOn}Original: Cliente * Copia: Emisor \n\n\n\n\n\n\n\n${boldOff}`;

  const spacingText = "\n\n\n";

  const textEncoder = new TextEncoder();
  const data = textEncoder.encode(
    spacingText +
      headerText +
      invoiceText +
      companyText +
      clientText +
      vendorText +
      tableContent +
      paymentDetailText +
      additionalNotes +
      disclaimerText +
      totalNumberText +
      spacingText
  );

  return data;
};

const getGroupedRooms = (data: any) => {
  return data?.reduce((acc: any, curr: any) => {
    const key = `${curr.reservationId}-${curr.roomId}`;

    if (!acc[key]) {
      acc[key] = {
        reservationId: curr.reservationId,
        roomId: curr.roomId,
        name: curr.name,
        price: curr.price,
        type: curr.type,
        quantity: 0,
        subtotal: 0,
      };
    }

    acc[key].quantity += 1;
    acc[key].subtotal += Number(curr.price);

    return acc;
  }, {});
};

export const connectAndPrintCashRegisterClosingReport = async (data: any) => {
  try {
    const device = await navigator.usb.requestDevice({
      filters: [{ vendorId: 0x04b8 }],
    });
    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);

    const printData = await getCashRegisterClosingReportData(data);
    await device.transferOut(1, printData);

    console.log("Print command sent");
  } catch (error) {
    console.error("Error:", error);
    toastHandler.error("Ocurrió un error al intentar imprimir");
  }
};

const getCashRegisterClosingReportData = async (data: any) => {
  const esc = "\x1b"; // ESC character
  const leftAlign = "\x1b\x61\x00";
  const centerAlign = `${esc}a\x01`; // ESC a 1 -> Center Align
  const boldOn = `${esc}E\x01`; // ESC E 1 -> Bold On
  const boldOff = `${esc}E\x00`; // ESC E 0 -> Bold Off

  const headerText =
    `${centerAlign}${boldOn}Reporte de Cierre de Caja\n${boldOff}` +
    `${centerAlign}${boldOn}Usuario: ${data.userName || ""}\n${boldOff}` +
    `${centerAlign}${boldOn}${getTodayTimeString()}\n\n${boldOff}`;

  const infoText =
    `${centerAlign}${boldOn}Caja 1\n${boldOff}` +
    `${centerAlign}${boldOn}Fecha Inicio: ${data.initialDate}\n${boldOff}` +
    `${centerAlign}${boldOn}Fecha Final: ${data.finalDate}\n\n${boldOff}`;

  const taxesText =
    `${leftAlign}${boldOn}Impuestos Recolectados\n${boldOff}` +
    `${leftAlign}${boldOn}I.S.V. 15% L. ${getCurrencyFormat(
      data.isvTax
    ).toString()}\n${boldOff}` +
    `${leftAlign}${boldOn}I.S.R.T. 4%: L. ${getCurrencyFormat(
      data.isrtTax
    ).toString()}\n\n${boldOff}`;

  const salesText =
    `${leftAlign}${boldOn}Subtotal: L. ${getCurrencyFormat(
      data.subtotal
    ).toString()}\n${boldOff}` +
    `${leftAlign}${boldOn}Impuestos: L. ${getCurrencyFormat(
      data.taxes
    ).toString()}\n${boldOff}` +
    `${leftAlign}${boldOn}Descuentos: L. ${getCurrencyFormat(
      data.discount
    ).toString()}\n${boldOff}` +
    `${leftAlign}${boldOn}Total: L. ${getCurrencyFormat(
      data.total
    ).toString()}\n\n\n\n\n\n\n\n${boldOff}`;

  const spacingText = "\n\n\n";

  const textEncoder = new TextEncoder();
  const printData = textEncoder.encode(
    spacingText + headerText + infoText + taxesText + salesText + spacingText
  );

  return printData;
};
