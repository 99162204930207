import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { store } from "@store/store";
import { Provider } from "react-redux";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { HOTEL_NAME, HOTEL_SLUG } from "@constants/globalConstants";

import "./index.css";
import App from "./App";

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();

const setCustomFavicon = () => {
  const customFaviconUrl = `${process.env.PUBLIC_URL}/${HOTEL_SLUG}/logo.ico`;
  const favicon: any = document.querySelector("link[rel='icon']");
  if (favicon) {
    favicon.href = customFaviconUrl;
  }

  document.title = HOTEL_NAME || "Default Title";
};

setCustomFavicon();
