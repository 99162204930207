import { Button, Card, Tooltip } from "antd";
import { Key, useRef, useState } from "react";
import { getInvoiceConfigurationsAboutToExpire } from ".";
import { getInvoiceService } from "@services/invoiceService";
import { getDateString, getDateDifference } from "@utils/date";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

import {
  StopOutlined,
  WarningOutlined,
  PrinterOutlined,
  CheckCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";

import IInvoice from "@interfaces/IInvoice";

export const getInvoicesExpireMessage = (invoiceConfigurationOptions: any) => {
  const invoiceConfigurationsAboutToExpire =
    getInvoiceConfigurationsAboutToExpire(invoiceConfigurationOptions);
  return invoiceConfigurationsAboutToExpire?.map((x: any) => (
    <p style={{ color: "red" }}>
      <WarningOutlined /> {x.label} esta cerca de expirar.
    </p>
  ));
};

export const InvoiceColumns: any = (
  filteredString: Key,
  sortedInfo: any,
  onDelete: Function,
  onPrint: Function
) => [
  {
    width: "10%",
    title: "Fecha",
    align: "center",
    key: "createdAt",
    dataIndex: "createdAt",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IInvoice) =>
      isFilterMatched(value, record),
    sorter: (a: IInvoice, b: IInvoice) =>
      getDateDifference(a.createdAt, b.createdAt),
    sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
    render: (createdAt: string) => getDateString(createdAt),
  },
  {
    width: "20%",
    align: "center",
    title: "Cliente",
    key: "invoiceClientName",
    dataIndex: "invoiceClientName",
    sorter: (a: IInvoice, b: IInvoice) =>
      sortAlphaNum(a.invoiceClientName, b.invoiceClientName),
    sortOrder:
      sortedInfo.columnKey === "invoiceClientName" ? sortedInfo.order : null,
  },
  {
    width: "20%",
    align: "center",
    key: "paymentMethod",
    title: "Método de pago",
    dataIndex: "paymentMethod",
    sorter: (a: IInvoice, b: IInvoice) =>
      sortAlphaNum(a.paymentMethod, b.paymentMethod),
    sortOrder:
      sortedInfo.columnKey === "paymentMethod" ? sortedInfo.order : null,
  },
  {
    width: "10%",
    title: "Tipo",
    align: "center",
    key: "invoiceName",
    dataIndex: "invoiceName",
    sorter: (a: IInvoice, b: IInvoice) =>
      sortAlphaNum(a.invoiceName, b.invoiceName),
    sortOrder: sortedInfo.columnKey === "invoiceName" ? sortedInfo.order : null,
  },
  {
    width: "20%",
    title: "Cajero",
    align: "center",
    key: "createdBy",
    dataIndex: "createdBy",
    sorter: (a: IInvoice, b: IInvoice) =>
      sortAlphaNum(a.createdBy, b.createdBy),
    sortOrder: sortedInfo.columnKey === "createdBy" ? sortedInfo.order : null,
  },
  {
    key: "total",
    width: "10%",
    title: "Total",
    align: "center",
    dataIndex: "total",
    sorter: (a: IInvoice, b: IInvoice) => a.total - b.total,
    sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
    render: (total: number) => `L. ${getCurrencyFormat(total)}`,
  },
  {
    key: "x",
    width: "10%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (invoice: IInvoice) => (
      <>
        <Tooltip title="Imprimir">
          <Button
            ghost
            className="ghost-button"
            icon={
              <PrinterOutlined onClick={async () => await onPrint(invoice)} />
            }
          />
        </Tooltip>
        {invoice.invoiceName !== "Caja 1" && (
          <Tooltip title="Anular">
            <Button
              ghost
              className="ghost-button"
              icon={
                <StopOutlined
                  onClick={async () => await onDelete(invoice.id)}
                />
              }
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const InvoiceExpandableComponent = () => {
  const expandedData = useRef<Record<string, any>>({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const handleExpand = async (expanded: boolean, record: any) => {
    if (expanded) {
      if (!expandedData.current[record.id]) {
        const invoice = await getInvoiceService(record.id);
        expandedData.current[record.id] = [
          ...invoice.products,
          ...invoice.services,
          ...invoice.roomsPayments,
        ];
      }
      setExpandedRowKeys((prevKeys: any) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys: any) =>
        prevKeys.filter((key: Key) => key !== record.id)
      );
    }
  };
  const expandedRowRender = (record: any) => {
    const data = expandedData.current[record.id];
    return (
      data && (
        <>
          <Card className="mb" title="Detalles">
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Factura Autorizada </b>
              <br />
              <span>{record.invoiceAuthorizedNumber}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>C.A.I. </b>
              <br />
              <span>{record.invoiceCai}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Notas </b>
              <br />
              <span>{record.invoiceNotes}</span>
            </Card.Grid>
            <Card.Grid
              hoverable={false}
              className="table-row-detail"
            ></Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>D.N.I. Cliente </b>
              <br />
              <span>{record.invoiceClientDni}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Cliente </b>
              <br />
              <span>{record.invoiceClientRtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Nombre Empresa </b>
              <br />
              <span>{record.invoiceCompanyName}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>R.T.N. Empresa </b>
              <br />
              <span>{record.invoiceCompanyRtn}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Subtotal </b>
              <br />
              <span>L. {getCurrencyFormat(record.subtotal)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>Descuento </b>
              <br />
              <span>L. {getCurrencyFormat(record.discount)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>I.S.V. </b>
              <br />
              <span>L. {getCurrencyFormat(record.isvTax)}</span>
            </Card.Grid>
            <Card.Grid hoverable={false} className="table-row-detail">
              <b>I.S.R.T. </b>
              <br />
              <span>L. {getCurrencyFormat(record.isrtTax)}</span>
            </Card.Grid>
          </Card>
          {data.length > 0 && (
            <Card className="mb" title="Pagos">
              {data?.map((payment: any) => (
                <Card.Grid
                  key={payment.id}
                  hoverable={false}
                  className="table-row-detail"
                >
                  <b>{payment.name}</b>
                  <br />
                  <b>Precio:</b>{" "}
                  <span>L.{getCurrencyFormat(payment.price)}</span>
                  <br />
                  {payment.date ? (
                    <>
                      <span>{getDateString(payment.date)}</span>
                      <br />
                    </>
                  ) : (
                    <>
                      <b>Cantidad:</b> <span>{payment.quantity}</span>
                      <br />
                    </>
                  )}
                  <br />
                  {payment.invoiceIds ? (
                    <CheckCircleFilled
                      style={{ color: "green", fontSize: "32px" }}
                    />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: "red", fontSize: "32px" }}
                    />
                  )}
                </Card.Grid>
              ))}
            </Card>
          )}
          <Card className="mb" title="Historial de Cambios">
            <Card.Grid hoverable={false} className="table-row-detail-single">
              <b>Creado Por </b>
              <br />
              <span>{record.createdBy}</span>
            </Card.Grid>
          </Card>
        </>
      )
    );
  };
  return {
    expandedRowKeys,
    onExpand: handleExpand,
    expandedRowRender,
  };
};
