import axios from "axios";
import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/report";

export const generateCashRegisterClosingReport = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/cash-register-closing-report`,
      { ...data },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching cash register closing report:", error);
    throw error;
  }
};

export const generateSalesReportByPaymentMethod = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/sales/payment-method`,
      { ...data },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales report by payment method:", error);
    throw error;
  }
};

export const generateSalesReportByRooms = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/sales/rooms`,
      { ...data },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales report by rooms:", error);
    throw error;
  }
};

export const generateSalesReportByRoomTypes = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/sales/rooms/types`,
      { ...data },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales report by room types:", error);
    throw error;
  }
};

export const generateReportByRoomTypeQuantity = async (data: any) => {
  try {
    const headers = await getConfigHeaders();
    const response = await axios.post(
      `${API_BASE_URL}/sales/rooms/quantity`,
      { ...data },
      headers
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales report by room quantity:", error);
    throw error;
  }
};
