import React from "react";
import { Button, Col, Row } from "antd";
import { IDateToolbarProps } from "./helpers";
import { MONTH_YEAR_FORMAT } from "@constants/date";
import { getDate, getDateString, getToday } from "@utils/date";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import DatePicker from "@components/Common/DatePicker";

const DateToolbar: React.FC<IDateToolbarProps> = (props) => {
  const onFilterChange = (e: any) => {
    const date = getDateString(e);
    props.setFilteredDate(date);
  };

  const substractDay = () => {
    const date = getDate(props.filteredDate)?.subtract(1, "month");
    props.setFilteredDate(getDateString(date));
  };

  const addDay = () => {
    const date = getDate(props.filteredDate)?.add(1, "month");
    props.setFilteredDate(getDateString(date));
  };

  return (
    <Row>
      <Col style={{ display: "flex", alignItems: 'center' }} flex={1}>
        <Button
          size="middle"
          type="primary"
          icon={<LeftOutlined />}
          onClick={() => substractDay()}
        />
        <DatePicker
          size="large"
          picker={"month"}
          onChange={onFilterChange}
          format={MONTH_YEAR_FORMAT}
          value={getDate(props.filteredDate) || getToday()}
          style={{ width: 200, marginLeft: 10, marginRight: 10 }}
        />
        <Button
          size="middle"
          type="primary"
          icon={<RightOutlined />}
          onClick={() => addDay()}
        />
      </Col>
    </Row>
  );
};

export default DateToolbar;
