import React, { useState } from "react";
import { IActionProps } from "./helpers";
import { Button, Modal, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { getModalHeader } from "@components/Common/Render";
import { EditFilled, DeleteOutlined } from "@ant-design/icons";

const ActionComponent: React.FC<IActionProps> = (props) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onDelete = () => {
    props.onDelete && props.onDelete(props.dataObject);
    setConfirmOpen(false);
  };

  return (
    <div>
      <Tooltip title="Editar">
        <Button
          ghost
          className="ghost-button"
          icon={
            <EditFilled
              onClick={() => navigate(`form/${props.dataObject.id}`)}
            />
          }
        />
      </Tooltip>

      {props.onDelete && (
        <Tooltip title="Borrar">
          <Button
            ghost
            className="ghost-button"
            icon={<DeleteOutlined onClick={() => setConfirmOpen(true)} />}
          />
        </Tooltip>
      )}

      <Modal
        centered
        open={confirmOpen}
        okText="Confirmar"
        cancelText="Cancelar"
        onOk={() => onDelete()}
        onCancel={() => setConfirmOpen(false)}
        title={getModalHeader(`Esta seguro de borrar ${props.label}?`)}
      ></Modal>
    </div>
  );
};

export default ActionComponent;
