import { Moment } from "moment";
import { clearTimeline } from "../timeline";
import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getToastMessage } from "@components/Common/Render";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getReservationService,
  createReservationService,
  deleteReservationService,
  updateReservationService,
  getActiveReservationsService,
  getInactiveReservationsService,
  updateReservationStatusService,
} from "@services/reservationService";

import IReservation from "@interfaces/IReservation";

export interface ReservationState {
  activeReservations: IReservation[];
  inactiveReservations: IReservation[];
  selectedReservation: IReservation | null;
  loading: boolean;
}

const initialState: ReservationState = {
  activeReservations: [],
  inactiveReservations: [],
  selectedReservation: null,
  loading: false,
};

export const fetchReservationAsync = createAsyncThunk(
  "reservation/fetchReservation",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getReservationService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchActiveReservationsAsync = createAsyncThunk(
  "reservation/fetchActiveReservations",
  async (date: Moment, { rejectWithValue }) => {
    try {
      return await getActiveReservationsService(date);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const fetchInactiveReservationsAsync = createAsyncThunk(
  "reservation/fetchInactiveReservations",
  async (date: Moment, { rejectWithValue }) => {
    try {
      return await getInactiveReservationsService(date);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createReservationAsync = createAsyncThunk(
  "reservation/createReservation",
  async (newReservation: IReservation, { dispatch, rejectWithValue }) => {
    try {
      const response = await createReservationService(newReservation);
      dispatch(clearTimeline());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateReservationAsync = createAsyncThunk(
  "reservation/updateReservation",
  async (updatedReservation: IReservation, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateReservationService(updatedReservation);
      dispatch(clearTimeline());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateReservationStatusAsync = createAsyncThunk(
  "reservation/updateReservationStatus",
  async ({ id, status }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateReservationStatusService({ id, status });
      dispatch(clearTimeline());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteReservationAsync = createAsyncThunk(
  "reservation/deleteReservation",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteReservationService(id);
      dispatch(clearTimeline());
      return response;
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    clearSelectedReservation: (state) => {
      state.selectedReservation = null;
    },
    clearActiveReservations: (state) => {
      state.activeReservations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReservationAsync.fulfilled, (state, action) => {
        state.selectedReservation = action.payload;
        state.loading = false;
      })
      .addCase(fetchReservationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchActiveReservationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActiveReservationsAsync.fulfilled, (state, action) => {
        state.selectedReservation = null;
        state.activeReservations = action.payload;
        state.loading = false;
      })
      .addCase(fetchActiveReservationsAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(fetchInactiveReservationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInactiveReservationsAsync.fulfilled, (state, action) => {
        state.selectedReservation = null;
        state.inactiveReservations = action.payload;
        state.loading = false;
      })
      .addCase(fetchInactiveReservationsAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(createReservationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReservationAsync.fulfilled, (state) => {
        state.activeReservations = [];
        state.selectedReservation = null;
        state.loading = false;
        toastHandler.success("Reservación creada!");
      })
      .addCase(createReservationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateReservationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReservationAsync.fulfilled, (state) => {
        state.activeReservations = [];
        state.selectedReservation = null;
        state.loading = false;
        toastHandler.success("Reservación actualizada!");
      })
      .addCase(updateReservationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(updateReservationStatusAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReservationStatusAsync.fulfilled, (state, action) => {
        state.activeReservations = [];
        state.inactiveReservations = [];
        state.selectedReservation = null;
        state.loading = false;
        getToastMessage(action.payload.status);
      })
      .addCase(updateReservationStatusAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      })
      .addCase(deleteReservationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReservationAsync.fulfilled, (state) => {
        state.activeReservations = [];
        state.selectedReservation = null;
        state.loading = false;
        toastHandler.success("Reservación borrada exitosamente!");
      })
      .addCase(deleteReservationAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export const { clearSelectedReservation, clearActiveReservations } =
  reservationSlice.actions;
export default reservationSlice.reducer;
