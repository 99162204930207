import { getHondurasCities } from "@utils/util";
import { IRenderItem, renderItem } from "@components/Form";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import CountryLib from "countrycitystatejson";

interface LocationState {
  cityOptions: IRenderItem[];
  countryOptions: IRenderItem[];
  loading: boolean;
  error: string | null;
}

const initialState: LocationState = {
  cityOptions: [],
  countryOptions: [],
  loading: false,
  error: null,
};

export const fetchCitiesAsync = createAsyncThunk(
  "location/fetchCities",
  async (shortName: string, { rejectWithValue }) => {
    try {
      let cities;
      if (shortName === "HN") {
        cities = getHondurasCities();
      } else {
        const country = CountryLib.getCountryByShort(shortName);
        cities = Object.values(country.states).flatMap((x) => x);
      }
      return cities?.map((city: any) =>
        renderItem({ id: city.name, name: city.name })
      );
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchCountriesAsync = createAsyncThunk(
  "location/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const countries = CountryLib.getCountries();
      return countries?.map((country: any) =>
        renderItem({ id: country.shortName, name: country.name })
      );
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountriesAsync.fulfilled, (state, action) => {
        state.countryOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchCountriesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchCitiesAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCitiesAsync.fulfilled, (state, action) => {
        state.cityOptions = action.payload;
        state.loading = false;
      })
      .addCase(fetchCitiesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default locationSlice.reducer;
