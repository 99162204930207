import axios from "axios";
import IRoom from "@interfaces/IRoom";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/room";

export const getRoomService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getRoomsService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const createRoomService = async (roomData: IRoom) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    roomData,
    headers
  );
  return response.data;
};

export const updateRoomService = async (roomData: IRoom) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${roomData.id}`,
    roomData,
    headers
  );
  return response.data;
};

export const deleteRoomService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
