import axios from "axios";
import IService from "@interfaces/IService";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/service";

export const getServiceService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getServicesService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const createServiceService = async (serviceData: IService) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    serviceData,
    headers
  );
  return response.data;
};

export const updateServiceService = async (serviceData: IService) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${serviceData.id}`,
    serviceData,
    headers
  );
  return response.data;
};
