import { getAuth } from "firebase/auth";

let cachedToken: string | null = null;
let tokenExpiryTime: number | null = null;

export const getConfigHeaders = async () => {
  try {
    const auth: any = getAuth();
    const currentTime = Date.now();

    if (!cachedToken || (tokenExpiryTime && currentTime >= tokenExpiryTime)) {
      const user = auth.currentUser;
      if (!user) throw new Error("No authenticated user found.");

      const tokenResult = await user.getIdTokenResult();
      cachedToken = tokenResult.token;
      tokenExpiryTime = tokenResult.expirationTime ? new Date(tokenResult.expirationTime).getTime() : null;
    }

    return {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
      },
    };
  } catch (error) {
    console.error("Error generating headers:", error);
    throw error;
  }
};