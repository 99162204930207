import React, { Key, useEffect, useState } from "react";
import { Table, Layout, Card } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { deleteCompanyAsync, fetchCompaniesAsync } from "@store/slices/company";

import {
  CompanyColumns,
  CompanyExpandableComponent,
} from "./helpers/Components";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const CompaniesTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  const { companies, loading } = useAppSelector((state) => state.company);

  useEffect(() => {
    if (companies.length === 0) dispatch(fetchCompaniesAsync());
  }, [dispatch, companies.length]);

  const onDelete = async (id: string) => await dispatch(deleteCompanyAsync(id));

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            dataSource={companies}
            pagination={{ pageSize: 20 }}
            expandable={CompanyExpandableComponent}
            columns={CompanyColumns(filteredString, sortedInfo, onDelete)}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default CompaniesTable;
