import React, { useState } from "react";
import { jsPDF } from "jspdf";
import { Button, Modal, Tooltip } from "antd";
import { getModalHeader } from "@components/Common/Render";
import { DeleteOutlined, FilePdfOutlined } from "@ant-design/icons";

import {
  setRoomsSection,
  setHeaderSection,
  setContactSection,
  setDetailsSection,
  setProductsSection,
  setServicesSection,
  setObservationsSection,
  setFooterWithPageNumbers,
} from ".";

import IHotel from "@interfaces/IHotel";
import IQuote from "@interfaces/IQuote";

const ActionComponent: React.FC<any> = (props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onDelete = () => {
    props.onDelete && props.onDelete(props.quote);
    setConfirmOpen(false);
  };

  const generatePdfWithHeader = (hotel: IHotel, quote: IQuote) => {
    const doc = new jsPDF();
    const { quoteDetail } = quote;
    let currentY = 80;
    setHeaderSection(doc, hotel, quote);
    setContactSection(doc, quote);
    currentY = setObservationsSection(doc, quoteDetail.notes, currentY)
    currentY = setRoomsSection(doc, quoteDetail.rooms, currentY);
    currentY = setProductsSection(doc, quoteDetail.products, currentY);
    currentY = setServicesSection(doc, quoteDetail.services, currentY);
    setDetailsSection(doc, quoteDetail, currentY);
    setFooterWithPageNumbers(doc);
    doc.save(`cotización-${quote.id?.replace(/-/g, "").slice(0, 8)}`);
  };

  return (
    <>
      <Tooltip title="Descargar PDF">
        <Button
          ghost
          className="ghost-button"
          icon={
            <FilePdfOutlined
              onClick={() => generatePdfWithHeader(props.hotel, props.quote)}
            />
          }
        />
      </Tooltip>
      <Tooltip title="Borrar">
        <Button
          ghost
          className="ghost-button"
          icon={<DeleteOutlined onClick={() => setConfirmOpen(true)} />}
        />
      </Tooltip>
      <Modal
        centered
        open={confirmOpen}
        okText="Confirmar"
        cancelText="Cancelar"
        onOk={() => onDelete()}
        onCancel={() => setConfirmOpen(false)}
        title={getModalHeader(`Esta seguro de borrar la cotización?`)}
      ></Modal>
    </>
  );
};

export default ActionComponent;
