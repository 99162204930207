export const citiesHonduras = [
  {
    idDepartamento: 0,
    name: "Atlantida",
    municipios: [
      {
        id: "0101",
        name: "La Ceiba",
      },
      {
        id: "0102",
        name: "El Porvenir",
      },
      {
        id: "0103",
        name: "Esparta",
      },
      {
        id: "0104",
        name: "Jutiapa",
      },
      {
        id: "0105",
        name: "La Masica",
      },
      {
        id: "0106",
        name: "San Francisco",
      },
      {
        id: "0107",
        name: "Tela",
      },
      {
        id: "0108",
        name: "Arizona",
      },
    ],
  },
  {
    idDepartamento: 1,
    name: "Colon",
    municipios: [
      {
        id: "0201",
        name: "Trujillo",
      },
      {
        id: "0202",
        name: "Balfate",
      },
      {
        id: "0203",
        name: "Iriona",
      },
      {
        id: "0204",
        name: "Limon",
      },
      {
        id: "0205",
        name: "Saba",
      },
      {
        id: "0206",
        name: "Santa Fe",
      },
      {
        id: "0207",
        name: "Santa Rosa Aguan",
      },
      {
        id: "0208",
        name: "Sonaguera",
      },
      {
        id: "0209",
        name: "Tocoa",
      },
      {
        id: "0210",
        name: "Bonito Oriental",
      },
    ],
  },
  {
    idDepartamento: 2,
    name: "Comayagua",
    municipios: [
      {
        id: "0301",
        name: "Comayagua",
      },
      {
        id: "0302",
        name: "Ajuterique",
      },
      {
        id: "0303",
        name: "El Rosario",
      },
      {
        id: "0304",
        name: "Esquias",
      },
      {
        id: "0305",
        name: "Humuya",
      },
      {
        id: "0306",
        name: "La Libertad",
      },
      {
        id: "0307",
        name: "Lamani",
      },
      {
        id: "0308",
        name: "La Trinidad",
      },
      {
        id: "0309",
        name: "Lejamani",
      },
      {
        id: "0310",
        name: "Meambar",
      },
      {
        id: "0311",
        name: "Minas De Oro",
      },
      {
        id: "0312",
        name: "Ojos De Agua",
      },
      {
        id: "0313",
        name: "San Jeronimo",
      },
      {
        id: "0314",
        name: "San Jose De Comayagua",
      },
      {
        id: "0315",
        name: "San Jose Del Potrero",
      },
      {
        id: "0316",
        name: "San Luis",
      },
      {
        id: "0317",
        name: "San Sebastian",
      },
      {
        id: "0318",
        name: "Siguatepeque",
      },
      {
        id: "0319",
        name: "Villa De San Antonio",
      },
      {
        id: "0320",
        name: "Las Lajas",
      },
      {
        id: "0321",
        name: "Taulabe",
      },
    ],
  },
  {
    idDepartamento: 3,
    name: "Copan",
    municipios: [
      {
        id: "0401",
        name: "Santa Rosa De Copan",
      },
      {
        id: "0402",
        name: "Cabañas",
      },
      {
        id: "0403",
        name: "Concepcion",
      },
      {
        id: "0404",
        name: "Copan Ruinas",
      },
      {
        id: "0405",
        name: "Corquin",
      },
      {
        id: "0406",
        name: "Cucuyagua",
      },
      {
        id: "0407",
        name: "Dolores",
      },
      {
        id: "0408",
        name: "Dulce Nombre",
      },
      {
        id: "0409",
        name: "El Paraiso",
      },
      {
        id: "0410",
        name: "Florida",
      },
      {
        id: "0411",
        name: "La Jigua",
      },
      {
        id: "0412",
        name: "La Union",
      },
      {
        id: "0413",
        name: "Nueva Arcadia",
      },
      {
        id: "0414",
        name: "San Agustin",
      },
      {
        id: "0415",
        name: "San Antonio",
      },
      {
        id: "0416",
        name: "San Jeronimo",
      },
      {
        id: "0417",
        name: "San Jose",
      },
      {
        id: "0418",
        name: "San Juan De Opoa",
      },
      {
        id: "0419",
        name: "San Nicolas",
      },
      {
        id: "0420",
        name: "San Pedro",
      },
      {
        id: "0421",
        name: "Santa Rita",
      },
      {
        id: "0422",
        name: "Trinidad",
      },
      {
        id: "0423",
        name: "Veracruz",
      },
    ],
  },
  {
    idDepartamento: 4,
    name: "Cortes",
    municipios: [
      {
        id: "0501",
        name: "San Pedro Sula",
      },
      {
        id: "0502",
        name: "Choloma",
      },
      {
        id: "0503",
        name: "Omoa",
      },
      {
        id: "0504",
        name: "Pimienta",
      },
      {
        id: "0505",
        name: "Potrerillos",
      },
      {
        id: "0506",
        name: "Puerto Cortes",
      },
      {
        id: "0507",
        name: "San Antonio De Cortes",
      },
      {
        id: "0508",
        name: "San Francisco De Yojoa",
      },
      {
        id: "0509",
        name: "San Manuel",
      },
      {
        id: "0510",
        name: "Santa Cruz De Yojoa",
      },
      {
        id: "0511",
        name: "Villanueva",
      },
      {
        id: "0512",
        name: "La Lima",
      },
    ],
  },
  {
    idDepartamento: 5,
    name: "Choluteca",
    municipios: [
      {
        id: "0601",
        name: "Choluteca",
      },
      {
        id: "0602",
        name: "Apacilagua",
      },
      {
        id: "0603",
        name: "Concepcion De Maria",
      },
      {
        id: "0604",
        name: "Duyure",
      },
      {
        id: "0605",
        name: "El Corpus",
      },
      {
        id: "0606",
        name: "El Triunfo",
      },
      {
        id: "0607",
        name: "Marcovia",
      },
      {
        id: "0608",
        name: "Morolica",
      },
      {
        id: "0609",
        name: "Namasigue",
      },
      {
        id: "0610",
        name: "Orocuina",
      },
      {
        id: "0611",
        name: "Pespire",
      },
      {
        id: "0612",
        name: "San Antonio De Flores",
      },
      {
        id: "0613",
        name: "San Isidro",
      },
      {
        id: "0614",
        name: "San Jose",
      },
      {
        id: "0615",
        name: "San Marcos De Colon",
      },
      {
        id: "0616",
        name: "Santa Ana De Yusguare",
      },
    ],
  },
  {
    idDepartamento: 6,
    name: "El Paraiso",
    municipios: [
      {
        id: "0701",
        name: "Yuscaran",
      },
      {
        id: "0702",
        name: "Alauca",
      },
      {
        id: "0703",
        name: "Danli",
      },
      {
        id: "0704",
        name: "El Paraiso",
      },
      {
        id: "0705",
        name: "Guinope",
      },
      {
        id: "0706",
        name: "Jacaleapa",
      },
      {
        id: "0707",
        name: "Liure",
      },
      {
        id: "0708",
        name: "Moroceli",
      },
      {
        id: "0709",
        name: "Oropoli",
      },
      {
        id: "0710",
        name: "Potrerillos",
      },
      {
        id: "0711",
        name: "San Antonio De Flores",
      },
      {
        id: "0712",
        name: "San Lucas",
      },
      {
        id: "0713",
        name: "San Matias",
      },
      {
        id: "0714",
        name: "Soledad",
      },
      {
        id: "0715",
        name: "Teupasenti",
      },
      {
        id: "0716",
        name: "Texiguat",
      },
      {
        id: "0717",
        name: "Vado Ancho",
      },
      {
        id: "0718",
        name: "Yauyupe",
      },
      {
        id: "0719",
        name: "Trojes",
      },
    ],
  },
  {
    idDepartamento: 7,
    name: "Francisco Morazan",
    municipios: [
      {
        id: "0801",
        name: "Distrito Central",
      },
      {
        id: "0802",
        name: "Alubaren",
      },
      {
        id: "0803",
        name: "Cedros",
      },
      {
        id: "0804",
        name: "Curaren",
      },
      {
        id: "0805",
        name: "El Porvenir",
      },
      {
        id: "0806",
        name: "Guaimaca",
      },
      {
        id: "0807",
        name: "La Libertad",
      },
      {
        id: "0808",
        name: "La Venta",
      },
      {
        id: "0809",
        name: "Lepaterique",
      },
      {
        id: "0810",
        name: "Maraita",
      },
      {
        id: "0811",
        name: "Marale",
      },
      {
        id: "0812",
        name: "Nueva Armenia",
      },
      {
        id: "0813",
        name: "Ojojona",
      },
      {
        id: "0814",
        name: "Orica",
      },
      {
        id: "0815",
        name: "Reitoca",
      },
      {
        id: "0816",
        name: "Sabanagrande",
      },
      {
        id: "0817",
        name: "San Antonio De Oriente",
      },
      {
        id: "0818",
        name: "San Buenaventura",
      },
      {
        id: "0819",
        name: "San Ignacio",
      },
      {
        id: "0820",
        name: "Cantarranas",
      },
      {
        id: "0821",
        name: "San Miguelito",
      },
      {
        id: "0822",
        name: "Santa Ana",
      },
      {
        id: "0823",
        name: "Santa Lucia",
      },
      {
        id: "0824",
        name: "Talanga",
      },
      {
        id: "0825",
        name: "Tatumbla",
      },
      {
        id: "0826",
        name: "Valle De Angeles",
      },
      {
        id: "0827",
        name: "Villa San Francisco",
      },
      {
        id: "0828",
        name: "Vallecillos",
      },
      {
        id: 28,
        name: "COMAYAGÜELA",
      },
    ],
  },
  {
    idDepartamento: 8,
    name: "Gracias A Dios",
    municipios: [
      {
        id: "0901",
        name: "Puerto Lempira",
      },
      {
        id: "0902",
        name: "Brus Laguna",
      },
      {
        id: "0903",
        name: "Ahuas",
      },
      {
        id: "0904",
        name: "Juan Francisco Bulnes",
      },
      {
        id: "0905",
        name: "Villeda Morales",
      },
      {
        id: "0906",
        name: "Wampusirpi",
      },
    ],
  },
  {
    idDepartamento: 9,
    name: "Intibuca",
    municipios: [
      {
        id: "1001",
        name: "La Esperanza",
      },
      {
        id: "1002",
        name: "Camasca",
      },
      {
        id: "1003",
        name: "Colomoncagua",
      },
      {
        id: "1004",
        name: "Concepcion",
      },
      {
        id: "1005",
        name: "Dolores",
      },
      {
        id: "1006",
        name: "Intibuca",
      },
      {
        id: "1007",
        name: "Jesus De Otoro",
      },
      {
        id: "1008",
        name: "Magdalena",
      },
      {
        id: "1009",
        name: "Masaguara",
      },
      {
        id: "1010",
        name: "San Antonio",
      },
      {
        id: "1011",
        name: "San Isidro",
      },
      {
        id: "1012",
        name: "San Juan",
      },
      {
        id: "1013",
        name: "San Marcos Sierra",
      },
      {
        id: "1014",
        name: "San Miguelito",
      },
      {
        id: "1015",
        name: "Santa Lucia",
      },
      {
        id: "1016",
        name: "Yamaranguila",
      },
      {
        id: "1017",
        name: "San Francisco De Opalaca",
      },
    ],
  },
  {
    idDepartamento: 10,
    name: "Islas De La Bahia",
    municipios: [
      {
        id: "1101",
        name: "Roatan",
      },
      {
        id: "1102",
        name: "Guanaja",
      },
      {
        id: "1103",
        name: "Jose Santos Guardiola",
      },
      {
        id: "1104",
        name: "Utila",
      },
    ],
  },
  {
    idDepartamento: 11,
    name: "La Paz",
    municipios: [
      {
        id: "1201",
        name: "La Paz",
      },
      {
        id: "1202",
        name: "Aguanqueterique",
      },
      {
        id: "1203",
        name: "Cabañas",
      },
      {
        id: "1204",
        name: "Cane",
      },
      {
        id: "1205",
        name: "Chinacla",
      },
      {
        id: "1206",
        name: "Guajiquiro",
      },
      {
        id: "1207",
        name: "Lauterique",
      },
      {
        id: "1208",
        name: "Marcala",
      },
      {
        id: "1209",
        name: "Mercedes De Oriente",
      },
      {
        id: "1210",
        name: "Opatoro",
      },
      {
        id: "1211",
        name: "San Antonio Del Norte",
      },
      {
        id: "1212",
        name: "San Jose",
      },
      {
        id: "1213",
        name: "San Juan",
      },
      {
        id: "1214",
        name: "San Pedro De Tutule",
      },
      {
        id: "1215",
        name: "Santa Ana",
      },
      {
        id: "1216",
        name: "Santa Elena",
      },
      {
        id: "1217",
        name: "Santa Maria",
      },
      {
        id: "1218",
        name: "Santiago De Puringla",
      },
      {
        id: "1219",
        name: "Yarula",
      },
    ],
  },
  {
    idDepartamento: 12,
    name: "Lempira",
    municipios: [
      {
        id: "1301",
        name: "Gracias",
      },
      {
        id: "1302",
        name: "Belen",
      },
      {
        id: "1303",
        name: "Candelaria",
      },
      {
        id: "1304",
        name: "Cololaca",
      },
      {
        id: "1305",
        name: "Erandique",
      },
      {
        id: "1306",
        name: "Gualcince",
      },
      {
        id: "1307",
        name: "Guarita",
      },
      {
        id: "1308",
        name: "La Campa",
      },
      {
        id: "1309",
        name: "La Iguala",
      },
      {
        id: "1310",
        name: "Las Flores",
      },
      {
        id: "1311",
        name: "La Union",
      },
      {
        id: "1312",
        name: "La Virtud",
      },
      {
        id: "1313",
        name: "Lepaera",
      },
      {
        id: "1314",
        name: "Mapulaca",
      },
      {
        id: "1315",
        name: "Piraera",
      },
      {
        id: "1316",
        name: "San Andres",
      },
      {
        id: "1317",
        name: "San Francisco",
      },
      {
        id: "1318",
        name: "San Juan Guarita",
      },
      {
        id: "1319",
        name: "San Manuel Colohete",
      },
      {
        id: "1320",
        name: "San Rafael",
      },
      {
        id: "1321",
        name: "San Sebastian",
      },
      {
        id: "1322",
        name: "Santa Cruz",
      },
      {
        id: "1323",
        name: "Talgua",
      },
      {
        id: "1324",
        name: "Tambla",
      },
      {
        id: "1325",
        name: "Tomala",
      },
      {
        id: "1326",
        name: "Valladolid",
      },
      {
        id: "1327",
        name: "Virginia",
      },
      {
        id: "1328",
        name: "San Marcos De Caiquin",
      },
    ],
  },
  {
    idDepartamento: 13,
    name: "Ocotepeque",
    municipios: [
      {
        id: "1401",
        name: "Ocotepeque",
      },
      {
        id: "1402",
        name: "Belen Gualcho",
      },
      {
        id: "1403",
        name: "Concepcion",
      },
      {
        id: "1404",
        name: "Dolores Merendon",
      },
      {
        id: "1405",
        name: "Fraternidad",
      },
      {
        id: "1406",
        name: "La Encarnacion",
      },
      {
        id: "1407",
        name: "La Labor",
      },
      {
        id: "1408",
        name: "Lucerna",
      },
      {
        id: "1409",
        name: "Mercedes",
      },
      {
        id: "1410",
        name: "San Fernando",
      },
      {
        id: "1411",
        name: "San Francisco Del Valle",
      },
      {
        id: "1412",
        name: "San Jorge",
      },
      {
        id: "1413",
        name: "San Marcos",
      },
      {
        id: "1414",
        name: "Santa Fe",
      },
      {
        id: "1415",
        name: "Sensenti",
      },
      {
        id: "1416",
        name: "Sinuapa",
      },
    ],
  },
  {
    idDepartamento: 14,
    name: "Olancho",
    municipios: [
      {
        id: "1501",
        name: "Juticalpa",
      },
      {
        id: "1502",
        name: "Campamento",
      },
      {
        id: "1503",
        name: "Catacamas",
      },
      {
        id: "1504",
        name: "Concordia",
      },
      {
        id: "1505",
        name: "Dulce Nombre De Culmi",
      },
      {
        id: "1506",
        name: "El Rosario",
      },
      {
        id: "1507",
        name: "Esquipulas Del Norte",
      },
      {
        id: "1508",
        name: "Gualaco",
      },
      {
        id: "1509",
        name: "Guarizama",
      },
      {
        id: "1510",
        name: "Guata",
      },
      {
        id: "1511",
        name: "Guayape",
      },
      {
        id: "1512",
        name: "Jano",
      },
      {
        id: "1513",
        name: "La Union",
      },
      {
        id: "1514",
        name: "Mangulile",
      },
      {
        id: "1515",
        name: "Manto",
      },
      {
        id: "1516",
        name: "Salama",
      },
      {
        id: "1517",
        name: "San Esteban",
      },
      {
        id: "1518",
        name: "San Francisco De Becerra",
      },
      {
        id: "1519",
        name: "San Francisco De La Paz",
      },
      {
        id: "1520",
        name: "Santa Maria Del Real",
      },
      {
        id: "1521",
        name: "Silca",
      },
      {
        id: "1522",
        name: "Yocon",
      },
      {
        id: "1523",
        name: "Patuca",
      },
    ],
  },
  {
    idDepartamento: 15,
    name: "Santa Barbara",
    municipios: [
      {
        id: "1601",
        name: "Santa Barbara",
      },
      {
        id: "1602",
        name: "Arada",
      },
      {
        id: "1603",
        name: "Atima",
      },
      {
        id: "1604",
        name: "Azacualpa",
      },
      {
        id: "1605",
        name: "Ceguaca",
      },
      {
        id: "1606",
        name: "San Jose De Colinas",
      },
      {
        id: "1607",
        name: "Concepcion Del Norte",
      },
      {
        id: "1608",
        name: "Concepcion Del Sur",
      },
      {
        id: "1609",
        name: "Chinda",
      },
      {
        id: "1610",
        name: "El Nispero",
      },
      {
        id: "1611",
        name: "Gualala",
      },
      {
        id: "1612",
        name: "Ilama",
      },
      {
        id: "1613",
        name: "Macuelizo",
      },
      {
        id: "1614",
        name: "Naranjito",
      },
      {
        id: "1615",
        name: "Nuevo Celilac",
      },
      {
        id: "1616",
        name: "Petoa",
      },
      {
        id: "1617",
        name: "Proteccion",
      },
      {
        id: "1618",
        name: "Quimistan",
      },
      {
        id: "1619",
        name: "San Francisco De Ojuera",
      },
      {
        id: "1620",
        name: "San Luis",
      },
      {
        id: "1621",
        name: "San Marcos",
      },
      {
        id: "1622",
        name: "San Nicolas",
      },
      {
        id: "1623",
        name: "San Pedro De Zacapa",
      },
      {
        id: "1624",
        name: "Santa Rita",
      },
      {
        id: "1625",
        name: "San Vicente Centenario",
      },
      {
        id: "1626",
        name: "Trinidad",
      },
      {
        id: "1627",
        name: "Las Vegas",
      },
      {
        id: "1628",
        name: "Nueva Frontera",
      },
    ],
  },
  {
    idDepartamento: 16,
    name: "Valle",
    municipios: [
      {
        id: "1701",
        name: "Nacaome",
      },
      {
        id: "1702",
        name: "Alianza",
      },
      {
        id: "1703",
        name: "Amapala",
      },
      {
        id: "1704",
        name: "Aramecina",
      },
      {
        id: "1705",
        name: "Caridad",
      },
      {
        id: "1706",
        name: "Goascoran",
      },
      {
        id: "1707",
        name: "Langue",
      },
      {
        id: "1708",
        name: "San Francisco De Coray",
      },
      {
        id: "1709",
        name: "San Lorenzo",
      },
    ],
  },
  {
    idDepartamento: 17,
    name: "Yoro",
    municipios: [
      {
        id: "1801",
        name: "Yoro",
      },
      {
        id: "1802",
        name: "Arenal",
      },
      {
        id: "1803",
        name: "El Negrito",
      },
      {
        id: "1804",
        name: "El Progreso",
      },
      {
        id: "1805",
        name: "Jocon",
      },
      {
        id: "1806",
        name: "Morazan",
      },
      {
        id: "1807",
        name: "Olanchito",
      },
      {
        id: "1808",
        name: "Santa Rita",
      },
      {
        id: "1809",
        name: "Sulaco",
      },
      {
        id: "1810",
        name: "Victoria",
      },
      {
        id: "1811",
        name: "Yorito",
      },
    ],
  },
  {
    idDepartamento: 18,
    name: "Auxiliares",
    municipios: [
      {
        id: "0814",
        name: "San Juan",
      },
      {
        id: "0901",
        name: "Rus Rus",
      },
      {
        id: "0902",
        name: "Tikiraya",
      },
      {
        id: "0905",
        name: "Usibila",
      },
      {
        id: "0906",
        name: "Krausirpe",
      },
      {
        id: "1003",
        name: "San Antonio",
      },
      {
        id: "1210",
        name: "Florida",
      },
      {
        id: "1211",
        name: "Mecetas",
      },
      {
        id: "1215",
        name: "Estancia",
      },
      {
        id: "1216",
        name: "Nahuaterique",
      },
      {
        id: "1219",
        name: "El Zancudo",
      },
      {
        id: "1307",
        name: "San Pablo",
      },
      {
        id: "1318",
        name: "Sazalapa",
      },
    ],
  },
];
