import React, { Key, useEffect, useState } from "react";
import { Table, Layout, Card } from "antd";
import { fetchServicesAsync } from "@store/slices/service";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ProductColumns } from "@pages/Products/helpers/Components";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const ServicesTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  
  const { services, loading } = useAppSelector((state) => state.service);

  useEffect(() => {
    if (services.length === 0) dispatch(fetchServicesAsync());
  }, [dispatch, services.length]);

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            dataSource={services}
            pagination={{ pageSize: 20 }}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={ProductColumns(filteredString, sortedInfo)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default ServicesTable;
