import {
  WEEK_FORMAT,
  YEAR_FORMAT,
  TIME_FORMAT,
  DATE_FORMAT,
  MONTH_FORMAT,
  DATE_TIME_FORMAT,
  CALENDAR_DAY_FORMAT,
  DATE_FORMAT_POSTGRES,
  CALENDAR_DATE_FORMAT,
  FULL_DAY_MONTH_YEAR_FORMAT,
  SHORT_DAY_MONTH_YEAR_FORMAT,
  SHORT_DAY_TIME_MONTH_YEAR_FORMAT,
} from "@constants/date";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

export const isDateToday = (date: any) => date === moment().format(DATE_FORMAT);

export const getTime = (date: string) => {
  if (!date) return null;
  return moment(date);
};

export const getToday = () => moment();

export const getTodayString = () => moment().format(DATE_FORMAT);

export const getTodayTimeString = () => moment().format(DATE_TIME_FORMAT);

export const getTodayShortString = () =>
  `${moment().format(SHORT_DAY_MONTH_YEAR_FORMAT)}`;

export const getDate = (date: string) => {
  if (!date) return null;
  return moment(date, DATE_FORMAT);
};

export const getDateString = (date: any) => {
  if (!date) return "";
  return moment(date).format(DATE_FORMAT);
};

export const getDateTimeString = (date: string) => {
  if (!date) return "";
  return moment(date).format(DATE_TIME_FORMAT);
};

export const getMonthString = (date: any) => {
  if (!date) return "";
  return moment(date, DATE_FORMAT).format(MONTH_FORMAT);
};

export const getYearString = (date: any) => {
  if (!date) return "";
  return moment(date, DATE_FORMAT).format(YEAR_FORMAT);
};

export const isDate = (value: any): boolean => {
  const allowedFormats = ["YYYY-MM-DD", moment.ISO_8601];
  return moment(value, allowedFormats, true).isValid();
};

export const getDateDatabase = (date: string) => {
  if (!date) return null;
  return moment(date, DATE_FORMAT_POSTGRES);
};

export const getDateStringDatabase = (date: any) => {
  if (!date) return "";
  return moment(date).format(DATE_FORMAT_POSTGRES);
};

export const getShortDateTimeString = (date: any) => {
  if (!date) return "";
  return moment(date).format(SHORT_DAY_TIME_MONTH_YEAR_FORMAT);
};

export const getShortDateString = (date: string) =>
  `${getDateDatabase(date)?.format(SHORT_DAY_MONTH_YEAR_FORMAT)}`;

export const getLongDateString = (date: any) => {
  if (!date) return "";
  return `${getDateDatabase(date)?.format(FULL_DAY_MONTH_YEAR_FORMAT)}`;
};

export const getTimeString = (date: any) => {
  if (!date) return "";
  return moment(date).format(TIME_FORMAT);
};

export const getDateDifference = (dateA: string, dateB: string) => {
  return moment(dateA).diff(moment(dateB));
};

export const getNumberOfDays = (initialDate: any, finalDate: any) =>{
  if (!initialDate || !finalDate) return "";
  return getDateDatabase(finalDate)?.diff(getDateDatabase(initialDate), "days");
}

export const getInitialDate = (date: any) => {
  return moment(date, DATE_FORMAT).subtract(15, "days");
};

export const getFinalDate = (date: any) => {
  return moment(date, DATE_FORMAT).add(15, "days");
};

export const getCalendarDayFormat = (date: string) =>
  moment(date, DATE_FORMAT).format(CALENDAR_DAY_FORMAT);

export const getCalendarDateFormat = (date: string) =>
  moment(date, DATE_FORMAT).format(CALENDAR_DATE_FORMAT);

export const getDateRange = (startDate: any, endDate: any) => {
  const now = startDate.clone();
  const dates = [];
  while (now.isSameOrBefore(endDate)) {
    const date = now.format(DATE_FORMAT);
    dates.push(date);
    now.add(1, "days");
  }
  return dates;
};

export const getDateCountInRange = (
  startDateString: string,
  endDateString: string,
  selectedDate: string
) => {
  const currentDate = moment(startDateString, DATE_FORMAT);
  const endDate = moment(endDateString, DATE_FORMAT);
  const givenStartDate = moment(selectedDate, DATE_FORMAT).subtract(
    15,
    "days"
  );
  const givenEndDate = moment(selectedDate, DATE_FORMAT).add(15, "days");
  let count = 0;

  while (currentDate <= endDate) {
    if (
      currentDate.isSameOrAfter(givenStartDate) &&
      currentDate.isSameOrBefore(givenEndDate)
    ) {
      count++;
    }
    currentDate.add(1, "days");
  }

  return count;
};

export const weekStartEndFormat = (value: any) =>
  `${getInitialDate(value).format(WEEK_FORMAT)} ~ ${getFinalDate(value).format(
    `${WEEK_FORMAT} YYYY`
  )}`;

export const isInvoiceDateAboutToExpire = (dateString: string) => {
  const date = getDateDatabase(dateString);
  if (date) {
    return (
      date.diff(moment(), "days") >= 0 && date.diff(moment(), "days") <= 30
    );
  }
  return false;
};

export const isInvoiceDateValid = (dateString: string) =>
  moment().isSameOrBefore(getDateDatabase(dateString));
