import { Key, useEffect, useState } from "react";
import { Table, Layout, Card } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { InvoiceConfigurationColumns } from "./helpers/Components";
import { fetchInvoiceConfigurationsAsync } from "@store/slices/invoice";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const InvoiceConfigurationTable = () => {
  const dispatch = useAppDispatch();
  
  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");

  const { invoiceConfigurations, loading } = useAppSelector(
    (state) => state.invoice
  );

  useEffect(() => {
    if (invoiceConfigurations.length === 0) {
      dispatch(fetchInvoiceConfigurationsAsync());
    }
  }, [dispatch, invoiceConfigurations.length]);

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            rowKey={(x) => x.id}
            pagination={{ pageSize: 20 }}
            dataSource={invoiceConfigurations}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={InvoiceConfigurationColumns(filteredString, sortedInfo)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default InvoiceConfigurationTable;
