import IHotel from "@interfaces/IHotel";

import { toastHandler } from "@utils/toastHandler";
import { getErrorMessage } from "@utils/errorMessage";
import { getHotelService } from "@services/hotelService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export interface HotelState {
  hotel: IHotel | null;
  loading: boolean;
}

const initialState: HotelState = {
  hotel: null,
  loading: false,
};

export const fetchHotelAsync = createAsyncThunk(
  "hotel/fetchHotel",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getHotelService(id);
    } catch (error: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHotelAsync.fulfilled, (state, action) => {
        state.hotel = action.payload;
        state.loading = false;
      })
      .addCase(fetchHotelAsync.rejected, (state, action) => {
        state.loading = false;
        toastHandler.error(action.payload as string);
      });
  },
});

export default hotelSlice.reducer;
