import { PRIMARY_COLOR } from "@constants/globalConstants";
import { filterData } from "@components/Form";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getSpan, productOptionsRender } from "@components/Common/Render";
import { Form, Button, Select, Divider, InputNumber, Row, Col } from "antd";

const ProductsDetails: React.FC<any> = (props) => {
  const shouldDisable = (name: any) => {
    return !!props.form.getFieldValue("products")[name]?.invoiceIds?.length;
  };
  return (
    <Form.List name="products">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Form.Item key={key} noStyle>
              <Row>
                <Col span={8}>
                  <div>{getSpan("Nombre")}</div>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "key"]}
                    rules={[{ required: true }]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      showSearch
                      style={{ width: "90%" }}
                      filterOption={filterData}
                      options={props.productOptions}
                      disabled={shouldDisable(name)}
                      optionRender={productOptionsRender}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div>{getSpan("Precio")}</div>
                  <Form.Item
                    noStyle
                    {...restField}
                    initialValue={0}
                    name={[name, "price"]}
                  >
                    <InputNumber
                      min={0}
                      controls={true}
                      addonBefore="L."
                      style={{ width: "90%" }}
                      disabled={shouldDisable(name)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <div>{getSpan("Cantidad")}</div>
                  <Form.Item
                    noStyle
                    {...restField}
                    initialValue={1}
                    name={[name, "quantity"]}
                  >
                    <InputNumber
                      min={1}
                      controls={true}
                      style={{ width: "90%" }}
                      disabled={shouldDisable(name)}
                    />
                  </Form.Item>
                </Col>
                <Col span={1} style={{ marginTop: 30 }}>
                  {!shouldDisable(name) && (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(name)}
                    />
                  )}
                </Col>
              </Row>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              block
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
              style={{ color: PRIMARY_COLOR, borderColor: PRIMARY_COLOR }}
            >
              Agregar producto
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default ProductsDetails;
