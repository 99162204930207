export const validatePhoneNumber = (
  _: any,
  phonePrefix: any,
  phoneNumber: any
) => {
  const trimmedPhoneNumber = phoneNumber ? phoneNumber.trim() : "";

  if (trimmedPhoneNumber.length === 0) {
    return Promise.resolve();
  }

  if (phonePrefix === "+504" && trimmedPhoneNumber.length !== 8) {
    return Promise.reject(
      new Error("El número debe tener exactamente 8 dígitos.")
    );
  }
  if (trimmedPhoneNumber && !/^\d+$/.test(trimmedPhoneNumber)) {
    return Promise.reject(
      new Error("El número de teléfono solo debe contener dígitos.")
    );
  }
  return Promise.resolve();
};

export const validateDni = (_: any, country: any, dni: any) => {
  const trimmedDni = dni ? dni.trim() : "";

  if (trimmedDni.length === 0) {
    return Promise.resolve();
  }

  if (country === "HN") {
    if (trimmedDni && !/^[0-9]+$/.test(trimmedDni)) {
      return Promise.reject(new Error("Solo se permiten números."));
    }

    if (trimmedDni && trimmedDni.toString().length !== 13) {
      return Promise.reject(new Error("Debe contener exactamente 13 dígitos."));
    }
  }
  return Promise.resolve();
};

export const validateRtn = (_: any, country: any, rtn: any) => {
  const trimmedRtn = rtn ? rtn.trim() : "";

  if (trimmedRtn.length === 0) {
    return Promise.resolve();
  }

  if (country === "HN") {
    if (trimmedRtn && !/^[0-9]+$/.test(trimmedRtn)) {
      return Promise.reject(new Error("Solo se permiten números."));
    }

    if (trimmedRtn && trimmedRtn.toString().length !== 14) {
      return Promise.reject(new Error("Debe contener exactamente 14 dígitos."));
    }
  }
  return Promise.resolve();
};

export const validateNoSpecialChars = (_: any, value: string) => {
  if (!value || /^[a-zA-Z0-9\s]*$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("No se permiten caracteres especiales."));
};

export const validateSomeSpecialChars = (_: any, value: any) => {
  if (!value || /^[^/-]+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("No se permiten '/' o '-' en el nombre."));
};

export const validateTwoWords = (_: any, value: string) => {
  if (!value || /^[A-Za-z]+(?: [A-Za-z]+)+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error("Debe de contener al menos un nombre y un apellido.")
  );
};
