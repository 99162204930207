import { Tooltip } from "antd";
import { v4 as uuid } from "uuid";
import { sortDraggableComponents } from "./helpers";
import { Droppable } from "@components/Common/Droppable";
import { Draggable } from "@components/Common/Draggable";

import {
  getDate,
  weekStartEndFormat,
  getCalendarDayFormat,
  getCalendarDateFormat,
} from "@utils/date";

import DatePicker from "@components/Common/DatePicker";

export const getCalendarColumnDate = (date: string) => (
  <div>
    <div className="calendar-date-header">{getCalendarDateFormat(date)}</div>
    <div className="calendar-day-header">{getCalendarDayFormat(date)}</div>
  </div>
);

export const TimelineFilterComponent = (
  selectedDate: any,
  onDateChange: any
) => (
  <DatePicker
    size="large"
    style={{ width: 220 }}
    onChange={onDateChange}
    format={weekStartEndFormat}
    defaultValue={getDate(selectedDate)}
  />
);

export const DroppableComponent = ({ date, room }: any) => (
  <Droppable key={uuid()} id={uuid()} data={{ date, room }} />
);
DroppableComponent.displayName = "Droppable";

export const DraggableComponent = ({
  roomsCount,
  reservation,
  selectedDate,
}: any) => (
  <Draggable key={uuid()} id={uuid()} data={{ reservation, selectedDate }}>
    <h4 style={{ marginRight: 10 }}>
      {reservation.reservationIndex}/{roomsCount}
    </h4>
    <Tooltip title={`${reservation.userName} - ${reservation.roomName}`}>
      <h3
        style={{
          maxWidth: 300,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {reservation.userName}
      </h3>
    </Tooltip>
  </Draggable>
);
DraggableComponent.displayName = "Draggable";

export const getDataSource = (data: any, selectedDate: any) => {
  return data?.rooms?.map((room: any) => {
    const cell: any = {};
    data?.columns?.forEach((date: string) => {
      if (!cell[date]) {
        cell[date] = [];
      }
      cell[date].push(
        <DroppableComponent key={uuid()} date={date} room={room} />
      );
    });

    const reservations = data.rows[room.id];
    const [initialColumn] = data.columns;
    const initialColumnDate = getDate(initialColumn);

    reservations?.forEach((reservation: any) => {
      const roomsCount = data.reservationIndexMap[reservation.reservationId];
      const initialDate = getDate(reservation.initialDate);
      const startDate: any = initialDate?.isBefore(initialColumnDate)
        ? initialColumn
        : reservation.initialDate;

      const draggableComponent = (
        <DraggableComponent
          key={uuid()}
          reservation={reservation}
          selectedDate={selectedDate}
          roomsCount={roomsCount}
        />
      );

      if (!cell[startDate]) {
        cell[startDate] = [];
      }

      cell[startDate].push(draggableComponent);
      const droppableComponent = cell[startDate]?.find(
        (component: any) => component.type.displayName === "Droppable"
      );
      const draggableComponents = cell[startDate]?.filter(
        (component: any) => component.type.displayName === "Draggable"
      );
      const sortedDraggables = sortDraggableComponents(draggableComponents);
      cell[startDate] = droppableComponent
        ? [droppableComponent, ...sortedDraggables]
        : [...sortedDraggables];
    });

    return {
      key: uuid(),
      id: uuid(),
      rooms: `${room.name} ${room.type}`,
      ...cell,
    };
  });
};
