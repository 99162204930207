import { Link } from "react-router-dom";
import { toastHandler } from "@utils/toastHandler";
import { breadcrumbNameMap } from "@constants/breadcrumbMap";
import { reservationStatus } from "@constants/reservationStatus";

export const optionsRender = (option: any) => (
  <span>
    <b>{option.data.label}</b>
    <br />
    {option.data.phone}
  </span>
);

export const productOptionsRender = (option: any) => (
  <span>
    <b>{option.data.label}</b>
    <br />
    L. {option.data.price}
  </span>
);

export const getSpan = (text: string, color: string = "GrayText") => (
  <span style={{ color, fontSize: 16, fontWeight: "bold" }}>{text}</span>
);

export const getModalHeader = (message: string) => (
  <div className="info-modal">
    <div className="info-modal-title">
      <h2 style={{ marginTop: 0 }}>{message}</h2>
    </div>
  </div>
);

export const getBreadcrumbItems = (location: any) => {
  const currentPath = location.pathname;
  const pathSnippets = currentPath.split("/").filter((i: any) => i);
  const paths = pathSnippets.filter((i: any) => !isGUID(i));
  return paths.map((_: any, index: number) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const isForm = url.includes("form");
    let prefix = "";
    if (isForm) {
      prefix = pathSnippets.length > 2 ? "Editar " : "Crear ";
    }
    const label = prefix + breadcrumbNameMap[url];
    return {
      key: url,
      title: index === 0 ? <Link to={url}>{label}</Link> : label,
    };
  });
};

export const isGUID = (value: string): boolean => {
  const guidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(value);
};

export const getToastMessage = (status: string | null) => {
  switch (status) {
    case reservationStatus.CHECKIN:
      toastHandler.success("Checkin realizado exitosamente!");
      break;
    case reservationStatus.CHECKOUT:
      toastHandler.success("Checkout realizado exitosamente!");
      break;
    case reservationStatus.COMPLETED:
      toastHandler.success("Reservación completada exitosamente!");
      break;
  }
};

export const getHeaderTitle = (status: string | null) => {
  switch (status) {
    case reservationStatus.CHECKIN:
      return getModalHeader("Desea realizar checkin?");
    case reservationStatus.CHECKOUT:
      return getModalHeader("Desea realizar checkout?");
    case reservationStatus.COMPLETED:
      return getModalHeader("Desea completar la reservación?");
  }
};
