import IRoom from "@interfaces/IRoom";
import ActionComponent from "@components/Table/ActionComponent";

import { Key } from "react";
import { getCurrencyFormat, isFilterMatched, sortAlphaNum } from "@utils/util";

export const RoomColumns: any = (
  filteredString: Key,
  sortedInfo: any,
  onDelete: Function
) => [
  {
    key: "name",
    width: "40%",
    title: "Nombre",
    dataIndex: "name",
    align: "center",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IRoom) => isFilterMatched(value, record),
    sorter: (a: IRoom, b: IRoom) => sortAlphaNum(a.name, b.name),
    sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
  },
  {
    key: "type",
    width: "20%",
    title: "Tipo",
    dataIndex: "type",
    align: "center",
    sorter: (a: IRoom, b: IRoom) => sortAlphaNum(a.type, b.type),
    sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
  },
  {
    key: "price",
    width: "20%",
    title: "Precio",
    dataIndex: "price",
    align: "center",
    sorter: (a: IRoom, b: IRoom) => a.price - b.price,
    sortOrder: sortedInfo.columnKey === "price" ? sortedInfo.order : null,
    render: (price: number) => `L. ${getCurrencyFormat(price)}`,
  },
  {
    key: "x",
    width: "20%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (room: IRoom) => (
      <ActionComponent
        label="habitación"
        dataObject={room}
        onDelete={async () => await onDelete(room.id)}
      />
    ),
  },
];
