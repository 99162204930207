import IUser from "@interfaces/IUser";
import ActionComponent from "@components/Table/ActionComponent";

import { Key } from "react";
import { Card, Tag } from "antd";
import { getDateString } from "@utils/date";
import { getRoleColor } from "@components/Table/helpers";
import { isFilterMatched, sortAlphaNum } from "@utils/util";

export const UserColumns: any = (
  filteredString: Key,
  sortedInfo: any,
  onDelete: Function
) => [
  {
    key: "name",
    width: "25%",
    align: "center",
    dataIndex: "name",
    title: "Nombre y Apellido",
    filteredValue: [filteredString],
    onFilter: (value: string, record: IUser) => isFilterMatched(value, record),
    sorter: (a: IUser, b: IUser) => sortAlphaNum(a.name, b.name),
    sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
  },
  {
    key: "phone",
    width: "15%",
    align: "center",
    title: "Teléfono",
    sorter: (a: IUser, b: IUser) => sortAlphaNum(a.phone, b.phone),
    sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
    render: (user: IUser) => (
      <span>
        ({user.phonePrefix}) {user.phone}
      </span>
    ),
  },
  {
    key: "dni",
    width: "15%",
    align: "center",
    title: "DNI",
    dataIndex: "dni",
    sorter: (a: IUser, b: IUser) => sortAlphaNum(a.dni, b.dni),
    sortOrder: sortedInfo.columnKey === "dni" ? sortedInfo.order : null,
  },
  {
    key: "rtn",
    width: "15%",
    align: "center",
    title: "RTN",
    dataIndex: "rtn",
    sorter: (a: IUser, b: IUser) => sortAlphaNum(a.rtn, b.rtn),
    sortOrder: sortedInfo.columnKey === "rtn" ? sortedInfo.order : null,
  },
  {
    key: "role",
    width: "15%",
    title: "Role",
    align: "center",
    dataIndex: "role",
    sorter: (a: IUser, b: IUser) => sortAlphaNum(a.role, b.role),
    sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
    render: (role: string) => <Tag color={getRoleColor(role)}>{role}</Tag>,
  },
  {
    key: "x",
    width: "15%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (user: IUser) => (
      <ActionComponent
        label="usuario"
        dataObject={user}
        onDelete={async () => await onDelete(user.id)}
      />
    ),
  },
];

export const UserExpandableComponent = {
  expandedRowRender: (record: any) => (
    <>
      <Card className="mb" title="Detalles">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Correo </b> <br />
          <span>{record.email}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Pasaporte </b> <br /> <span>{record.passport}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Fecha de Nacimiento </b> <br />
          <span>{getDateString(record.birthday)}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail"></Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Género </b>
          <br />
          <span>{record.gender}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Ciudad</b> <br /> <span>{record.city}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>País</b> <br /> <span>{record.country}</span>
        </Card.Grid>
      </Card>
      <Card className="mb" title="Historial de Cambios">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Creado Por </b>
          <br />
          <span>{record.createdBy}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Modificado Por </b>
          <br />
          <span>{record.modifiedBy}</span>
        </Card.Grid>
      </Card>
    </>
  ),
};
