import ICompany from "@interfaces/ICompany";
import ActionComponent from "@components/Table/ActionComponent";

import { Key } from "react";
import { Card } from "antd";
import { isFilterMatched, sortAlphaNum } from "@utils/util";

export const CompanyColumns: any = (
  filteredString: Key,
  sortedInfo: any,
  onDelete: Function
) => [
  {
    key: "name",
    width: "40%",
    align: "center",
    title: "Nombre",
    dataIndex: "name",
    filteredValue: [filteredString],
    onFilter: (value: string, record: ICompany) =>
      isFilterMatched(value, record),
    sorter: (a: ICompany, b: ICompany) => sortAlphaNum(a.name, b.name),
    sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
  },
  {
    key: "phone",
    width: "15%",
    align: "center",
    title: "Teléfono",
    sorter: (a: ICompany, b: ICompany) => sortAlphaNum(a.phone, b.phone),
    sortOrder: sortedInfo.columnKey === "phone" ? sortedInfo.order : null,
    render: (company: ICompany) =>
      company.phone && (
        <span>
          ({company.phonePrefix}) {company.phone}
        </span>
      ),
  },
  {
    key: "rtn",
    width: "15%",
    title: "RTN",
    align: "center",
    dataIndex: "rtn",
    sorter: (a: ICompany, b: ICompany) => sortAlphaNum(a.rtn, b.rtn),
    sortOrder: sortedInfo.columnKey === "rtn" ? sortedInfo.order : null,
  },
  {
    key: "email",
    width: "15%",
    title: "Correo",
    align: "center",
    dataIndex: "email",
    sorter: (a: ICompany, b: ICompany) => sortAlphaNum(a.email, b.email),
    sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
  },
  {
    key: "x",
    width: "15%",
    dataIndex: "",
    title: "Acción",
    align: "center",
    render: (company: ICompany) => (
      <ActionComponent
        label={company.name}
        dataObject={company}
        onDelete={async () => await onDelete(company.id)}
      />
    ),
  },
];

export const CompanyExpandableComponent = {
  expandedRowRender: (record: any) => (
    <>
      <Card className="mb" title="Detalles">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Dirección </b>
          <br />
          <span>{record.address}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Ciudad </b> <br />
          <span>{record.city}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>País </b> <br /> <span>{record.country}</span>
        </Card.Grid>
      </Card>
      <Card className="mb" title="Historial de Cambios">
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Creado Por </b>
          <br />
          <span>{record.createdBy}</span>
        </Card.Grid>
        <Card.Grid hoverable={false} className="table-row-detail">
          <b>Modificado Por </b>
          <br />
          <span>{record.modifiedBy}</span>
        </Card.Grid>
      </Card>
    </>
  ),
};
