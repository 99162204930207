import React, { Key, useEffect, useState } from "react";
import { Table, Layout, Card } from "antd";
import { RoomColumns } from "./helpers/Components";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { deleteRoomAsync, fetchRoomsAsync } from "@store/slices/room";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const RoomsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");
  
  const { rooms, loading } = useAppSelector((state) => state.room);

  useEffect(() => {
    if (rooms.length === 0) dispatch(fetchRoomsAsync());
  }, [dispatch, rooms.length]);

  const onDelete = async (id: string) => await dispatch(deleteRoomAsync(id));

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            rowKey={(x) => x.id}
            dataSource={rooms}
            loading={loading}
            pagination={{ pageSize: 20 }}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={RoomColumns(filteredString, sortedInfo, onDelete)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default RoomsTable;
