export const codes = [
  {
    name: "Afghanistan",
    id: "+93",
    key: "AF",
  },
  {
    name: "Aland Islands",
    id: "+358",
    key: "AX",
  },
  {
    name: "Albania",
    id: "+355",
    key: "AL",
  },
  {
    name: "Algeria",
    id: "+213",
    key: "DZ",
  },
  {
    name: "AmericanSamoa",
    id: "+1684",
    key: "AS",
  },
  {
    name: "Andorra",
    id: "+376",
    key: "AD",
  },
  {
    name: "Angola",
    id: "+244",
    key: "AO",
  },
  {
    name: "Anguilla",
    id: "+1264",
    key: "AI",
  },
  {
    name: "Antarctica",
    id: "+672",
    key: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    id: "+1268",
    key: "AG",
  },
  {
    name: "Argentina",
    id: "+54",
    key: "AR",
  },
  {
    name: "Armenia",
    id: "+374",
    key: "AM",
  },
  {
    name: "Aruba",
    id: "+297",
    key: "AW",
  },
  {
    name: "Australia",
    id: "+61",
    key: "AU",
  },
  {
    name: "Austria",
    id: "+43",
    key: "AT",
  },
  {
    name: "Azerbaijan",
    id: "+994",
    key: "AZ",
  },
  {
    name: "Bahamas",
    id: "+1242",
    key: "BS",
  },
  {
    name: "Bahrain",
    id: "+973",
    key: "BH",
  },
  {
    name: "Bangladesh",
    id: "+880",
    key: "BD",
  },
  {
    name: "Barbados",
    id: "+1246",
    key: "BB",
  },
  {
    name: "Belarus",
    id: "+375",
    key: "BY",
  },
  {
    name: "Belgium",
    id: "+32",
    key: "BE",
  },
  {
    name: "Belize",
    id: "+501",
    key: "BZ",
  },
  {
    name: "Benin",
    id: "+229",
    key: "BJ",
  },
  {
    name: "Bermuda",
    id: "+1441",
    key: "BM",
  },
  {
    name: "Bhutan",
    id: "+975",
    key: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    id: "+591",
    key: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    id: "+387",
    key: "BA",
  },
  {
    name: "Botswana",
    id: "+267",
    key: "BW",
  },
  {
    name: "Brazil",
    id: "+55",
    key: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    id: "+246",
    key: "IO",
  },
  {
    name: "Brunei Darussalam",
    id: "+673",
    key: "BN",
  },
  {
    name: "Bulgaria",
    id: "+359",
    key: "BG",
  },
  {
    name: "Burkina Faso",
    id: "+226",
    key: "BF",
  },
  {
    name: "Burundi",
    id: "+257",
    key: "BI",
  },
  {
    name: "Cambodia",
    id: "+855",
    key: "KH",
  },
  {
    name: "Cameroon",
    id: "+237",
    key: "CM",
  },
  {
    name: "Canada",
    id: "+1",
    key: "CA",
  },
  {
    name: "Cape Verde",
    id: "+238",
    key: "CV",
  },
  {
    name: "Cayman Islands",
    id: "+ 345",
    key: "KY",
  },
  {
    name: "Central African Republic",
    id: "+236",
    key: "CF",
  },
  {
    name: "Chad",
    id: "+235",
    key: "TD",
  },
  {
    name: "Chile",
    id: "+56",
    key: "CL",
  },
  {
    name: "China",
    id: "+86",
    key: "CN",
  },
  {
    name: "Christmas Island",
    id: "+61",
    key: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    id: "+61",
    key: "CC",
  },
  {
    name: "Colombia",
    id: "+57",
    key: "CO",
  },
  {
    name: "Comoros",
    id: "+269",
    key: "KM",
  },
  {
    name: "Congo",
    id: "+242",
    key: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    id: "+243",
    key: "CD",
  },
  {
    name: "Cook Islands",
    id: "+682",
    key: "CK",
  },
  {
    name: "Costa Rica",
    id: "+506",
    key: "CR",
  },
  {
    name: "Cote d'Ivoire",
    id: "+225",
    key: "CI",
  },
  {
    name: "Croatia",
    id: "+385",
    key: "HR",
  },
  {
    name: "Cuba",
    id: "+53",
    key: "CU",
  },
  {
    name: "Cyprus",
    id: "+357",
    key: "CY",
  },
  {
    name: "Czech Republic",
    id: "+420",
    key: "CZ",
  },
  {
    name: "Denmark",
    id: "+45",
    key: "DK",
  },
  {
    name: "Djibouti",
    id: "+253",
    key: "DJ",
  },
  {
    name: "Dominica",
    id: "+1767",
    key: "DM",
  },
  {
    name: "Dominican Republic",
    id: "+1849",
    key: "DO",
  },
  {
    name: "Ecuador",
    id: "+593",
    key: "EC",
  },
  {
    name: "Egypt",
    id: "+20",
    key: "EG",
  },
  {
    name: "El Salvador",
    id: "+503",
    key: "SV",
  },
  {
    name: "Equatorial Guinea",
    id: "+240",
    key: "GQ",
  },
  {
    name: "Eritrea",
    id: "+291",
    key: "ER",
  },
  {
    name: "Estonia",
    id: "+372",
    key: "EE",
  },
  {
    name: "Ethiopia",
    id: "+251",
    key: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    id: "+500",
    key: "FK",
  },
  {
    name: "Faroe Islands",
    id: "+298",
    key: "FO",
  },
  {
    name: "Fiji",
    id: "+679",
    key: "FJ",
  },
  {
    name: "Finland",
    id: "+358",
    key: "FI",
  },
  {
    name: "France",
    id: "+33",
    key: "FR",
  },
  {
    name: "French Guiana",
    id: "+594",
    key: "GF",
  },
  {
    name: "French Polynesia",
    id: "+689",
    key: "PF",
  },
  {
    name: "Gabon",
    id: "+241",
    key: "GA",
  },
  {
    name: "Gambia",
    id: "+220",
    key: "GM",
  },
  {
    name: "Georgia",
    id: "+995",
    key: "GE",
  },
  {
    name: "Germany",
    id: "+49",
    key: "DE",
  },
  {
    name: "Ghana",
    id: "+233",
    key: "GH",
  },
  {
    name: "Gibraltar",
    id: "+350",
    key: "GI",
  },
  {
    name: "Greece",
    id: "+30",
    key: "GR",
  },
  {
    name: "Greenland",
    id: "+299",
    key: "GL",
  },
  {
    name: "Grenada",
    id: "+1473",
    key: "GD",
  },
  {
    name: "Guadeloupe",
    id: "+590",
    key: "GP",
  },
  {
    name: "Guam",
    id: "+1671",
    key: "GU",
  },
  {
    name: "Guatemala",
    id: "+502",
    key: "GT",
  },
  {
    name: "Guernsey",
    id: "+44",
    key: "GG",
  },
  {
    name: "Guinea",
    id: "+224",
    key: "GN",
  },
  {
    name: "Guinea-Bissau",
    id: "+245",
    key: "GW",
  },
  {
    name: "Guyana",
    id: "+595",
    key: "GY",
  },
  {
    name: "Haiti",
    id: "+509",
    key: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    id: "+379",
    key: "VA",
  },
  {
    name: "Honduras",
    id: "+504",
    key: "HN",
  },
  {
    name: "Hong Kong",
    id: "+852",
    key: "HK",
  },
  {
    name: "Hungary",
    id: "+36",
    key: "HU",
  },
  {
    name: "Iceland",
    id: "+354",
    key: "IS",
  },
  {
    name: "India",
    id: "+91",
    key: "IN",
  },
  {
    name: "Indonesia",
    id: "+62",
    key: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    id: "+98",
    key: "IR",
  },
  {
    name: "Iraq",
    id: "+964",
    key: "IQ",
  },
  {
    name: "Ireland",
    id: "+353",
    key: "IE",
  },
  {
    name: "Isle of Man",
    id: "+44",
    key: "IM",
  },
  {
    name: "Israel",
    id: "+972",
    key: "IL",
  },
  {
    name: "Italy",
    id: "+39",
    key: "IT",
  },
  {
    name: "Jamaica",
    id: "+1876",
    key: "JM",
  },
  {
    name: "Japan",
    id: "+81",
    key: "JP",
  },
  {
    name: "Jersey",
    id: "+44",
    key: "JE",
  },
  {
    name: "Jordan",
    id: "+962",
    key: "JO",
  },
  {
    name: "Kazakhstan",
    id: "+77",
    key: "KZ",
  },
  {
    name: "Kenya",
    id: "+254",
    key: "KE",
  },
  {
    name: "Kiribati",
    id: "+686",
    key: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    id: "+850",
    key: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    id: "+82",
    key: "KR",
  },
  {
    name: "Kuwait",
    id: "+965",
    key: "KW",
  },
  {
    name: "Kyrgyzstan",
    id: "+996",
    key: "KG",
  },
  {
    name: "Laos",
    id: "+856",
    key: "LA",
  },
  {
    name: "Latvia",
    id: "+371",
    key: "LV",
  },
  {
    name: "Lebanon",
    id: "+961",
    key: "LB",
  },
  {
    name: "Lesotho",
    id: "+266",
    key: "LS",
  },
  {
    name: "Liberia",
    id: "+231",
    key: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    id: "+218",
    key: "LY",
  },
  {
    name: "Liechtenstein",
    id: "+423",
    key: "LI",
  },
  {
    name: "Lithuania",
    id: "+370",
    key: "LT",
  },
  {
    name: "Luxembourg",
    id: "+352",
    key: "LU",
  },
  {
    name: "Macao",
    id: "+853",
    key: "MO",
  },
  {
    name: "Macedonia",
    id: "+389",
    key: "MK",
  },
  {
    name: "Madagascar",
    id: "+261",
    key: "MG",
  },
  {
    name: "Malawi",
    id: "+265",
    key: "MW",
  },
  {
    name: "Malaysia",
    id: "+60",
    key: "MY",
  },
  {
    name: "Maldives",
    id: "+960",
    key: "MV",
  },
  {
    name: "Mali",
    id: "+223",
    key: "ML",
  },
  {
    name: "Malta",
    id: "+356",
    key: "MT",
  },
  {
    name: "Marshall Islands",
    id: "+692",
    key: "MH",
  },
  {
    name: "Martinique",
    id: "+596",
    key: "MQ",
  },
  {
    name: "Mauritania",
    id: "+222",
    key: "MR",
  },
  {
    name: "Mauritius",
    id: "+230",
    key: "MU",
  },
  {
    name: "Mayotte",
    id: "+262",
    key: "YT",
  },
  {
    name: "Mexico",
    id: "+52",
    key: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    id: "+691",
    key: "FM",
  },
  {
    name: "Moldova",
    id: "+373",
    key: "MD",
  },
  {
    name: "Monaco",
    id: "+377",
    key: "MC",
  },
  {
    name: "Mongolia",
    id: "+976",
    key: "MN",
  },
  {
    name: "Montenegro",
    id: "+382",
    key: "ME",
  },
  {
    name: "Montserrat",
    id: "+1664",
    key: "MS",
  },
  {
    name: "Morocco",
    id: "+212",
    key: "MA",
  },
  {
    name: "Mozambique",
    id: "+258",
    key: "MZ",
  },
  {
    name: "Myanmar",
    id: "+95",
    key: "MM",
  },
  {
    name: "Namibia",
    id: "+264",
    key: "NA",
  },
  {
    name: "Nauru",
    id: "+674",
    key: "NR",
  },
  {
    name: "Nepal",
    id: "+977",
    key: "NP",
  },
  {
    name: "Netherlands",
    id: "+31",
    key: "NL",
  },
  {
    name: "Netherlands Antilles",
    id: "+599",
    key: "AN",
  },
  {
    name: "New Caledonia",
    id: "+687",
    key: "NC",
  },
  {
    name: "New Zealand",
    id: "+64",
    key: "NZ",
  },
  {
    name: "Nicaragua",
    id: "+505",
    key: "NI",
  },
  {
    name: "Niger",
    id: "+227",
    key: "NE",
  },
  {
    name: "Nigeria",
    id: "+234",
    key: "NG",
  },
  {
    name: "Niue",
    id: "+683",
    key: "NU",
  },
  {
    name: "Norfolk Island",
    id: "+672",
    key: "NF",
  },
  {
    name: "Northern Mariana Islands",
    id: "+1670",
    key: "MP",
  },
  {
    name: "Norway",
    id: "+47",
    key: "NO",
  },
  {
    name: "Oman",
    id: "+968",
    key: "OM",
  },
  {
    name: "Pakistan",
    id: "+92",
    key: "PK",
  },
  {
    name: "Palau",
    id: "+680",
    key: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    id: "+970",
    key: "PS",
  },
  {
    name: "Panama",
    id: "+507",
    key: "PA",
  },
  {
    name: "Papua New Guinea",
    id: "+675",
    key: "PG",
  },
  {
    name: "Paraguay",
    id: "+595",
    key: "PY",
  },
  {
    name: "Peru",
    id: "+51",
    key: "PE",
  },
  {
    name: "Philippines",
    id: "+63",
    key: "PH",
  },
  {
    name: "Pitcairn",
    id: "+872",
    key: "PN",
  },
  {
    name: "Poland",
    id: "+48",
    key: "PL",
  },
  {
    name: "Portugal",
    id: "+351",
    key: "PT",
  },
  {
    name: "Puerto Rico",
    id: "+1939",
    key: "PR",
  },
  {
    name: "Qatar",
    id: "+974",
    key: "QA",
  },
  {
    name: "Romania",
    id: "+40",
    key: "RO",
  },
  {
    name: "Russia",
    id: "+7",
    key: "RU",
  },
  {
    name: "Rwanda",
    id: "+250",
    key: "RW",
  },
  {
    name: "Reunion",
    id: "+262",
    key: "RE",
  },
  {
    name: "Saint Barthelemy",
    id: "+590",
    key: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    id: "+290",
    key: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    id: "+1869",
    key: "KN",
  },
  {
    name: "Saint Lucia",
    id: "+1758",
    key: "LC",
  },
  {
    name: "Saint Martin",
    id: "+590",
    key: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    id: "+508",
    key: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    id: "+1784",
    key: "VC",
  },
  {
    name: "Samoa",
    id: "+685",
    key: "WS",
  },
  {
    name: "San Marino",
    id: "+378",
    key: "SM",
  },
  {
    name: "Sao Tome and Principe",
    id: "+239",
    key: "ST",
  },
  {
    name: "Saudi Arabia",
    id: "+966",
    key: "SA",
  },
  {
    name: "Senegal",
    id: "+221",
    key: "SN",
  },
  {
    name: "Serbia",
    id: "+381",
    key: "RS",
  },
  {
    name: "Seychelles",
    id: "+248",
    key: "SC",
  },
  {
    name: "Sierra Leone",
    id: "+232",
    key: "SL",
  },
  {
    name: "Singapore",
    id: "+65",
    key: "SG",
  },
  {
    name: "Slovakia",
    id: "+421",
    key: "SK",
  },
  {
    name: "Slovenia",
    id: "+386",
    key: "SI",
  },
  {
    name: "Solomon Islands",
    id: "+677",
    key: "SB",
  },
  {
    name: "Somalia",
    id: "+252",
    key: "SO",
  },
  {
    name: "South Africa",
    id: "+27",
    key: "ZA",
  },
  {
    name: "South Sudan",
    id: "+211",
    key: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    id: "+500",
    key: "GS",
  },
  {
    name: "Spain",
    id: "+34",
    key: "ES",
  },
  {
    name: "Sri Lanka",
    id: "+94",
    key: "LK",
  },
  {
    name: "Sudan",
    id: "+249",
    key: "SD",
  },
  {
    name: "Suriname",
    id: "+597",
    key: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    id: "+47",
    key: "SJ",
  },
  {
    name: "Swaziland",
    id: "+268",
    key: "SZ",
  },
  {
    name: "Sweden",
    id: "+46",
    key: "SE",
  },
  {
    name: "Switzerland",
    id: "+41",
    key: "CH",
  },
  {
    name: "Syrian Arab Republic",
    id: "+963",
    key: "SY",
  },
  {
    name: "Taiwan",
    id: "+886",
    key: "TW",
  },
  {
    name: "Tajikistan",
    id: "+992",
    key: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    id: "+255",
    key: "TZ",
  },
  {
    name: "Thailand",
    id: "+66",
    key: "TH",
  },
  {
    name: "Timor-Leste",
    id: "+670",
    key: "TL",
  },
  {
    name: "Togo",
    id: "+228",
    key: "TG",
  },
  {
    name: "Tokelau",
    id: "+690",
    key: "TK",
  },
  {
    name: "Tonga",
    id: "+676",
    key: "TO",
  },
  {
    name: "Trinidad and Tobago",
    id: "+1868",
    key: "TT",
  },
  {
    name: "Tunisia",
    id: "+216",
    key: "TN",
  },
  {
    name: "Turkey",
    id: "+90",
    key: "TR",
  },
  {
    name: "Turkmenistan",
    id: "+993",
    key: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    id: "+1649",
    key: "TC",
  },
  {
    name: "Tuvalu",
    id: "+688",
    key: "TV",
  },
  {
    name: "Uganda",
    id: "+256",
    key: "UG",
  },
  {
    name: "Ukraine",
    id: "+380",
    key: "UA",
  },
  {
    name: "United Arab Emirates",
    id: "+971",
    key: "AE",
  },
  {
    name: "United Kingdom",
    id: "+44",
    key: "GB",
  },
  {
    name: "United States",
    id: "+1",
    key: "US",
  },
  {
    name: "Uruguay",
    id: "+598",
    key: "UY",
  },
  {
    name: "Uzbekistan",
    id: "+998",
    key: "UZ",
  },
  {
    name: "Vanuatu",
    id: "+678",
    key: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    id: "+58",
    key: "VE",
  },
  {
    name: "Vietnam",
    id: "+84",
    key: "VN",
  },
  {
    name: "Virgin Islands, British",
    id: "+1284",
    key: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    id: "+1340",
    key: "VI",
  },
  {
    name: "Wallis and Futuna",
    id: "+681",
    key: "WF",
  },
  {
    name: "Yemen",
    id: "+967",
    key: "YE",
  },
  {
    name: "Zambia",
    id: "+260",
    key: "ZM",
  },
  {
    name: "Zimbabwe",
    id: "+263",
    key: "ZW",
  },
];
