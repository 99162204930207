import { uniqBy } from "lodash";
import { ROLE } from "@constants/role";
import { getDateTimeString, isDate } from "./date";
import { citiesHonduras } from "@constants/citiesHonduras";

const propertiesToIgnore = [
  "createdAt",
  "createdBy",
  "modifiedAt",
  "modifiedBy",
];

export const gridStyle = {
  width: "25%",
};

export const isFilterMatched = (
  value: any,
  record: Record<string, any>
): boolean => {
  if (!value) return true;
  const filteredRecord = Object.keys(record)
    .filter((key) => !propertiesToIgnore.includes(key))
    .reduce((obj, key) => {
      obj[key] = record[key];
      return obj;
    }, {} as Record<string, any>);
  const flatArray = flattenObject(filteredRecord);
  return flatArray.some((x: any) => {
    const formattedValue = getFormattedValue(x) ?? "";
    return formattedValue.toLowerCase().includes(value.toLowerCase());
  });
};

const getFormattedValue = (value: any): string => {
  if (typeof value === "number") {
    return value.toString();
  } else if (isDate(value)) {
    return getDateTimeString(value);
  }
  return String(value);
};

export const flattenObject = (obj: Record<string, any>): any[] => {
  const result: any[] = [];
  Object.values(obj).forEach((value) => {
    if (value && typeof value !== "string" && typeof value === "object") {
      result.push(...Object.values(value));
    } else if (value !== undefined && value !== null) {
      result.push(value);
    }
  });
  return result;
};

export const sortAlphaNum = (a: string | null, b: string | null) => {
  if (a === null && b === null) return 0;
  if (a === null) return -1;
  if (b === null) return 1;

  return a.localeCompare(b, "en", { numeric: true });
};

export const sortBooleans = (a: boolean, b: boolean) => {
  if (a === b) return 0;
  if (a === false && b === true) return -1;
  return 1;
};

export const orderAlphaNumeric = (data: any, key: string) => {
  return data
    .slice()
    .sort((a: any, b: any) =>
      a[key].localeCompare(b[key], undefined, { numeric: true })
    );
};

export const getHondurasCities = () => {
  let cities;
  cities = citiesHonduras?.reduce((result: any, x: any) => {
    return result.concat(x.municipios);
  }, []);
  cities = uniqBy(cities, "name");
  return orderAlphaNumeric(cities, "name");
};

export const getCurrencyFormat = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const convertName = (fullName: string) => {
  const nameParts = fullName?.split(" ");
  if (nameParts?.length < 2) {
    return fullName;
  }

  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1]?.charAt(0);

  return `${firstName} ${lastNameInitial}.`;
};

export const isAuthUser = (token: any) =>
  token.claims.role !== ROLE.ADMIN && token.claims.role !== ROLE.RECEPCIONIST;
