import React, { Key, useEffect, useState } from "react";
import { useAppSelector } from "@store/hooks";
import { Table, Layout, Card, message } from "antd";
import { getErrorMessage } from "@utils/errorMessage";
import { deleteQuoteService, getQuotesService } from "@services/quoteService";
import { QuoteColumns, QuoteExpandableComponent } from "./helpers/Components";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const QuotesTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const { hotel } = useAppSelector((state) => state.hotel);
  const [filteredString, setFilteredString] = useState<Key>("");

  useEffect(() => {
    fetchQuotes();
  }, []);

  const fetchQuotes = async () => {
    setLoading(true);
    const quotes = await getQuotesService();
    setQuotes(quotes);
    setLoading(false);
  };

  const onDelete = async (id: string) => {
    setLoading(true);
    try {
      const quotes = await deleteQuoteService(id);
      setQuotes(quotes);
      message.success("Cotización borrada!");
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            dataSource={quotes}
            rowKey={(x) => x.id}
            pagination={{ pageSize: 20 }}
            expandable={QuoteExpandableComponent}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={QuoteColumns(filteredString, sortedInfo, hotel, onDelete)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default QuotesTable;
