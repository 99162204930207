import { useEffect, useState } from "react";
import { gridStyle } from "@utils/util";
import { parseCurrency } from "@pages/Reports/Common";
import { getErrorMessage } from "@utils/errorMessage";
import { filterData, IItemOption } from "@components/Form";
import { getStaffUsersService } from "@services/userService";
import { ProjectOutlined, PrinterOutlined } from "@ant-design/icons";
import { connectAndPrintCashRegisterClosingReport } from "@utils/printer";
import { generateCashRegisterClosingReport } from "@services/reportService";

import {
  getDateTimeString,
  getTodayShortString,
  getShortDateTimeString,
} from "@utils/date";

import {
  Col,
  Row,
  Spin,
  Card,
  Button,
  Select,
  message,
  DatePicker,
} from "antd";

import IUser from "@interfaces/IUser";

const { RangePicker } = DatePicker;

const CashRegisterClosingReport = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [filteredUser, setFilteredUser] = useState();
  const [filteredDate, setFilteredDate] = useState<any>([]);
  const [usersOptions, setUsersOptions] = useState<IItemOption[]>([]);

  useEffect(() => {
    setLoading(true);
    getStaffUsersData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStaffUsersData = async () => {
    try {
      const users = await getStaffUsersService();
      const usersOptions: IItemOption[] = users?.map((user: IUser) => ({
        key: user.id,
        label: user.name,
        value: user.name,
      }));
      setUsersOptions(usersOptions);
      return usersOptions;
    } catch (error: any) {
      message.error(getErrorMessage(error));
    }
  };

  const getSum = (value1: string, value2: string) => {
    return ((Number(value1) || 0) + (Number(value2) || 0)).toString();
  };

  const handleOnGenerate = async () => {
    if (filteredDate.length < 2 || !filteredUser) return;

    const payload = {
      initialDate: filteredDate[0].toISOString(),
      finalDate: filteredDate[1].toISOString(),
      userName: filteredUser,
    };

    try {
      setLoading(true);
      const result = await generateCashRegisterClosingReport(payload);
      setData(result);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleOnPrint = async () => {
    try {
      setLoading(true);
      if (data) {
        const printData = Object.assign(data, {
          initialDate: getDateTimeString(filteredDate[0].toISOString()),
          finalDate: getDateTimeString(filteredDate[1].toISOString()),
          taxes: getSum(data.isvTax, data.isrtTax),
          userName: filteredUser,
        });
        await connectAndPrintCashRegisterClosingReport(printData);
      }
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card style={{ width: 1200 }}>
        <Row style={{ marginBottom: 40 }}>
          <Col style={{ display: "flex", marginLeft: 20, width: 400 }}>
            <RangePicker
              showTime
              size="large"
              format={"DD/MM/YY hh:mm A"}
              onChange={(value: any) => setFilteredDate(value)}
            />
          </Col>
          <Col
            style={{
              width: 300,
              marginLeft: 50,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Select
              showSearch
              size="large"
              options={usersOptions}
              filterOption={filterData}
              onChange={setFilteredUser}
              notFoundContent="Usuario no encontrado"
            ></Select>
          </Col>
          <Col style={{ marginLeft: 50 }}>
            <Button
              size="large"
              type="primary"
              icon={<ProjectOutlined />}
              onClick={() => handleOnGenerate()}
            >
              
              Generar
            </Button>
          </Col>
          <Col style={{ marginLeft: 50 }}>
            {data && (
              <Button
                size="large"
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => handleOnPrint()}
              >
                
                Imprimir
              </Button>
            )}
          </Col>
        </Row>

        {data && (
          <Card style={{ borderWidth: 0, paddingLeft: 20, marginBottom: 50 }}>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Fecha de reporte</b>
              <br />
              <span>{getTodayShortString()}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Fecha inicial</b>
              <br />
              <span>
                {getShortDateTimeString(filteredDate[0].toISOString())}
              </span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Fecha final</b>
              <br />
              <span>
                {getShortDateTimeString(filteredDate[1].toISOString())}
              </span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Usuario</b>
              <br />
              <span>{filteredUser}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>I.S.V. Total</b>
              <br />
              <span>{parseCurrency(data.isvTax)}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>I.S.R.T. Total</b>
              <br />
              <span>{parseCurrency(data.isrtTax)}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Impuestos Total</b>
              <br />
              <span>{parseCurrency(getSum(data.isvTax, data.isrtTax))}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Descuentos Total</b>
              <br />
              <span>{parseCurrency(data.discount)}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Subtotal</b>
              <br />
              <span>{parseCurrency(data.subtotal)}</span>
            </Card.Grid>
            <Card.Grid className="table-row-detail" style={gridStyle}>
              <b>Total</b>
              <br />
              <span>{parseCurrency(data.total)}</span>
            </Card.Grid>
          </Card>
        )}
      </Card>
    </Spin>
  );
};

export default CashRegisterClosingReport;
