import axios from "axios";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/invoiceConfiguration";

export const getInvoiceConfigurationService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getInvoiceConfigurationsService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const createInvoiceConfigurationService = async (
  invoiceConfigurationData: IInvoiceConfiguration
) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    invoiceConfigurationData,
    headers
  );
  return response.data;
};
