import { useEffect, useState } from "react";
import { DATE_FORMAT } from "@constants/date";
import { formLayout } from "@constants/formLayout";
import { getSpan } from "@components/Common/Render";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getFieldData, onFinishFailed } from "@components/Form";
import { Card, Form, Spin, Input, Space, Button, InputNumber } from "antd";

import {
  fetchInvoiceConfigurationAsync,
  createInvoiceConfigurationAsync,
  clearSelectedInvoiceConfiguration,
} from "@store/slices/invoice";

import IFieldData from "@interfaces/IFieldData";
import DatePicker from "@components/Common/DatePicker";
import IInvoiceConfiguration from "@interfaces/IInvoiceConfiguration";

const InvoiceConfigurationForm = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [fields, setFields] = useState<IFieldData[]>([]);
  const { selectedInvoiceConfiguration, loading } = useAppSelector(
    (state) => state.invoice
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedInvoiceConfiguration());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedInvoiceConfiguration) {
      setFields(getFieldData(selectedInvoiceConfiguration));
    } else if (!selectedInvoiceConfiguration && guid) {
      dispatch(fetchInvoiceConfigurationAsync(guid));
    }
  }, [dispatch, guid, selectedInvoiceConfiguration]);

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (invoiceConfigurationData: IInvoiceConfiguration) => {
    await dispatch(createInvoiceConfigurationAsync(invoiceConfigurationData));
    goBack();
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />

          <Form.Item
            name="name"
            label={getSpan("Nombre")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="cai"
            label={getSpan("CAI")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="deadlineForIssuance"
            label={getSpan("Fecha limite emisión")}
            rules={[{ required: true }]}
          >
            <DatePicker
              inputReadOnly
              format={DATE_FORMAT}
              style={{ width: "100%" }}
              placeholder="Elegir fecha"
            />
          </Form.Item>

          <Form.Item
            name="initialAuthorizedRange"
            label={getSpan("Rango inicial autorizado")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="finalAuthorizedRange"
            label={getSpan("Rango final autorizado")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="totalNumeration"
            label={getSpan("Numeración total")}
            rules={[{ required: true }]}
            initialValue={0}
          >
            <InputNumber controls={true} min={0} />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit">
                Crear
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default InvoiceConfigurationForm;
