import { useState } from "react";
import { FloatButton, Modal } from "antd";

import {
  TeamOutlined,
  BankOutlined,
  FileTextOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import UserForm from "@pages/Users/UserForm";
import InvoiceForm from "@pages/Invoice/InvoiceForm";
import CompanyForm from "@pages/Companies/CompanyForm";
import ReservationForm from "@pages/Reservations/ReservationForm";

const FloatButtons: React.FC<any> = (props) => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [reservationModalOpen, setReservationModalOpen] = useState(false);

  return (
    <>
      <FloatButton.Group
        open={true}
        shape="circle"
        trigger="click"
        closeIcon={false}
      >
        {props.showReservation && (
          <FloatButton
            type="primary"
            tooltip="Crear Reservación"
            icon={<ContainerOutlined />}
            onClick={() => setReservationModalOpen(true)}
          />
        )}
        {props.showInvoice && (
          <FloatButton
            type="primary"
            tooltip="Crear Factura"
            icon={<FileTextOutlined />}
            onClick={() => setInvoiceModalOpen(true)}
          />
        )}
        <FloatButton
          type="primary"
          tooltip="Crear Usuario"
          icon={<TeamOutlined />}
          onClick={() => setUserModalOpen(true)}
        />
        <FloatButton
          type="primary"
          tooltip="Crear Empresa"
          icon={<BankOutlined />}
          onClick={() => setCompanyModalOpen(true)}
        />
      </FloatButton.Group>

      {reservationModalOpen && (
        <Modal
          centered
          footer={[]}
          width={950}
          open={reservationModalOpen}
          onCancel={() => setReservationModalOpen(false)}
        >
          <ReservationForm onBack={() => setReservationModalOpen(false)} />
        </Modal>
      )}

      {invoiceModalOpen && (
        <Modal
          centered
          footer={[]}
          width={1650}
          open={invoiceModalOpen}
          onCancel={() => setInvoiceModalOpen(false)}
        >
          <InvoiceForm onBack={() => setInvoiceModalOpen(false)} />
        </Modal>
      )}

      {userModalOpen && (
        <Modal
          centered
          width={650}
          footer={[]}
          open={userModalOpen}
          title={<h2>Crear Cliente</h2>}
          onCancel={() => setUserModalOpen(false)}
        >
          <UserForm onBack={() => setUserModalOpen(false)} />
        </Modal>
      )}

      {companyModalOpen && (
        <Modal
          centered
          width={650}
          footer={[]}
          open={companyModalOpen}
          title={<h2>Crear Empresa</h2>}
          onCancel={() => setCompanyModalOpen(false)}
        >
          <CompanyForm onBack={() => setCompanyModalOpen(false)} />
        </Modal>
      )}
    </>
  );
};

export default FloatButtons;
