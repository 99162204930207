import React, { Key, useEffect, useState } from "react";

import { Table, Layout, Card } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { deleteUserAsync, fetchUsersAsync } from "@store/slices/user";
import { UserColumns, UserExpandableComponent } from "./helpers/Components";

import TableToolbar from "@components/Table/TableToolbar";

const { Header, Content } = Layout;

const UsersTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredString, setFilteredString] = useState<Key>("");

  const { users, loading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchUsersAsync());
    }
  }, [dispatch, users.length]);

  const onDelete = async (id: string) => await dispatch(deleteUserAsync(id));

  return (
    <Card>
      <Layout>
        <Header style={{ background: "white", padding: 0 }}>
          <TableToolbar onFilterChange={setFilteredString} canCreate />
        </Header>
        <Content>
          <Table
            loading={loading}
            dataSource={users}
            rowKey={(x) => x.id}
            pagination={{ pageSize: 20 }}
            expandable={UserExpandableComponent}
            onChange={(_, __, sorter) => setSortedInfo(sorter)}
            columns={UserColumns(filteredString, sortedInfo, onDelete)}
          />
        </Content>
      </Layout>
    </Card>
  );
};

export default UsersTable;
