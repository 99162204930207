import React, { useEffect, useState } from "react";
import { formLayout } from "@constants/formLayout";
import { getSpan } from "@components/Common/Render";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Card, Form, Spin, Input, Space, Button, InputNumber } from "antd";
import { IFormProps, getFieldData, onFinishFailed } from "@components/Form";

import {
  createServiceAsync,
  fetchServiceAsync,
  updateServiceAsync,
  clearSelectedService,
} from "@store/slices/service";

import IService from "@interfaces/IService";
import IFieldData from "@interfaces/IFieldData";

const { TextArea } = Input;

const ServiceForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [fields, setFields] = useState<IFieldData[]>([]);
  const { selectedService, loading } = useAppSelector((state) => state.service);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedService());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedService) {
      setFields(getFieldData(selectedService));
    } else if (!selectedService && guid) {
      dispatch(fetchServiceAsync(guid));
    }
  }, [dispatch, guid, selectedService]);

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (serviceData: IService) => {
    if (selectedService) {
      await dispatch(updateServiceAsync(serviceData));
    } else {
      await dispatch(createServiceAsync(serviceData));
    }
    goBack();
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card bordered={false} style={{ width: 600 }}>
        <Form
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="id" hidden />

          <Form.Item
            name="name"
            label={getSpan("Nombre")}
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>

          <Form.Item
            name="price"
            initialValue={0}
            label={getSpan("Precio")}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={0}
              controls={true}
              addonBefore="L."
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item name="description" label={getSpan("Descripción")}>
            <TextArea rows={3} maxLength={200} />
          </Form.Item>

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {selectedService ? "Editar Servicio" : "Crear Servicio"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default ServiceForm;
