import React, { useEffect, useState } from "react";
import ICompany from "@interfaces/ICompany";
import IFieldData from "@interfaces/IFieldData";
import PhonePrefix from "@components/Common/PhonePrefix";

import { formLayout } from "@constants/formLayout";
import { getSpan } from "@components/Common/Render";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { Card, Form, Input, Spin, Space, Select, Button } from "antd";
import { fetchCitiesAsync, fetchCountriesAsync } from "@store/slices/location";

import {
  clearSelectedCompany,
  createCompanyAsync,
  fetchCompanyAsync,
  updateCompanyAsync,
} from "@store/slices/company";

import {
  IFormProps,
  filterData,
  getFieldData,
  onFinishFailed,
} from "@components/Form";

import {
  validateRtn,
  validatePhoneNumber,
  validateSomeSpecialChars,
} from "@utils/validation";

const CompanyForm: React.FC<IFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { guid } = useParams();
  const [form] = Form.useForm();
  const [fields, setFields] = useState<IFieldData[]>([]);

  const { selectedCompany, loading } = useAppSelector((state) => state.company);

  const { cityOptions, countryOptions } = useAppSelector(
    (state) => state.location
  );

  useEffect(() => {
    return () => {
      dispatch(clearSelectedCompany());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      setFields(getFieldData(selectedCompany));
    } else if (!selectedCompany && guid) {
      dispatch(fetchCompanyAsync(guid));
    }
  }, [dispatch, guid, selectedCompany]);

  useEffect(() => {
    if (countryOptions.length === 0) {
      dispatch(fetchCountriesAsync());
      dispatch(fetchCitiesAsync("HN"));
    }
  }, [dispatch, countryOptions.length]);

  const handleCountryChange = (_: any, value: any) => {
    form.setFieldsValue({ city: "" });
    dispatch(fetchCitiesAsync(value.key));
  };

  const goBack = () => (props.onBack ? props.onBack() : navigate(".."));
  const onFinish = async (companyData: ICompany) => {
    selectedCompany
      ? await dispatch(updateCompanyAsync(companyData)).unwrap()
      : await dispatch(createCompanyAsync(companyData)).unwrap();

    goBack();
  };

  return (
    <Spin className="spinner" spinning={loading}>
      <Card
        bordered={false}
        style={{ width: 600 }}
        className={props.onBack ? "custom-form-card" : ""}
      >
        <Form
          form={form}
          fields={fields}
          {...formLayout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={{ required: " " }}
        >
          <Form.Item name="id" hidden />
          <Form.Item
            name="name"
            label={getSpan("Nombre")}
            rules={[
              { required: true },
              { validator: validateSomeSpecialChars },
            ]}
          >
            <Input maxLength={80} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={getSpan("Teléfono")}
            dependencies={["phonePrefix"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const phonePrefix = getFieldValue("phonePrefix");
                  return validatePhoneNumber(_, phonePrefix, value);
                },
              }),
            ]}
          >
            <Input addonBefore={<PhonePrefix />} maxLength={15} />
          </Form.Item>
          <Form.Item
            name="rtn"
            label={getSpan("RTN")}
            rules={[
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const country = getFieldValue("country");
                  return validateRtn(_, country, value);
                },
              }),
            ]}
          >
            <Input maxLength={14} />
          </Form.Item>

          {!props.excludeFields && (
            <>
              <Form.Item
                name="email"
                label={getSpan("Correo")}
                rules={[{ type: "email", message: "Correo no es valido." }]}
              >
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item name="address" label={getSpan("Dirección")}>
                <Input maxLength={50} />
              </Form.Item>
              <Form.Item name="city" label={getSpan("Ciudad")}>
                <Select
                  showSearch
                  options={cityOptions}
                  filterOption={filterData}
                  notFoundContent="Ciudad no encontrado"
                ></Select>
              </Form.Item>
              <Form.Item
                name="country"
                initialValue="Honduras"
                label={getSpan("País")}
              >
                <Select
                  showSearch
                  options={countryOptions}
                  filterOption={filterData}
                  onChange={handleCountryChange}
                  notFoundContent="País no encontrado"
                ></Select>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Space size="large">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {selectedCompany ? "Editar Empresa" : "Crear Empresa"}
              </Button>
              <Button onClick={() => goBack()}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default CompanyForm;
