import axios from "axios";
import IReservation from "@interfaces/IReservation";

import { Moment } from "moment";
import { getConfigHeaders } from "@constants/axiosConfig";
import { getMonthString, getYearString } from "@utils/date";

const API_BASE_URL = "/api/reservation";

export const getReservationService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getActiveReservationsService = async (date: Moment) => {
  const month = getMonthString(date);
  const year = getYearString(date);
  const headers = await getConfigHeaders();
  const response = await axios.get(
    `${API_BASE_URL}/active/${month}/${year}`,
    headers
  );
  return response.data;
};

export const getInactiveReservationsService = async (date: Moment) => {
  const month = getMonthString(date);
  const year = getYearString(date);
  const headers = await getConfigHeaders();
  const response = await axios.get(
    `${API_BASE_URL}/inactive/${month}/${year}`,
    headers
  );
  return response.data;
};

export const createReservationService = async (
  reservationData: IReservation
) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    reservationData,
    headers
  );
  return response.data;
};

export const updateReservationService = async (
  reservationData: IReservation
) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${reservationData.id}`,
    reservationData,
    headers
  );
  return response.data;
};

export const updateReservationStatusService = async ({ id, status }: any) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/status/${id}`,
    { id, status },
    headers
  );
  return response.data;
};

export const deleteReservationService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
