import React from "react";
import { useNavigate } from "react-router-dom";
import { doResetPasswordAsync } from "@store/slices/auth";
import { HOTEL_LOGO_URL } from "@constants/globalConstants";
import { Card, Form, Spin, Input, Image, Button } from "antd";
import { useAppDispatch, useAppSelector } from "@store/hooks";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.auth);

  const onFinish = async (userData: any) => {
    const { username } = userData;
    const response = await dispatch(doResetPasswordAsync(username)).unwrap();
    if (response) {
      setTimeout(() => {
        goBack();
      }, 3000);
    }
  };

  const goBack = () => navigate("..");

  return (
    <Spin className="spinner" spinning={loading}>
      <div className="login">
        <Card className="login-card" title={null}>
          <div
            style={{ textAlign: "center", paddingTop: 20, paddingBottom: 40 }}
          >
            <Image
              width="60%"
              height="60%"
              preview={false}
              src={HOTEL_LOGO_URL}
            />
          </div>
          <Form
            name="basic"
            size={"large"}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Correo no es valido.",
                },
              ]}
            >
              <Input className="input-class" placeholder="Correo electrónico" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Enviar correo
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="default" style={{ width: "100%" }} onClick={goBack}>
                Cancelar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

export default ResetPassword;
