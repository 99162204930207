import axios from "axios";
import ICompany from "@interfaces/ICompany";

import { getConfigHeaders } from "@constants/axiosConfig";

const API_BASE_URL = "/api/company";

export const getCompanyService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};

export const getCompaniesService = async () => {
  const headers = await getConfigHeaders();
  const response = await axios.get(`${API_BASE_URL}/all`, headers);
  return response.data;
};

export const createCompanyService = async (companyData: ICompany) => {
  const headers = await getConfigHeaders();
  const response = await axios.post(
    `${API_BASE_URL}/create`,
    companyData,
    headers
  );
  return response.data;
};

export const updateCompanyService = async (companyData: ICompany) => {
  const headers = await getConfigHeaders();
  const response = await axios.put(
    `${API_BASE_URL}/${companyData.id}`,
    companyData,
    headers
  );
  return response.data;
};

export const deleteCompanyService = async (id: string) => {
  const headers = await getConfigHeaders();
  const response = await axios.delete(`${API_BASE_URL}/${id}`, headers);
  return response.data;
};
